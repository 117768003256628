<template>
  <div>
    <div class="ml-3">
      <div
        v-if="item.type === 'block'"
        class="block-conditions"
      >
        <div>
          <div
            v-for="(condition, index) in item.conditions"
            :key="index"
            class="mt-4"
          >
            <p
              v-if="index !== 0"
              class="operator"
              @click="onChangeOperator"
            >
              {{ logicalOperator[item.operator].label }}
            </p>
            <FiltersCustomCondtions
              v-model="item.conditions[index]"
              class="group"
              @remove="(count) => onRemove(index, count)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <Form
          v-model:typeCondition="item.typeCondition"
          v-model="item.item"
          @remove="onRemoveForm"
        />
      </div>
      <div
        v-if="item.type === 'block'"
      >
        <p
          class="options-button ml-5 mt-2"
          @click="addData"
        >
          +
        </p>
      </div>
      <div>
        <p
          class="options-button mt-2"
          :class="{
            'ml-4': item.type === 'block'
          }"
          @click="addParentBlock"
        >
          { }
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiltersCustomCondtions'
}
</script>

<script setup>
import Form from '@/components/event/filters/custom/Form.vue'
import customFilteringFormContent from '@/shared/enums/customFilteringFormContent.js'
import FilterCustom from '@/shared/libraries/filterCustom'
import { useStore } from 'vuex'

const store = useStore()

const logicalOperator = {
  'AND': {
    label: 'ET',
    value: 'AND'
  },
  'OR': {
    label: 'OU',
    value: 'OR'
  }
}

const item = defineModel({
  type: Object,
  required: true
})

const emits = defineEmits(['remove'])

function addData(event) {
  store.dispatch('ws/sendEvent', { event: event })
  item.value.conditions.push({
    operator: 'AND',
    type: 'item',
    typeCondition: customFilteringFormContent.STRICT_VALUE,
    item: {
      key: '',
      value: ''
    }
  })
  store.dispatch('ws/collaborativeEventTreated')
}

function onChangeOperator(event) {
  store.dispatch('ws/sendEvent', { event: event })
  item.value.operator = (item.value.operator === "AND") ? "OR" : "AND"
  store.dispatch('ws/collaborativeEventTreated')
}

function addParentBlock(event) {
  store.dispatch('ws/sendEvent', { event: event })
  if (item.value.type === 'item') {
    item.value = {
      operator: 'AND',
      type: 'block',
      conditions: [
        {
          operator: 'AND',
          type: 'block',
          conditions: [
            item.value
          ]
        },
        {
          operator: 'AND',
          type: 'block',
          conditions: [
            newItemTemplate()
          ]
        },
      ]
    }
  } else {
    item.value = {
      operator: 'AND',
      type: 'block', 
      conditions: [
        item.value,
        newItemTemplate()
      ]
    }
  }
  store.dispatch('ws/collaborativeEventTreated')
}

function newItemTemplate() {
  const defaultFormContent = customFilteringFormContent.STRICT_VALUE
  return {
    operator: 'AND',
    type: 'item',
    typeCondition: defaultFormContent,
    item: FilterCustom.getTemplateFor(defaultFormContent)
  }
}

function onRemove(indexConditions, count) {
  if (count === 1) {
    if (item.value.conditions.length > 1) {
      item.value.conditions = item.value.conditions.filter((v, index) => index !== indexConditions)
    } else {
      emits('remove', count + 1)
    }
  }

  if (count === 2) {
    item.value.conditions = item.value.conditions.filter((v, index) => index !== indexConditions)
    if (item.value.conditions.length === 1) {
      item.value = (item.value.conditions[0].conditions !== undefined)
        ? item.value.conditions[0].conditions[0]
        : item.value.conditions[0]
    }
  }
}

function onRemoveForm() {
  emits('remove', 1)
}
</script>

<style scoped>
.operator {
  @apply mt-7 mb-7 ml-3 cursor-pointer underline underline-offset-2;
}

.options-button {
  @apply cursor-pointer;
  font-size: 1.5rem;
}

.group {
  @apply ml-6;
  border-left: 2px dashed var(--c-gray-1);
}

.condition-items {
  @apply mt-2;
}

</style>