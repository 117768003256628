import * as mutationTypes from '@/store/mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'

const getDefaultState = () => {
  return {
    diabEvent: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    diabEvent: state => state.diabEvent
  },
  mutations: {
    [mutationTypes.SET_DIAB_EVENT] (state, payload) {
      state.diabEvent = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    async getDiabInfo(context) {
      //Paramètre mis à des valeur d'initialisation car aucune importance pour le moment
      const requestBody = await context.dispatch('getRepresentationReqBody', {}, { root: true })
      requestBody.idPatient = context.rootState.patient.idPatient
      let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/diabete/`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        ErrorManager.requestError(response, {origin: 'medical/diabete/getDiabInfo', params: null})
        return
      }
      response = await response.json()

      context.commit(mutationTypes.SET_DIAB_EVENT, response.currentDiab)
    }
  }
}