<template>
  <div>
    <Tutorial
      :all-components="allComponents"
    />
  </div>
</template>

<script>
export default {
  name: 'TutorialLauncherPriseEnMain'
}
</script>

<script setup>
import { markRaw } from 'vue'
import Tutorial from '@/components/tutorial/Tutorial.vue'

import Component1  from '@/components/tutorial/priseEnMain/Component1.vue'
import Component2  from '@/components/tutorial/priseEnMain/Component2.vue'
import Component3  from '@/components/tutorial/priseEnMain/Component3.vue'
import Component4  from '@/components/tutorial/priseEnMain/Component4.vue'
import Component5  from '@/components/tutorial/priseEnMain/Component5.vue'
import Component6  from '@/components/tutorial/priseEnMain/Component6.vue'
import Component7  from '@/components/tutorial/priseEnMain/Component7.vue'
import Component8  from '@/components/tutorial/priseEnMain/Component8.vue'
import Component9  from '@/components/tutorial/priseEnMain/Component9.vue'
import Component10 from '@/components/tutorial/priseEnMain/Component10.vue'
import Component11 from '@/components/tutorial/priseEnMain/Component11.vue'
import Component12 from '@/components/tutorial/priseEnMain/Component12.vue'
import Component13 from '@/components/tutorial/priseEnMain/Component13.vue'
import Component14 from '@/components/tutorial/priseEnMain/Component14.vue'
import Component15 from '@/components/tutorial/priseEnMain/Component15.vue'
import Component16 from '@/components/tutorial/priseEnMain/Component16.vue'
import Component17 from '@/components/tutorial/priseEnMain/Component17.vue'
import Component18 from '@/components/tutorial/priseEnMain/Component18.vue'
import Component19 from '@/components/tutorial/priseEnMain/Component19.vue'
import Component20 from '@/components/tutorial/priseEnMain/Component20.vue'
import Component21 from '@/components/tutorial/priseEnMain/Component21.vue'
import Component22 from '@/components/tutorial/priseEnMain/Component22.vue'

const allComponents = [
  {
    component: markRaw(Component1),
    idTutoPartRecord: 1
  },
  {
    component: markRaw(Component2),
    idTutoPartRecord: 2
  },
  {
    component: markRaw(Component3),
    idTutoPartRecord: 3
  }, 
  {
    component: markRaw(Component4),
    idTutoPartRecord: 4
  }, 
  {
    component: markRaw(Component5),
    idTutoPartRecord: 5
  }, 
  {
    component: markRaw(Component6),
    idTutoPartRecord: 6
  }, 
  {
    component: markRaw(Component7),
    idTutoPartRecord: 7
  }, 
  {
    component: markRaw(Component8),
    idTutoPartRecord: 8
  }, 
  {
    component: markRaw(Component9),
    idTutoPartRecord: 9
  },
  {
    component: markRaw(Component10),
    idTutoPartRecord: 10
  }, 
  {
    component: markRaw(Component11),
    idTutoPartRecord: 11
  }, 
  {
    component: markRaw(Component12),
    idTutoPartRecord: 12
  }, 
  {
    component: markRaw(Component13),
    idTutoPartRecord: 13
  }, 
  {
    component: markRaw(Component14),
    idTutoPartRecord: 14
  }, 
  {
    component: markRaw(Component15),
    idTutoPartRecord: 15
  }, 
  {
    component: markRaw(Component16),
    idTutoPartRecord: 16
  }, 
  {
    component: markRaw(Component17),
    idTutoPartRecord: 17
  }, 
  {
    component: markRaw(Component18),
    idTutoPartRecord: 18
  },
  {
    component: markRaw(Component19),
    idTutoPartRecord: 19
  },
  {
    component: markRaw(Component20),
    idTutoPartRecord: 20
  }, 
  {
    component: markRaw(Component21),
    idTutoPartRecord: 21
  }, 
  {
    component: markRaw(Component22),
    idTutoPartRecord: 22
  }
]
</script>