<template>
  <div
    v-if="displayFollowUpDiabParcours"
    class="parcours-family-history mb-4"
    @click="onClick"
  >
    <ChronologyItem
      :index="0"
    >
      <template #right-content>
        <p>Suivi diabète</p>
      </template>
    </ChronologyItem>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import ChronologyItem from '@/components/utils/ChronologyItem.vue'
import * as HierarchyEntries from '@/libraries/hierarchy/hierarchyEntries.js'

const store = useStore()

const displayFollowUpDiabParcours = computed(() => store.getters['dataExtension'].diabFollowUp === true)

async function onClick(event) {
  store.dispatch('ws/sendEvent', {event: event })
  await HierarchyEntries.diabNurseEntryTrt(store)
  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
.parcours-family-history * {
  cursor: pointer;
}
</style>