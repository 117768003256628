<template>
  <div>
    <label>
      Plus grand que:
      <input
        v-model="data.lowerBound"
        type="number"
        @input="(e) => onInput(e, data.lowerBound, false)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomFilteringFormGreaterThan'
}
</script>

<script setup>
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const data = defineModel({
  type: Object,
  required: true
})

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])
</script>
