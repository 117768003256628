<template>
  <div>
    <img
      src="../assets/tronc_3.png"
    >
  </div>
</template>
  
<script>
export default {
  name: 'DecouverteTutoContent4'
}
</script>