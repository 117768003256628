import linesGenerator from '@/libraries/LinesGenerator.js'
import linesTypes from '@/enums/lines_types.js'
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import * as mutationTypes from '@/store/mutations-types'

import selectionTypes from '@/enums/event_selection_types.js'
import _ from 'lodash'
import utils from '@/libraries/utils.js'

export default {
  data: () => ({
    /**
     * Il s'agit de l'id qui sera utilisé pour le groupe contenant les lignes de filtrages
     * @type {String}
     */
    idGroupLines: 'group-lines'
  }),
  computed: {
    ...mapGetters({
      referenceRadius: "layout/radius",
      symbols: 'event/filtering/symbols'
    })
  },
  methods: {
    /**
     * Cette fonction gère l'affichage du filtrage d'évenement
     * @method
     * @public
     * @param {EyeEventFiltering} events Il s'agit des événements devant être affichés par le filtrage
     */
    showFilters(events) {
      let paths = []
      let symbols = []

      d3.select(`#${this.idGroupLines}`)
        .selectAll('path')
        .remove()

      Object.values(events).forEach(filteredSelection => {
        Object.values(filteredSelection.sections).forEach(filteredSelectionSection => {
          const lines = linesGenerator.betweenEvents(filteredSelectionSection, this.referenceRadius)
          symbols = [...symbols, ...lines.symbols]
          paths = [...paths, ...lines.paths]
        })
      })

      this.drawLineBetweenSimilarEvent(paths)
      for (const symbol of symbols) {
        symbol.selectionTypes = selectionTypes.FILTERING
      }
      
      symbols = [...symbols, ...this.symbols.filter(symbol => symbol.selectionTypes !== selectionTypes.FILTERING)]
      this.$store.commit(`event/filtering/${mutationTypes.SET_SYMBOLS}`, symbols)
    },
    /**
     * Cette fonction gère l'affichage des lignes entre les événement similaire (avec le même code)
     * @method
     * @public
     * @param {Object[]} paths Il s'agit d'un tableau contenant les informations de chaque lignes devant être tracées
     */
    drawLineBetweenSimilarEvent(paths) {
      d3.select(`#${this.idGroupLines}`)
        .selectAll('path')
        .data(paths)
        .join("path")
        .attr("d", (d) => d.path)
        .attr("class", (d) => `${d.resourceTypeClass} trace-filtering`)
        .attr("stroke-linecap", "butt")
        .attr("stroke-dasharray", (d,i,nodes) => {
          if (d.type !== linesTypes.DASH) {
            return nodes[i].getTotalLength()
          } else {
            const sizeGap = 3
            const edges = Math.ceil(nodes[i].getTotalLength() * 0.05)
            let middle = Math.floor((nodes[i].getTotalLength() - edges * 2) / sizeGap)
            middle = (middle % 2 !== 0) ? middle : middle - 1
            const end = nodes[i].getTotalLength() - edges - middle * sizeGap
            return `${edges}${` ${sizeGap}`.repeat(middle)} ${end}`
          }
        })
    },
    /**
     * Cette fonction gère la suppression des filtres actuellement affichés
     * @method
     * @public
     */
    removeFiltering() {
      const selection = d3
        .select(`#${this.idGroupLines}`)
        .selectAll('path')

      selection.interrupt()
      selection.transition()
        .duration(D3Animation.LINES_REPORT_DISAPPEAR)
        .style("opacity", 0)
        .on('end', () => {
          selection.remove()
        })

      let symbols = [...this.symbols]
      symbols = symbols.filter(symbol => symbol.selectionTypes !== selectionTypes.FILTERING)

      this.$store.commit(`event/filtering/${mutationTypes.SET_SYMBOLS}`, symbols)
    },
  }
}