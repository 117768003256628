import * as mutationTypes from '@/store/mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

const getDefaultState = () => {
  return {
    /**
     * Il s'agit des informations du cercle des événements associés
     * @type {null|Object}
     */
    associatedEventsCircle: null,
    /**
     * Evenement pour lequel le cercle des événements associés est obtenu
     * @type {EyeEvent|null}
     */
    srcEvent: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    /**
     * Cette fonction permet de mettre à jour les données du cercle des événements associés
     * @param {State} state state du module
     * @param {EyeCircle} payload Données du cercle des événements associés
     */
    [mutationTypes.UPDATE_ASSOCIATED_EVENTS_CIRCLE](state, payload) {
      state.associatedEventsCircle = payload
    },
    /**
     * Permet de mettre à jour l'événement dont le cercle des associés est affiché
     * @param {State} state state du module
     * @param {EyeEvent} payload Evenement pour lequel est affiché le cercle des associés
     */
    [mutationTypes.UPDATE_SRC_EVENT_ASSOCIATED](state, payload) {
      state.srcEvent = payload
    },
    [mutationTypes.DISABLE_ASSOCIATED_EVENT_CIRCLE]() {
      this.commit(`refCircle/associatedEvents/${mutationTypes.UPDATE_ASSOCIATED_EVENTS_CIRCLE}`, null)
      this.commit(`refCircle/associatedEvents/${mutationTypes.UPDATE_SRC_EVENT_ASSOCIATED}`, null)
      this.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`)
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    /**
     * Permet d'obtenir le cercle des événements associés
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     * @param {EyeEvent} event Evenement pour lequel sera obtenu le cercle des associés
     */
    async getAssociatedEvents(context, event) {
      const body = await context.dispatch('getRepresentationReqBody', {
        mode: {
          type: eyediagMode.mode.PATIENT,
          options: {}
        },
        extraData: {
          code: event.code,
        }
      }, {root: true})

      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/associated-events/`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(body)
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'refCircle/associatedEvents/getAssociatedEvents', params: event})
          return
        }

        response = await response.json()

        context.commit(`refCircle/score/${mutationTypes.DISPLAY_SCORE_TOOLTIP}`, null, {root: true})

        context.commit(mutationTypes.UPDATE_ASSOCIATED_EVENTS_CIRCLE, response.associatedEventsCircle)
        context.commit(`refCircle/score/${mutationTypes.UPDATE_SCORE_CHECKER}`, response.scoreChecker, {root: true})
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    },
    async getListAssociatedEvents(context, code) {
      let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/associated-events/${code}`, {
        method: 'GET',
        credentials: 'include'
      })

      if (!response.ok) {
        ErrorManager.requestError(response, {origin: 'refCircle/associatedEvents/getListAssociatedEvents', params: code})
        return []
      }

      response = await response.json()
      return response
    }
  }
}