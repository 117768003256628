<template>
  <div
    ref="baseBorderList"
    class="base-border-list float-right top-0 right-0 bottom-0 border select-none"
    :style="baseBorderListStyle"
    :class="baseBorderListClass"
    @mousemove="sendCheckScroll"
    @transitionend="onTransitionend"
  >
    <div
      v-if="isOpen"
      ref="resize"
      class="resize-border-list absolute h-screen cursor-ew-resize"
      @mousedown="resizeEvent"
    />
    <div class="style-borderList-info">
      <h3
        v-if="title !== null"
        class="m-b-20 text-center"
      >
        {{ title }}
      </h3>
      <slot name="content" />
    </div>
  </div>
</template>

<script>

import * as d3 from 'd3'
import * as mutationTypes from "@/store/mutations-types"
import utils from '@/libraries/utils.js'
import { mapActions } from 'vuex'

export default {
  name: 'ListBase',
  props: {
    title: {
      type: [String, null],
      required: false,
      default: null
    }
  },
  data: () => ({
    //position
    mPos: 0,
    baseBorderListStyle: {
      width: '0px'
    },
    baseBorderListClass: {
      'base-border-list-animate': false
    },
    isOpen: false
  }),
  methods: {
    ...mapActions({
      sendProperties: 'ws/sendProperties'
    }),
    /**
    * Fonction permettant de lancer la redimenssion de la fenêtre avec l'appelde la fonction resize de la borderlist à droite
    * @method
    * @public
    * @param {event} e
    */
    resizeEvent(e) {
      this.mPos = e.x

      d3.select('#root-app').on("mousemove", this.resize)
      d3.select('#root-app').on("mouseup", () => {
        d3.select('#root-app').on("mousemove", null)
        d3.select('#root-app').on("mouseup", null)
      })
    },
    /**
    * Permet de changer la taille des listes avec une limite entre 200 et 700px
    * @method
    * @public
    * @param {event} e
    */
    resize(e) {
      const sizeBorderList = +(this.baseBorderListStyle.width.slice(0, -2)) //on vire le px à la fin + conversion en int
      const dx = this.mPos - e.x
      const nbPixel = sizeBorderList + dx
      if (nbPixel > 199 && nbPixel < 700) {
        this.mPos = e.x
        this.baseBorderListStyle.width = `${nbPixel}px`
        this.sendProperties({
          target: this.$refs.baseBorderList,
          params: { style: { width: this.baseBorderListStyle.width }}
        })
      }
    },
    /**
     * Permet de gérer l'ouverture et la fermeture du conteneur permettant de selectionner les éléments présent dans l'assemblage que l'on souhaite sauvegarder
     * @method
     * @public
     * @param {Boolean} isDisplayed Détermine si la liste doit être affiché
     */
    async movePanel(isDisplayed) {
      this.isOpen = isDisplayed
      this.baseBorderListClass['base-border-list-animate'] = true
      this.baseBorderListStyle.width = isDisplayed
        ? '200px'
        : '0px'

      this.$store.commit(`borderList/${mutationTypes.UPDATE_DISPLAYED_LIST}`, isDisplayed)
    },
    onTransitionend(event) {
      if (this.baseBorderListClass['base-border-list-animate'] === true && event.propertyName === 'width') {
        this.baseBorderListClass['base-border-list-animate'] = false
      }
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur du conteneur de filtrages des événements par temps puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    sendCheckScroll(e) {
      utils.sendCheckScroll(e, this.$store)
    }
  }
}
</script>

<style scoped>
.base-border-list {
  background-color: var(--color-bg-1);
  overflow-y: scroll;
  overflow-x: hidden;
  color: var(--color-text);
  height: calc(100vh - 50px);
}

.base-border-list-animate {
  transition: width 500ms ease;
}

.resize-border-list {
  border-left: solid var(--color-border);
  border-left-width: 1px;
  z-index: 7;
  width: 6px
}
.style-borderList-info{
  margin-inline: 15px;
  margin-top: 20px;
}
</style>