import FilterCustom from './filterCustom.js'

export default {
  /**
   * Permet d'appliquer le filtrage par sévérité sur les événements
   */
  filterBySeverity(events, filters, filteredEvents) {
    if (filters.severity) {
      events.forEach(event => {
        if (event.severity >= filters.severity.min && event.severity <= filters.severity.max && filteredEvents.find(filtered => filtered.id === event.id) === undefined) {
          filteredEvents.push(event)
        }
      })
    }

    return filteredEvents
  },

  /**
   * Permet d'appliquer le filtrage par nombre d'antécédents sur les événements
   * @param {*} events liste des évenements
   * @param {*} filters objet contenant l'ensemble des paremètres du filtrages
   * @param {*} filteredEvents liste d'objet (à préciser ce que c'est réelement
   * @param {*} antecedentRank 
   * @returns 
   */
  filterByAntecedent(events, filters, filteredEvents, antecedentRank) {
    if (filters.antecedent) {
      events.forEach(event => {
        if (antecedentRank.get(event.code) >= filters.antecedent.min && filteredEvents.find(filtered => filtered.id === event.id) === undefined) {
          filteredEvents.push(event)
        }
      })
    }

    return filteredEvents
  },
  
  /**
   * Permet d'appliquer le filtrage par rapport aux événements les plus récents
  */
  filterByLast(events, filters, filteredEvents) {
    if (filters.last) {
      const eventsToDisplay = events.sort((a, b) => new Date(b.onsetDateTime) - new Date(a.onsetDateTime)).slice(0, filters.last)
      for (const event of eventsToDisplay) {
        filteredEvents.push(event)
      }
    }

    return filteredEvents
  },

  filterByCodes(events, filters, filteredEvents) {
    if (filters.codes) {
      const codes = filters.codes.map(e => e.code)
      events.forEach(event => {
        if (codes.includes(event.code)) {
          filteredEvents.push(event)
        }
      })
    }

    return filteredEvents
  },

  filterBySection(events, filters, filteredEvents) {
    if (filters.sections) {
      for (const event of events) {
        if (filters.sections.includes(event.idSection)) {
          filteredEvents.push(event)
        }
      }
    }
  },

  filterByText(events, filters, filteredEvents) {
    if (filters.text) {
      for (const event of events) {
        for (let extension of Object.values(event.extension)) {
          extension += ''
          extension = extension.toLowerCase()
  
          if (extension.includes(filters.text.toLowerCase())) {
            filteredEvents.push(event)
          }
        }
      }
    }

    return filteredEvents
  },

  filterCustom(events, filters, filteredEvents) {
    const filterCustom = new FilterCustom(events, filters, filteredEvents)
    filterCustom.filterCustom()
  }
}