<template>
  <div
    ref="label-container"
    class="label-container"
  />
</template>

<script>
import * as d3 from 'd3'
import CircleUtility from '@/libraries/CircleUtility.js'
import { mapGetters } from 'vuex'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import D3Animation from '@/config/D3Animation.js'

const labelMargin = 20

/**
 * Tableau contenant les événements dont les libellés doivent être affichés
 * @type {EyeEvent[]}
 */
let copyEvents = []
/**
 * Stocke le rayon de base des cercles pour lequel les libellés ont été affichés
 * @type {Number}
 */
let oldBaseRadius = 0
/**
 * Contient la référence de la simulation de force créé par D3js
 * @type {D3Object}
 */
let simulation = null

export default {
  name: "EyeLabel",
  computed: {
    ...mapGetters({
      events: "event/common/labelToDisplay",
      baseRadius: 'layout/radius',
      ratioRemPx: 'ratioRemPx',
      eyediagMode: 'eyediagMode',
      isInNurseEntry: 'isInNurseEntry',
      hoveredEvent: 'event/common/hoveredEvent',
      isInPopulational: 'isInPopulational'
    })
  },
  watch: {
    ratioRemPx() {
      this.resetEvents()
      this.updateEvents()
    },
    async events(newVal) {
      this.updateEvents()
    },
  },
  mounted() {
    this.setupSimulation()
  },
  methods: {
    /**
     * Permet la réinitialisation du composant en supprimant les libellés affichés
     * @method
     * @public
     */
    resetEvents() {
      d3.select(this.$refs["label-container"])
        .selectAll('.event-label')
        .remove()

      copyEvents = []
    },
    /**
     * Permet la mise à jour de l'affichage des libellés à l'écran
     * @method
     * @public
     */
    async updateEvents() {
      //en cas de redimensionnement de la fenêtre faire un reset des libellés
      if (oldBaseRadius !== this.baseRadius) {
        this.resetEvents()
      }
      oldBaseRadius = this.baseRadius

      if (simulation !== null) {
        simulation.stop();
      }
      this.updateNodes()
      try {
        await this.initLabels()
      } catch (err) {
        return;
      }
      this.calcSizeRect();
      this.updateSimulation();
    },
    /**
     * Permet l'initialisation de l'affichage des libellés des événements lorsque Eyediag est en mode dossier patient. La fonction gére les animations d'entrées des nouveaux libellés et les animations de sorties des libellés ne devant plus être affichés
     * @method
     * @public
     */
    async initLabels() {
      const transitions = []
      d3.select(this.$refs["label-container"])
        .selectAll(".event-label")
        .interrupt()

      d3.select(this.$refs["label-container"])
        .style("position", "absolute")
        .selectAll(".event-label")
        .data(copyEvents, (d) => `${d.id}${d.parentSection}`)
        .join(
          (enter) => {
            const group = enter
              .append("div")
              .attr("class", "event-label")
              .style("position", "absolute")
              .style("opacity", 0)

            group.each((d, i, nodes) => {
              if (this.eyediagMode.options.merge === undefined) {
                this.createLabelPatient(d, nodes[i])
              } else {
                if (_.isString(d.id) && d.id.includes('SCORE-')) {
                  this.createLabelPatient(d, nodes[i])
                } else {
                  this.createLabelNbEventsPerSection(d, nodes[i])
                }
              }
            })

            group.each((d, i, nodes) => {
              const labelPos = d3.pointRadial(d.angle, d.radiusEvent + d.r + 15)
              const containerRect = nodes[i].getBoundingClientRect()
              d.x = labelPos[0]
              d.y = labelPos[1] - containerRect.height / 2
              if (d.anchor === 'end') {
                d.x -= containerRect.width
              }

              d3.select(nodes[i])
                .style("top", (d) => `${d.y}px`)
                .style("left", (d) => d.anchor === 'start'
                  ? `${d.x + 10}px`
                  : `${d.x - 10}px`)
                .style('text-align', d.anchor === 'start' ? 'left': 'right')
            })

            transitions.push(
              group.transition()
                .duration(D3Animation.LABEL_APPEAR)
                .style('left', (d) => `${d.x}px`)
                .style('opacity', 1)
                .end()
            )
          },
          null,
          (exit) => {
            transitions.push(
              exit
                .transition()
                .duration(D3Animation.LABEL_DISAPPEAR)
                .style("left", (d) => (d.anchor === "end" ? `${d.x - 25}px` : `${d.x + 25}px`))
                .style("opacity", 0)
                .on("end", () => exit.remove())
                .on("interrupt", () => exit.remove())
                .on("cancel", () => exit.remove())
                .end()
            )
          }
        );

      return Promise.all(transitions);
    },
    createLabelPatient(d, elem) {
      d3.select(elem)
        .append("p")
        .text(this.titleComputed(d.defaultLabel))

      if (this.isInNurseEntry === false && this.hoveredEvent !== null && d.id === this.hoveredEvent.id) {
        d3.select(elem)
          .append("p")
          .text(this.subTitleComputed(d.onsetDateTime))
      }
    },
    createLabelNbEventsPerSection(d, elem) {
      for (let j = 0; j < 3 && j < d.ranks.length; j++) {
        const label = this.isInPopulational
          ? `${d.ranks[j].size} ${d.ranks[j].code} - ${this.titleComputed(d.ranks[j].label)}`
          : `${d.ranks[j].size} ${d.ranks[j].code}`

        d3.select(elem)
          .append("p")
          .text(label)
      }
    },
    /**
     * Cette fonction la mise à jour de la position des libellés. Elle est appelé par la simulation de force de d3js
     * @method
     * @public
     */
    drawLabels() {
      d3.select(this.$refs["label-container"])
        .selectAll(".event-label")
        .data(copyEvents, (d) => `${d.id}${d.parentSection}`)
        .style('opacity', 1) // important, lors de va et viens rapide les libellés peuvent disparaitre sans cette ligne
        .style("top", (d) => `${d.y}px`)
        .style("left", (d) => `${d.x}px`)
    },
    /**
     * Cette fonction permet d'initialiser la simulation de force que d3js appliquera sur l'ensemble des libellés
     * @method
     * @public
     */
    setupSimulation() {
      simulation = d3
        .forceSimulation(copyEvents)
        .alpha(1)
        .on("tick", this.ticked);

      simulation.stop();
    },
    /**
     * Cette fonction permet de mettre à jour la simulation de force avec de nouveaux libellés et de redémarrer la simulation
     * @method
     * @public
     */
    updateSimulation() {
      simulation = simulation
        .nodes(copyEvents)
        .alpha(1)
        .force("collision", this.collisionForce())
        .tick(0);

      simulation.restart();
    },
    /**
     * Cette fonction est appelé automatiquement par la simulation de force de d3js "à chaque tour" de la simulation
     * @method
     * @public
     */
    ticked() {
      this.drawLabels();
    },
    /**
     * Cette fonction permet la copie des événements dans la variable local newCopyEvents du composant et de déterminer la position de base du libellé (a gauche ou a droite)
     * @method
     * @public
     */
    updateNodes() {
      const newCopyEvents = [];
      let invert = false;

      this.events.forEach((event) => {
        const tmp = { ...event };

        const previousPosition = copyEvents.find((e) => e.id === event.id);
        if (previousPosition) {
          newCopyEvents.push(previousPosition);
        } else {
          tmp.x = event.cx;
          tmp.y = event.cy;
          newCopyEvents.push(tmp);
        }
      });

      for (let i = 0; i < newCopyEvents.length; i++) {
        const angle = newCopyEvents[i].angle;
        if (copyEvents.find((e) => e.id === newCopyEvents[i].id)) {
          continue;
        }
        if (i === 0) {
          this.place(newCopyEvents[i]);
          continue;
        }
        if (
          (angle > CircleUtility.degreesToRadians(150) &&
            angle < CircleUtility.degreesToRadians(210)) ||
          (angle > CircleUtility.degreesToRadians(330) &&
            angle < CircleUtility.degreesToRadians(390))
        ) {
          if (
            Math.abs(newCopyEvents[i - 1].cx - newCopyEvents[i].cx) < 20 &&
            !invert
          ) {
            this.placeInvert(newCopyEvents[i]);
            invert = true;
          } else {
            this.place(newCopyEvents[i]);
            invert = false;
          }
        } else if (
          Math.abs(newCopyEvents[i - 1].cy - newCopyEvents[i].cy) < 20 &&
          !invert
        ) {
          this.placeInvert(newCopyEvents[i]);
          invert = true;
        } else {
          this.place(newCopyEvents[i]);
          invert = false;
        }
      }
      copyEvents = newCopyEvents;
    },
    /**
     * Permet de déterminer la position et les dimensions du rectangle dans lequel le libellés doit être écrit
     * @method
     * @public
     */
    calcSizeRect() {
      d3.select(this.$refs["label-container"])
        .selectAll(".event-label")
        .each((d, i, nodes) => {
          const containerRect = nodes[i].getBoundingClientRect()
          d.rect = {
            x: d.x,
            y: d.y,
            width: containerRect.width,
            height: containerRect.height,
          };
        });
    },
    /**
     * Permet de déterminer la position du libellé lorsque le précédent libellé était affiché dans le sens inverse
     * @method
     * @public
     */
    placeInvert(elem) {
      if (elem.x < 0) {
        elem.anchor = "start";
        elem.x = elem.x + labelMargin;
      } else {
        elem.anchor = "end";
        elem.x = elem.x - labelMargin;
      }
    },
    /**
     * Permet de déterminer la position du libellé
     * @method
     * @public
     */
    place(elem) {
      if (elem.x < 0) {
        elem.anchor = "end";
        elem.x = elem.x - labelMargin;
      } else {
        elem.anchor = "start";
        elem.x = elem.x + labelMargin;
      }
    },
    /**
     * Retourne une version courte du libellé principal de l'événement
     * @method
     * @public
     */
    titleComputed(title) {
      return title.length > 30 ? title.substring(0, 30) + "..." : title;
    },
    /**
     * Retourne une version courte du sous libellé de l'événement
     * @method
     * @public
     */
    subTitleComputed(subtitle) {
      return subtitle ? subtitle.substring(0, 10) : null;
    },
    /**
     * Force devant s'appliquer au libellés durant la simulation de force
     * @method
     * @public
     */
    collisionForce() {
      return this.rectCollide().size((d) => {
        return [d.rect.width, d.rect.height];
      });
    },
    /**
     * 
     * Les fonctions suivantes permettent de mettre en place une force de collision sur des box rectangles, ce code provient d'internet plus de détail sur l'algo est disponible à l'adresse suivante
     * Collision Rectangle Explication
     * https://lvngd.com/blog/rectangular-collision-detection-d3-force-layouts/
     * Collision Rectangle Implémentation utilisée
     * https://bl.ocks.org/cmgiven/547658968d365bcc324f3e62e175709b
     */
    rectCollide() {
      var nodes, sizes, masses;
      nodes = copyEvents;
      var size = this.constant([0, 0]);
      var strength = 1;
      var iterations = 1;

      function force() {
        var node, size, mass, xi, yi;
        var i = -1;
        while (++i < iterations) {
          iterate();
        }

        function iterate() {
          var j = -1;
          var tree = d3.quadtree(nodes, xCenter, yCenter).visitAfter(prepare);

          while (++j < nodes.length) {
            node = nodes[j];
            size = sizes[j];
            mass = masses[j];
            xi = xCenter(node);
            yi = yCenter(node);

            tree.visit(apply);
          }
        }

        function apply(quad, x0, y0, x1, y1) {
          var data = quad.data;
          var xSize = (size[0] + quad.size[0]) / 2;
          var ySize = (size[1] + quad.size[1]) / 2;
          if (data) {
            if (data.index <= node.index) {
              return;
            }

            var x = xi - xCenter(data);
            var y = yi - yCenter(data);
            var xd = Math.abs(x) - xSize;
            var yd = Math.abs(y) - ySize;

            if (xd < 0 && yd < 0) {
              var l = Math.sqrt(x * x + y * y);
              var m = masses[data.index] / (mass + masses[data.index]);

              if (Math.abs(xd) < Math.abs(yd)) {
                node.vx -= (x *= (xd / l) * strength) * m;
                data.vx += x * (1 - m);
              } else {
                node.vy -= (y *= (yd / l) * strength) * m;
                data.vy += y * (1 - m);
              }
            }
          }

          return (
            x0 > xi + xSize ||
            y0 > yi + ySize ||
            x1 < xi - xSize ||
            y1 < yi - ySize
          );
        }

        function prepare(quad) {
          if (quad.data) {
            quad.size = sizes[quad.data.index];
          } else {
            quad.size = [0, 0];
            var i = -1;
            while (++i < 4) {
              if (quad[i] && quad[i].size) {
                quad.size[0] = Math.max(quad.size[0], quad[i].size[0]);
                quad.size[1] = Math.max(quad.size[1], quad[i].size[1]);
              }
            }
          }
        }
      }

      function xCenter(d) {
        return d.x + d.vx + sizes[d.index][0] / 2;
      }
      function yCenter(d) {
        return d.y + d.vy + sizes[d.index][1] / 2;
      }

      force.initialize = function (_) {
        sizes = (nodes = _).map(size);
        masses = sizes.map(function (d) {
          return d[0] * d[1];
        });
      };

      force.size = function (_) {
        return arguments.length
          ? ((size = typeof _ === "function" ? _ : this.constant(_)), force)
          : size;
      };

      force.strength = function (_) {
        return arguments.length ? ((strength = +_), force) : strength;
      };

      force.iterations = function (_) {
        return arguments.length ? ((iterations = +_), force) : iterations;
      };

      return force;
    },
    constant(_) {
      return function () {
        return _;
      };
    },
  },
};
</script>

<style>
.sections{
  fill: var(--color-text);
}
.event-label {
  background-color: var(--color-bg-1);
  color: var(--color-text);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: max-content;
}
</style>