<template>
  <div>
    <ImportButton
      text="Importer"
      accept=".json"
      @content="onFileContent"
    />
    <p
      v-if="successImport"
      class="mt-2"
    >
      Importation réussi
    </p>
  </div>
</template>

<script setup>
import ImportButton from '@/components/utils/ImportButton.vue'
import { ref } from 'vue'

const options = defineModel({
  type: Object,
  required: true
})
const successImport = ref(false)

options.value.sections = []

function onFileContent(content) {
  try {
    options.value.sections = JSON.parse(content)
    successImport.value = true
  } catch {
    successImport.value = false
    options.value.sections = []
  }
}
</script>