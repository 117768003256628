<template>
  <div>
    <RoomsManager />
    <TutoRecording />
    <Tuto />
    <Tips />
  </div>
</template>

<script>
export default {
  name: 'EyeHud'
}
</script>

<script setup>
import RoomsManager from '@/components/hud/collaborative/RoomsManager.vue'
import TutoRecording from '@/components/hud/tuto/recording.vue'
import Tuto from '@/components/hud/tuto/Tuto.vue'

import Tips from '@/components/hud/tips/Tips.vue'

</script>