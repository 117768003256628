<template>
  <div
    v-if="displayHistoryFamilyParcours"
    class="parcours-family-history mb-4"
    @click="onClick"
  >
    <ChronologyItem
      :index="0"
      color="var(--c-orange)"
      :selected="isDisplayedFamilyHistoryCircle"
    >
      <template #right-content>
        <p>ATCD.FAM</p>
      </template>  
    </ChronologyItem>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import ChronologyItem from '@/components/utils/ChronologyItem.vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const displayHistoryFamilyParcours = computed(() => store.getters['refCircle/familyHistory/familyHistoryEvents']?.length > 0)
const isDisplayedFamilyHistoryCircle = computed(() => store.getters['refCircle/familyHistory/isDisplayedFamilyHistoryCircle'])

function onClick(event) {
  store.dispatch('ws/sendEvent', {event: event })

  const displayCircle = isDisplayedFamilyHistoryCircle.value === true
    ? false
    : true

  store.commit(`refCircle/familyHistory/${mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE}`, displayCircle)
  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
p {
  color: var(--c-orange);
}

.parcours-family-history * {
  cursor: pointer;
}
</style>