<template>
  <div v-if="show">
    <TutorialPrise v-if="idDisplayedTuto === 1" />
    <Tutorial1 v-else-if="idDisplayedTuto === 6" />
    <Tutorial2 v-else-if="idDisplayedTuto === 4" />
    <Tutorial3 v-else-if="idDisplayedTuto === 5" />
    <TutorialConcept v-else />
  </div>
</template>

<script>
export default {
  name: 'EyeTuto'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted } from 'vue'
import TutorialPrise from "@/components/tutorial/priseEnMain/Launcher.vue"
import TutorialConcept from "@/components/tutorial/concepts/Launcher.vue"
import Tutorial1 from '@/components/tutorial/1/Launcher.vue'
import Tutorial2 from '@/components/tutorial/2/Launcher.vue'
import Tutorial3 from '@/components/tutorial/3/Launcher.vue'

const store = useStore()

const show = ref(false)
const idDisplayedTuto = computed(() => store.getters['tuto/idDisplayedTuto'])

watch(idDisplayedTuto, () => {
  onIdDisplayedTutoChange()
})

onMounted(() => {
  onIdDisplayedTutoChange()
})

async function onIdDisplayedTutoChange() {
  if (idDisplayedTuto.value !== null) {
    await store.dispatch('tuto/getTutoParts')
    show.value = true
  } else {
    show.value = false
  }
}

</script>