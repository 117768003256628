<template>
  <div ref="graphs">
    <Collapse 
      v-if="typeEntityContext === UserRoleGENERALISTE"
      @collapse="collapsing"
    >
      <template #title>
        <p 
          v-if="periodUnit == 2"
          class="tooltip_title" 
        >
          Occurences par Semestre
        </p>
        <p 
          v-else
          class="tooltip_title"
        >
          Occurences par Année
        </p>
      </template>
      <template #content>
        <div 
          ref="chart_countParTemps" 
          class="chart_countParTemps"
        />
      </template>
    </Collapse>
    <Collapse 
      v-if="typeEntityContext === UserRoleQOR"
    >
      <template #title>
        <p 
          class="tooltip_title" 
        >
          Survivabilité
        </p>
      </template>
      <template #content>
        <div>
          <label>
            <div 
              ref="chart_suvivabilite" 
              class="chart_suvivabilite"
            />
            <input
              v-model="selectedCondition"
              type="radio"
              value="1"
              @change="(e) => onInput(e, selectedCondition, true)"
            >
            &gt;
          </label>
          <label>
            <input
              v-model="selectedCondition"
              type="radio"
              value="0"
              @change="(e) => onInput(e, selectedCondition, true)"
            >
            &lt;
          </label>
          <input 
            v-model.lazy="seuil" 
            placeholder="edit me"
            @input="(e) => onInput(e, seuil, false)"
          >
          <p> définit seuil au delà duquel considère que « n'est plus à risque »</p>
        </div>
      </template>
    </Collapse>
    <Collapse 
      v-if="typeEntityContext === UserRoleQOR"
      @collapse="collapsing"
    >
      <template #title>
        <p 
          class="tooltip_title" 
        >
          Boites à moustaches
        </p>
      </template>
      <template #content>
        <div>
          <div 
            ref="chart_moustaches" 
            class="chart_moustaches"
          />
        </div>
      </template>
    </Collapse>
  </div>
</template>

<script>
import { countParTemps, survivabilite, moustaches_sev } from './PlotsUtils'
import { mapGetters } from 'vuex'
import * as d3 from "d3";
import periodUnits from '@/shared/enums/period_units'
import UserRole from '@/shared/enums/userRole'
import Collapse from '@/components/Collapse.vue'

import CollaborativeUtils from '@/libraries/collaborative/utils.js'

export default {
  components: {
    Collapse,
  },
  props: {
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      resizeObserver: null,
      UserRoleGENERALISTE: UserRole.GENERALISTE,
      UserRoleQOR: UserRole.QOR,
      seuil: 8,
      selectedCondition:1,
    }
  },
  computed: {
    ...mapGetters({
      events: "event/common/events",
      allEvents: "event/common/allEvents",
      unitPerCircle: "circle/unitPerCircle",
      periodUnit: "circle/periodUnit",
      typeEntityContext: 'user/typeEntityContext',
    }),
  },
  watch: {
    seuil(newVal){
      if (newVal) {
        this.drawChart()
      }
    },
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    drawChart() {
      if (this.$refs.chart_countParTemps) {
        const chartContainer = this.$refs.chart_countParTemps;
        const filteredData = this.allEvents.filter(x => x.code == this.tooltipData.code);
        
        d3.select(chartContainer).select("svg").remove();
        if (chartContainer) {
          countParTemps(filteredData, chartContainer, this.periodUnit);
        }
      }
      if (this.$refs.chart_suvivabilite) {
        // severity
        const chartSurv = this.$refs.chart_suvivabilite;
        const filteredData = this.allEvents
          .filter(x => x.Section == this.tooltipData.Section)
          .map(({  extension, onsetDateTime, id }) =>
            ({ id:id, onsetDateTime: onsetDateTime, value: extension.value }));
        
        d3.select(chartSurv).select("svg").remove();
        if (chartSurv) {
          survivabilite(filteredData, chartSurv, this.seuil, this.selectedCondition, this.typeEntityContext);
        }
      }
      if (this.$refs.chart_moustaches) {
        const chart_moustaches = this.$refs.chart_moustaches
        const filteredData = this.allEvents
          .filter(x => x.Section == this.tooltipData.Section)
          .map(({ onsetDateTime, id, extension }) =>
            ({ id:id , onsetDateTime: onsetDateTime, val:extension.value }));

        d3.select(chart_moustaches).select("svg").remove();
        if (chart_moustaches) {
          moustaches_sev(filteredData, chart_moustaches, this.typeEntityContext)
        }
      }
    },
    collapsing(open) {
      if (open) {
        if (this.resizeObserver == null) {
          this.initResizeObserver()
        }
        this.drawChart()
      } 
      if (Object.values(this.$refs).length == 1 && this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null
      }
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        try {
          for (const entry of entries) {
            this.drawChart();
          }
        } catch (error) {
        }
      });
      if (this.$refs['graphs'] && this.seuil) {
        this.resizeObserver.observe(this.$refs['graphs']);
      }
    },
    onInput(...params) {
      CollaborativeUtils.onInput(...[this.$store, ...params])
    },
  },
}

</script>

<style scoped>

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}
.axis-label {
  font-size: 12px;
  font-family: Arial, sans-serif;
}
</style>