<template>
  <div>
    <img
      v-if="isInDarkMode()"
      src="@/assets/icons/eyediagDark.png"
    >
    <img
      v-else
      src="@/assets/icons/eyediagLight.png"
    >
  </div>
</template>
  
<script>
export default {
  name: 'DecouverteTutoContent'
}
</script>

<script setup>
import utils from '@/libraries/utils.js'

const isInDarkMode = utils.isInDarkMode

</script>