<template>
  <div>
    <div
      v-for="(item, index) in chronology"
      :key="index"
      class="mb-2"
    >
      <ChronologyItem
        :index="index"
        :type-link="item.previousJustBehind ? 'solid' : 'dash'"
      >
        <template #right-content>
          <p class="legend-rep-circle-content-date">
            {{ item.label }}
          </p>
          <p class="mt-1">
            {{ `${item.size} événement${item.size > 1 ? 's' : ''}` }}
          </p>
        </template>
      </ChronologyItem>
    </div>
  </div>
</template>

<script setup>

import { useStore } from 'vuex'
import { computed, defineEmits, onMounted } from 'vue'
import ChronologyItem from '@/components/utils/ChronologyItem.vue'

const store = useStore()

const circles = computed(() => store.getters['circle/circles'])
const chronology = computed(() => {
  const result = []

  let previousJustBehind = true
  for (let i = circles.value.length - 1; i > 0; i--) {
    const circle = circles.value[i]
    const events = circle.points.filter(p => p.peak === true)
    if (events.length > 0) {
      result.push({label: circle.period.label, size: events.length, previousJustBehind})
      previousJustBehind = true
    } else {
      previousJustBehind = false
    }
  }
  return result
})

onMounted(() => {
  emits('title', 'Parcours patient')
})

const emits = defineEmits(['title'])

</script>

<style scoped>
.legend-rep-circle-content-date {
  font-family: var(--font-secondary-2);
  font-weight: 500;
  letter-spacing: 0.03em;
}

</style>