<template>
  <div class="relative">
    <div
      class="exit"
      @click="removeItem"
    >
      X
    </div>
    <div class="form-container">
      <label>
        Donnée
        <select
          v-model="data.key"
          class="mr-2"
          @change="(e) => onInput(e, data.key, true)"
        >
          <option
            v-for="(property, index) in userEventProperties"
            :key="index"
            :value="property.name"
          >
            {{ property.name }}
          </option>
        </select>
      </label>
      <label>
        Type de filtrage
        <select
          v-model="typeCondition"
          @change="(e) => onInput(e, typeCondition, true)"
        >
          <option
            v-for="(content, index) in formContents"
            :key="index"
            :value="content.type"
          >
            {{ content.label }}
          </option>
        </select>
      </label>
      <component
        :is="formContent"
        v-if="formContent"
        v-model="data"
        class="custom-filtering-form-content"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomFilteringForm"
}
</script>

<script setup>
import { ref, computed, markRaw, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import CustomFilteringFormContent from '@/shared/enums/customFilteringFormContent.js'
import FilterCustom from '@/shared/libraries/filterCustom.js'

import ContentBetween from '@/components/event/filters/custom/formContent/Between.vue'
import ContentStrictValue from '@/components/event/filters/custom/formContent/StrictValue.vue'
import ContentGreaterThan from '@/components/event/filters/custom/formContent/GreaterThan.vue'
import ContentSmallerThan from '@/components/event/filters/custom/formContent/SmallerThan.vue'
import ContentExcept from '@/components/event/filters/custom/formContent/ExceptValue.vue'
import ContentLikeValue from '@/components/event/filters/custom/formContent/LikeValue.vue'

const store = useStore()
const formContents = [
  {
    type: CustomFilteringFormContent.STRICT_VALUE,
    label: 'Strictement égal',
    component: markRaw(ContentStrictValue)
  },
  {
    type: CustomFilteringFormContent.EXCEPT_VALUE,
    label: 'Différent de',
    component: markRaw(ContentExcept)
  },
  {
    type: CustomFilteringFormContent.LIKE,
    label: 'Contient',
    component: markRaw(ContentLikeValue)
  },
  {
    type: CustomFilteringFormContent.GREATER_THAN,
    label: 'Borne inférieur',
    component: markRaw(ContentGreaterThan)
  },
  {
    type: CustomFilteringFormContent.SMALLER_THAN,
    label: 'Borne supérieur',
    component: markRaw(ContentSmallerThan)
  },
  {
    type: CustomFilteringFormContent.BETWEEN,
    label: 'Bornes',
    component: markRaw(ContentBetween)
  }
]

const data = defineModel({
  type: Object,
  required: true
})
const typeCondition = defineModel('typeCondition', {
  type: String,
  required: true
})

const emits = defineEmits(['remove'])

const formContent = ref(null)

const userEventProperties = computed(() => store.getters['event/common/userEventProperties'])

//J'utilise un watcher pour déclencher le changement de formulaire à la place de l'événement @change car en mode collaboratif ça ne marche pas. Pour les spectateurs dans le @change j'ai toujours la précédente valeur et non l'actuelle. Je ne sais pas pourquoi, le cas est unique dans eyediag. Peut-être parce que c'est un v-model ?
watch(typeCondition, () => {
  typeConditionChange()
})

onMounted(() => {
  typeConditionChange()
})

function typeConditionChange() {
  updateComponentFormContent()
  data.value = FilterCustom.getTemplateFor(typeCondition.value, data.value)
}

function updateComponentFormContent() {
  const result = formContents.find(f => f.type === typeCondition.value)
  if (result !== undefined) {
    formContent.value = result.component
  }
}

function removeItem(event) {
  store.dispatch('ws/sendEvent', {event: event})
  emits('remove')
  store.dispatch('ws/collaborativeEventTreated')
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>

<style scoped>
.form-container {
  @apply p-5 flex flex-col;
  border-radius: 5px;
  background-color: var(--c-gray-2);
  box-sizing: border-box;
}

label {
  @apply mt-4 flex flex-col;
}

label > * {
  @apply mt-2;
}

.exit {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
}
</style>

<style>
.custom-filtering-form-content label {
  @apply mt-4 flex flex-col;
}

.custom-filtering-form-content > label > * {
  @apply mt-2;
}
</style>