<template>
  <div class="container-report-form">
    <form @submit.prevent="onValidate">
      <div>
        <label>
          Type d'actions
          <select
            v-model="selectedAction"
          >
            <option
              v-for="action in actions"
              :key="action.value"
              :value="action.value"
            >
              {{ action.label }}
            </option>
          </select>
        </label>
      </div>
  
      <div v-if="['update', 'delete'].includes(selectedAction)">
        <label>
          Identifiant lien
          <input
            v-model="identifier"
          >
        </label>
      </div>
  
      <div v-if="['create', 'update'].includes(selectedAction)">
        <label>
          Description
          <textarea
            v-model="description"
          />
        </label>
      </div>
  
      <div class="mt-5">
        <DesignButton
          type="submit"
          text="Valider"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import DesignButton from '@/components/DesignButton.vue'
import EyeFetch from '@/libraries/EyeFetch.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from '@/libraries/utils.js'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const actions = [
  { label: 'Créer', value: 'create' },
  { label: 'Modifier', value: 'update' },
  { label: 'Supprimer', value: 'delete' }
]

const emits = defineEmits(['validate'])

const selectedAction = ref('')
const identifier = ref('')
const description = ref('')

const memorizedEvents = computed(() => store.getters['event/common/memorizedEvents'])
const folderId = computed(() => store.getters['patient/folderId'])
const reports = computed(() => store.getters['event/report/reports'])
const idSelectedReport = computed(() => store.getters['event/report/idSelectedReport'])
const events = computed(() => store.getters['event/common/events'])

const unitPerCircle = computed(() => store.getters['circle/unitPerCircle'])
const periodUnit = computed(() => store.getters['circle/periodUnit'])

watch(idSelectedReport, () => {
  fillValues()
})

onMounted(() => {
  fillValues()
})

const fillValues = () => {
  if (idSelectedReport.value !== null) {
    const report = reports.value.find(r => r.id === idSelectedReport.value)
    if (report !== undefined) {
      identifier.value = report.id
      description.value = report.description
    }
  }
}

const onValidate = async (event) => {  
  if (event.isTrusted) {
    let method = ''
    let endPoint = ''
    const data = await store.dispatch('getRepresentationReqBody', { extraData: {
      idEvents: memorizedEvents.value.map(event => event.id),
      folderId: folderId.value
    }})
  
    if (['update', 'delete'].includes(selectedAction.value)) {
      data.id = identifier.value
    }
    if (['create', 'update'].includes(selectedAction.value)) {
      data.description = description.value
    }
  
    if (['update', 'create'].includes(selectedAction.value)) {
      method = 'POST'
      endPoint = '/patient/report/'
    } else {
      method = 'DELETE'
      endPoint = '/patient/report/'
    }
  
    await EyeFetch(store, `${process.env.VUE_APP_SERVER_BASE_URL}${endPoint}`, {
      method: method,
      credentials: 'include',
      body: JSON.stringify(data)
    })
    store.dispatch('ws/sendEvent', {event: event})
  }

  if (selectedAction.value == 'delete') {
    for (const memorizedEvent of memorizedEvents.value) {
      store.commit(`event/common/${mutationTypes.CHANGE_STATE_MEMORIZED_EVENT}`, memorizedEvent)
    }
    store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, events.value)
  }
  await store.dispatch('deleteUserCache'),
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: unitPerCircle.value,
    periodUnit: periodUnit.value,
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })

  emits('validate')
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}
</script>

<style scoped>
.container-report-form {
  @apply p-5;
  box-sizing: border-box;
  width: 300px;
}

label {
  @apply flex flex-col mt-4;
}

label > * {
  @apply mt-2;
}
</style>