<template>
  <div>
    <ContextMenu
      v-if="menuConfig.show"
      :x="menuConfig.x"
      :y="menuConfig.y"
      :items="menuConfig.items"
      :z-index="5"
      @close="onCloseContextMenu"
    />
    <DraggableList
      @dragresult="onDragResult"
    >
      <div
        v-for="(section, index) in sections"
        :key="index"
        class="mt-1 draggable-item"
        :idx="index"
        draggable="true"
        @contextmenu="(e) => onContextMenu(e, index)"
      >
        <Collapse>
          <template #title>
            <EditableText
              v-model="section.name"
              :editing="indexRename === index"
              @validate="onValidateRename"
            />
          </template>
          <template #content>
            <Collapse>
              <template #title>
                Composition
              </template>
              <template #content>
                <Filtering v-model="section.filtering" />
              </template>
            </Collapse>
            <Collapse>
              <template #title>
                SousSections
              </template>
              <template #content>
                <ListSections v-model="section.children" />
              </template>
            </Collapse>
          </template>
        </Collapse>
      </div>
    </DraggableList>
    <p
      class="underline underline-offset-2 mt-2"
      @click="addSection"
    >
      Ajouter une catégorie
    </p>
    <AddSection
      v-if="isAddingSection"
      @new-sections="onNewSections"
    />
  </div>
</template>

<script>
export default {
  name: 'ListSections'
}
</script>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Collapse from '@/components/Collapse.vue'
import Filtering from '@/components/event/filters/custom/Conditions.vue'
import ContextMenu from '@/components/ContextMenu.vue'
import menuTypes from '@/enums/menu_types.js'
import EditableText from '@/components/utils/EditableText.vue'

import AddSection from '@/components/borderLists/ListConfigSections/addSection/AddSection.vue'
import DraggableList from '@/components/utils/DraggableList.vue'

const store = useStore()
const sections = defineModel({
  type: Array,
  required: true
})

let contextMenuIndex = null

const indexRename = ref(null)
const menuConfig = ref({
  show: false,
  x: 0,
  y: 0,
  items: [
    {
      id: 0,
      label: "Renommer",
      labelId: 'rename',
      type: menuTypes.TEXT,
      selected: false,
      click: () => {
        indexRename.value = contextMenuIndex
      }
    },
    {
      id: 1,
      label: "Supprimer",
      labelId: 'delete',
      type: menuTypes.TEXT,
      selected: false,
      click: () => {
        sections.value.splice(contextMenuIndex, 1)
      }
    },
  ]
})

const isAddingSection = ref(false)

function addSection(event) {
  store.dispatch('ws/sendEvent', { event: event })
  isAddingSection.value = !isAddingSection.value
  store.dispatch('ws/collaborativeEventTreated')
}

function onNewSections(newSections) {
  isAddingSection.value = false
  sections.value.push(...newSections)
}

function onDragResult(toMove, before) {
  if (before > toMove) {
    before -= 1
  }

  const elemToMove = sections.value[toMove]

  sections.value.splice(toMove, 1)
  sections.value.splice(before, 0, elemToMove)
}

function onContextMenu(event, index) {
  event.stopPropagation()
  event.preventDefault()
  store.dispatch('ws/sendEvent', { event: event })
  if (contextMenuIndex === index) {
    onCloseContextMenu()
  } else {
    const itemRect = event.currentTarget.getBoundingClientRect()
    contextMenuIndex = index
    menuConfig.value.show = true
    menuConfig.value.x = itemRect.x - 20
    menuConfig.value.y = itemRect.y
  }
  store.dispatch('ws/collaborativeEventTreated')
}

function onCloseContextMenu() {
  contextMenuIndex = null
  menuConfig.value.show = false
}

function onValidateRename() {
  indexRename.value = null
}

</script>