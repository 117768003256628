import { set_shape, mouseover_y } from '@/components/tooltip/PlotsUtils';
import * as d3 from "d3";

export function draw_svg_parSection(svg, width, height, datasets) {
  // Convert x values to Date objects for each dataset
  datasets.forEach(dataset => {
    dataset.data.forEach(d => {
      d.xVal = new Date(d.xVal);
    });
  });
  const legendHeight = 40;  // Space reserved for the legend

  // Flatten the dataset array to get all the x and y values for the domain
  const allData = datasets.flatMap(dataset => dataset.data);
  
  // Define x and y scales based on all datasets
  const x = d3.scaleTime()
    .domain([d3.min(allData, d => d.xVal), d3.max(allData, d => d.xVal)])
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain([0, d3.max(allData, d => d.yVal)])
    .nice()
    .range([height, legendHeight]);

  // Define axes
  let xAxis = d3.axisBottom(x).tickFormat(d3.format("d"));
  const yAxis = d3.axisLeft(y).ticks(y.domain()[1]).tickFormat(d3.format("d"));

  // Append X axis
  svg.append("g")
    .attr("transform", `translate(0,${height})`)
    .call(xAxis)
    .style("color", "var(--color-text)")
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "-0.8em")
    .attr("dy", "0.15em")
    .attr("transform", "rotate(-30)")
    .style("color", "var(--color-text)");

  // Append Y axis
  svg.append("g")
    .call(yAxis)
    .style("color", "var(--color-text)");

  // Define a color scale for the different datasets
  const color = d3.scaleOrdinal(d3.schemeCategory10)
    .domain(datasets.map(dataset => dataset.name));

  // Define the line generator
  const line = d3.line()
    .x(d => x(d.xVal))
    .y(d => y(d.yVal));

  // Draw the lines and points for each dataset
  datasets.forEach(dataset => {
    // Add line
    svg.append("path")
      .datum(dataset.data)
      .attr("class", "line")
      .attr("d", line)
      .attr("fill", "none")
      .attr("stroke", color(dataset.name))
      .attr("stroke-width", 2);
    const sanitizedName = dataset.name//.replace(/[^a-zA-Z0-9]/g, "");  // Sanitize the name

    // Add points
    svg.selectAll(`circle-${sanitizedName}`)
      .data(dataset.data)
      .enter()
      .append("circle")
      .attr("cx", d => x(d.xVal))
      .attr("cy", d => y(d.yVal))
      .attr("r", 4)
      .attr("fill", color(dataset.name));
  });
  const legend = svg.append("g")
    .attr("transform", `translate(0, 0)`); 
  
  datasets.forEach((dataset, i) => {
    const legendItem = legend.append("g")
      .attr("transform", `translate(${i * 150}, 0)`);  
    
    legendItem.append("rect")
      .attr("x", 10)
      .attr("y", 10)
      .attr("width", 20)
      .attr("height", 20)
      .attr("fill", color(dataset.name));  
    
    legendItem.append("text")
      .attr("x", 40)
      .attr("y", 25)
      .text(dataset.name)
      .attr("fill", "var(--color-text)")
  });  
}

export function countParTemps_parCat(data, thisChart, periodUnit) {
  const { width, height, svg } = set_shape(250, thisChart);
  data.forEach(d => {
    d.year = new Date(d.onsetDateTime).getFullYear();
  });
  const sectionYearCounts = d3.rollups(
    data,
    v => d3.rollups(
      v,
      yearVals => yearVals.length,  // Count the occurrences by year
      d => d.year  // Group by year
    ).map(d => ({ year: d[0], count: d[1] })),  // Map to year-count pairs
    d => d.Section  // Group by Section
  );
  
  // Create datasets for each unique section
  const datasets = sectionYearCounts.map(sectionGroup => ({
    name: sectionGroup[0],  // The section name
    data: sectionGroup[1].sort((a, b) => a.year - b.year).map(d => ({
      xVal: d.year,  // xVal is the year
      yVal: d.count  // yVal is the count for that year in the section
    }))
  }));
  return draw_svg_parSection(svg, width, height, datasets)
}

function draw_pieChart(svg, radius, SectionCounts) {
  const color = d3.scaleOrdinal()
    .domain(SectionCounts.map(d => d.section))
    .range(d3.schemeSet2);

  const pie = d3.pie()
    .value(d => d.count);
  const data_ready = pie(SectionCounts); 
  
  const arcGenerator = d3.arc()
    .innerRadius(0)
    .outerRadius(radius);

  svg.selectAll('slice')
    .data(data_ready)
    .enter()
    .append('path')
    .attr('d', arcGenerator)
    .attr('fill', d => color(d.data.section))
    .attr("stroke", "var(--color-text)")
    .style("stroke-width", "2px")
    .style("opacity", 0.7);
  
  svg.selectAll('slice')
    .data(data_ready)
    .enter()
    .append('text')
    .text(d => `${d.data.section}:\n${d.data.count}`)
    .attr("transform", d => `translate(${arcGenerator.centroid(d)})`)
    .attr("stroke", "var(--color-text)")
    .style("text-anchor", "middle")
    .style("font-size", 12);
  return svg
}

export function pieChart_Section(data, thisChart) {
  const width = thisChart.clientWidth
  const height = 200
  const svg = d3.select(thisChart)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", `translate(${width / 2},${height / 2})`);

  const SectionCounts = d3.rollups(
    data,
    v => v.length,
    d => d.Section)
    .map(d => ({ section: d[0], count: d[1] }));
  
  const margin = 10;
  const radius = Math.min(width, height) / 2 - margin;

  return draw_pieChart(svg, radius, SectionCounts)
}

export function formatGrouped(data, colA = "groupeA", colB = "idSection") {  
  let formattedData
  try {
    formattedData = data[colA].map((A, index) => ({
      idSection: data[colB][index],
      groupA: A,
      count: data.count[index]
    }));
  
  } catch (error) {
    const groupAs = Object.values(data[colA]);
    const idSections = Object.values(data[colB]);
    const counts = Object.values(data.count);
    formattedData = groupAs.map((A, index) => ({
      idSection: idSections[index],
      groupA: A,
      count: counts[index]
    }));  
  }
  return formattedData
}

function groupedBarChart(svg, formattedData, width, height, colA = "groupeA", colB = "idSection"){
  const legendHeight = 40; 

  formattedData.sort((a, b) => {
    if (a.groupA < b.groupA) return -1;
    if (a.groupA > b.groupA) return 1;
    return 0;
  });

  const x = d3.scaleBand()
    .domain(formattedData.map(d => d.groupA))
    .range([0, width])
    .padding(0.1);
    
  const y = d3.scaleLinear()
    .domain([0, d3.max(formattedData, d => d.count)])
    .nice()
    .range([height, legendHeight]);
  
  const color = d3.scaleOrdinal()
    .domain([...new Set(formattedData.map(d => d.idSection))])
    .range(d3.schemeCategory10);

  const groupedData = d3.group(formattedData, d => d.groupA);
  const uniqueValues = [...new Set(formattedData.map(item => item.idSection))];
  const barWidth = x.bandwidth() / uniqueValues.length;
  
  svg.selectAll(".bar")
    .data(formattedData)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("x", d => x(d.groupA) + barWidth * groupedData.get(d.groupA).indexOf(d))
    .attr("y", d => y(d.count))
    .attr("width", barWidth)
    .attr("height", d => height - y(d.count))
    .attr("fill", d => color(d.idSection))
    .attr("opacity", 0.7);

  svg.append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));

  svg.append("g")
    .attr("class", "y-axis")
    .call(d3.axisLeft(y));

  const legend = svg.append("g")
    .attr("transform", `translate(${width - 100}, 0)`);

  legend.selectAll("rect")
    .data(color.domain())
    .enter()
    .append("rect")
    .attr("x", 0)
    .attr("y", (d, i) => i * 20)
    .attr("width", 15)
    .attr("height", 15)
    .attr("fill", color);

  legend.selectAll("text")
    .data(color.domain())
    .enter()
    .append("text")
    .attr("x", 20)
    .attr("y", (d, i) => i * 20 + 12)
    .style("fill", "var(--color-text)")
    .text(d => d);
  return svg
}

function drawAgeChart(svg, data, width, height) {
  const legendHeight = 40; 
  const x = d3.scaleBand()
    .domain(data.map(d => d.groupe_label))
    .range([0, width])
    .padding(0.1);

  const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.event_occurred + d.no_event)])
    .nice()
    .range([height, legendHeight]);

  const color = d3.scaleOrdinal()
    .domain(["event_occurred", "no_event"])
    .range(["#4caf50", "#ff5722"]);

  svg.append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x));

  svg.append("g")
    .attr("class", "y-axis")
    .call(d3.axisLeft(y));

  // Bars
  svg.selectAll(".bar-group")
    .data(data)
    .join("g")
    .attr("class", "bar-group")
    .attr("transform", d => `translate(${x(d.groupe_label)},0)`)
    .selectAll("rect")
    .data(d => [
      { key: "event_occurred", value: d.event_occurred },
      { key: "no_event", value: d.no_event }
    ])
    .join("rect")
    .attr("class", "bar")
    .attr("x", d => color.domain().indexOf(d.key) * x.bandwidth() / 2)
    .attr("y", d => y(d.value))
    .attr("width", x.bandwidth() / 2)
    .attr("height", d => height - y(d.value))
    .attr("fill", d => color(d.key));

  // Legend
  const legend = svg.append("g")
    .attr("class", "legend")
    .attr("transform", `translate(${width - 100},20)`);

  legend.selectAll("rect")
    .data(color.domain())
    .join("rect")
    .attr("x", 0)
    .attr("y", (d, i) => i * 20)
    .attr("width", 18)
    .attr("height", 18)
    .attr("fill", d => color(d));

  legend.selectAll("text")
    .data(color.domain())
    .join("text")
    .attr("x", 24)
    .attr("y", (d, i) => i * 20 + 9)
    .attr("dy", "0.35em")
    .text(d => d)
    .style("fill", "var(--color-text)")
    .style("font-size", "12px");
  
  return svg
}

export function ageRangePlot(data, thisChart, setting) {
  if (typeof data == "string") {
    data = JSON.parse(data)    
  }
  
  if (typeof data.distribution == "string") {
    data.distribution = JSON.parse(data.distribution)    
  }

  try {
    const { width, height, svg } = set_shape(250, thisChart);
    if (setting["type"] == "AgeJourExam") {
      if (setting['test'] != 'bySection') {
        return drawAgeChart(svg, data, width, height, "dimension")
      }
      else {
        data = formatGrouped(data)
        return groupedBarChart(svg, data, width, height)
      }
    }
    else {
      data = formatGrouped(data)
      return groupedBarChart(svg, data, width, height)
    }
  } catch (error) {
    console.error(error);
    return null
  }

}