<template>
  <ListBase
    id="time-filter-menu"
    ref="list-base"
    title="Filtrage par temps"
  >
    <template #content>
      <div
        v-for="(period, index) in periods"
        :key="index"
        class="time-filter-container"
        @click="(e) => onClickPeriod(e, period)"
      >
        <p
          :class="(period.selected) ? 'current-event': 'other-event'"
        >
          {{ period.period }}
        </p>
        <ul>
          <li
            v-for="(event, indexItem) in period.events"
            :key="indexItem"
            :class="(period.selected) ? 'current-event': 'other-event'"
          >
            {{ event.defaultLabel }}
          </li>
        </ul>
      </div>
    </template>
  </ListBase>
</template>

<script>

import * as d3 from 'd3'
import { mapGetters, mapActions } from 'vuex'
import * as mutationTypes from "@/store/mutations-types.js"
import EventSelectionTypes from "@/enums/event_selection_types.js"
import timeFilter from '@/enums/time_filter.js'
import ListBase from '@/components/borderLists/ListBase.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'

export default {
  name: 'ListTimeFilter',
  components: {
    ListBase
  },
  data: () => ({
    /**
     * Il s'agit de la liste des périodes de temps qui seront utilisé par le filtrage
     * @type {Date[]}
     */
    periods: []
  }),
  computed: {
    ...mapGetters({
      events: 'event/common/events',
      eventFilters: 'event/filtering/eventFilters',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      isDisplayedList: 'borderList/isDisplayedList',
    })
  },
  watch: {
    eventFilters() {
      if (this.eventFilters.time && this.currentDisplayedBorderList === borderListTypes.LIST_TIME_FILTER) {
        this.generatePeriod()
      }
    }
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      sendProperties: "ws/sendProperties",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Permet de gérer l'ouverture et la fermeture du conteneur affichant la liste du filtrage par temps
     * @method
     * @public
     * @param {Boolean} isDisplayed Détermine si la liste doit être affiché
     */
    movePanel(isDisplayed) {
      if (isDisplayed) {
        this.generatePeriod()
      } else {
        this.unSelectEventOldPeriod()
        this.$store.dispatch('event/common/generateEvents')
      }
      this.$refs['list-base'].movePanel(isDisplayed)
    },
    /**
     * Permet de générer les périodes de temps et les événements devant être présent dans ces périodes pour le filtrage
     * @method
     * @public
     */
    generatePeriod() {
      this.unSelectEventOldPeriod()
      this.periods = []
      
      if (this.events.length > 0) {
        let events = [...this.events]
        events = events.sort((a,b) => new Date(a.onsetDateTime) - new Date(b.onsetDateTime))
  
        const startDate = new Date(events[0].onsetDateTime)
        startDate.setMonth(startDate.getMonth() - 1)
        const endDate = new Date(events[events.length - 1].onsetDateTime)
        endDate.setMonth(endDate.getMonth() + 1)
        let periods = []
  
        if (this.eventFilters.time.unit === timeFilter.WEEK) {
          periods = d3.timeMonday.range(startDate, endDate)
        } else {
          periods = d3.timeMonth.range(startDate, endDate)
        }
        const timeFormat = d3.timeFormat("%d/%m/%Y")
  
        for (let i = 0; i < periods.length - 1; i++) {
          const startPeriod = periods[i]
          const endPeriod = periods[i + 1]
          const period = {
            selected: false,
            period: `${timeFormat(startPeriod)} - ${timeFormat(endPeriod)}`,
            events: events.filter(event => {
              const eventDate = new Date(event.onsetDateTime)
              return eventDate >= startPeriod && eventDate < endPeriod
            })
          }
  
          if (period.events.length > 0) {
            this.periods.push(period)
          }
        }
        this.periods.reverse()
        this.periods[0].selected = true
        this.onClickPeriod(null, this.periods[0])
      }
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur effectue un clique sur une période de temps
     * @method
     * @public
     * @param {event} e Il s'agit de l'événement fournit au listener
     * @param {EyePeriod} period Il s'agit de la période de temps sur laquelle l'utilisateur à cliqué
     */
    onClickPeriod(e, period) {
      if (e !== null) {
        this.sendEvent({ event: e })
      }

      this.unSelectEventOldPeriod()
      this.periods.forEach(period => period.selected = false)
      period.selected = true

      period.events.forEach(event => this.$store.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, {
        event: event,
        selectionType: EventSelectionTypes.FILTERING
      }))
      this.$store.dispatch('event/common/generateEvents')

      if (e !== null) {
        this.collaborativeEventTreated()
      }
    },
    /**
     * Cette fonction permet de déselectionner les événements qui étaient mis en évidence lorsque l'utilsateur avait cliqué sur une période de temps
     * @method
     * @public
     */
    unSelectEventOldPeriod() {
      const oldPeriod = this.periods.find(period => period.selected)
      if (oldPeriod) {
        oldPeriod.events.forEach(event => this.$store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
          event,
          selectionType: EventSelectionTypes.FILTERING
        }))
      }
    }
  }
}

</script>

<style>
.current-event {
  color: var(--color-text);
  opacity: 1;
  padding-inline: 5px;
  font-size: 1.2rem;
}

.other-event {
  color: var(--color-text);
  opacity: 0.3;
  padding-inline: 5px;
  font-size: 1.2rem;
}

.time-filter-container {
  cursor: pointer;
}
</style>