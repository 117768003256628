<template>
  <button
    data-test="tooltip-exitButton"
    class="tooltip-exit-button"
    :style="buttonStyle"
    @click="onClick"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    &#10005;
  </button>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'
import utils from '@/libraries/utils.js'

const store = useStore()

const props = defineProps({
  tooltipData: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['exit'])

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])
const buttonStyle = ref({
  borderColor: 'var(--c-gray-1)',
  color: 'var(--color-bg-1)'
})

watch(hoveredEvent, () => {
  const isEventTooltip = hoveredEvent.value?.code === props.tooltipData.code

  if (isEventTooltip) {
    crossChangeState({isHoverCross: false, focusOnEvent: true})
  } else {
    crossChangeState({isHoverCross: false, focusOnEvent: false})
  }
})

function onClick(event) {
  emits('exit', event)
}

function onMouseOver(event) {
  store.dispatch('ws/sendEvent', { event: event })
  crossChangeState({isHoverCross: true, focusOnEvent: true})
  store.dispatch('ws/collaborativeEventTreated')
}

function onMouseLeave(event) {
  store.dispatch('ws/sendEvent', { event: event })
  crossChangeState({isHoverCross: false, focusOnEvent: true})
  store.dispatch('ws/collaborativeEventTreated')
}

/**
  * Cette fonction permet de modifier l'état du bouton permettant de fermer la tooltip. La fonction gère à la fois l'apparition / disparition de la croix lorsque l'utilisateur positionne son pointeur sur le bouton mais également la modification de couleur du contour du bouton pour indiquer que cette tooltip est associée à l'événement que l'utilisateur est en train de survoler
  * @param {Boolean} isHoverCross Indique si le bouton est actuellement survolé par le pointeur de l'utilisateur
  * @param {Boolean} focusOnEvent Indique si le pointeur de l'utilisateur survole soit la tooltip ou l'event
  */
function crossChangeState({isHoverCross, focusOnEvent}) {
  const resourceTypeClass = `.${utils.getEventResourceTypeColor(props.tooltipData, store)}`
  const resourceTypeColor = utils.getCssPropertyOf(resourceTypeClass, 'stroke')

  buttonStyle.value.borderColor = focusOnEvent ? resourceTypeColor : "var(--c-gray-1)"
  buttonStyle.value.color = isHoverCross ? "var(--c-gray-1)" : "var(--color-bg-1)"
}

</script>

<style scoped>
.tooltip-exit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  background: none;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  user-select: none;
  transition: 
    border-color 500ms ease,
    color 300ms ease;
}
</style>