class EventsUtils {
  static getValueFromRootOrExt(event, key) {
    if (event[key] !== undefined) {
      return event[key]
    }
    if (event.extension[key]) {
      return event.extension[key]
    }
    return undefined
  }
}

export default EventsUtils