<template>
  <div>
    <div
      v-if="index !== 0"
      class="link-box"
      :class="{
        'link-box': typeLink !== 'none',
        'no-link': typeLink === 'none',
        'solid-link': typeLink === 'solid',
        'dash-link': typeLink === 'dash'
      }"
      :style="linkStyle"
    />
    <div class="flex flex-row items-center">
      <div class="point-container">
        <span
          class="point"
          :style="circleStyle"
        >
          <span
            :style="circleInsideStyle"
          />
        </span>
      </div>
      <div class="ml-2">
        <slot name="right-content" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  typeLink: {
    type: String,
    required: false,
    default: 'solid'
  },
  color: {
    type: [String, null],
    required: false,
    default: null
  },
  selected: {
    type: Boolean,
    required: false,
    default: false
  }
})

const linkStyle = computed(() => props.color !== null
  ? { borderLeftColor: props.color }
  : {}
)

const circleStyle = computed(() => props.color !== null
  ? { borderColor: props.color }
  : {}
)

const circleInsideStyle = computed(() => ({
  backgroundColor: props.color || 'var(--color-primary-accent-opaque)',
  opacity: props.selected === true ? 0.3 : 0
}))

</script>

<style scoped>
.link-box {
  margin-left: 8px;
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
  height: 15px;
  border-left-color: var(--color-primary-accent-opaque);
  border-left-width: 1px;
}

.no-link {
  height: 10px;
}

.dash-link {
  border-left-style: dashed;
}

.solid-link {
  border-left-style: solid;
}

.point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary-accent-opaque);
  display: block;
}

.point > span {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  transition: opacity 300ms ease;
}

.point-container {
  flex-basis: 22px;
  flex-shrink: 0;
}
</style>