import * as mutationTypes from '../../mutations-types.js'
import EventManager from '@/libraries/EventManager.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const getDefaultState = () => {
  return {
    /**
     * Données pour la génération du cercle des scores
     * @type {EyeCircle}
     */
    scoreCircle: null,
    /**
     * Objet définissant les tests et les événements du dossier patient qui peuvent valider ces tests
     * @type {EyeScoreChecker}
     */
    scoreChecker: null,
    /**
     * Identifiant du score affiché par l'utilisateur
     * @type {null|Number}
     */
    idScore: null,
    /**
     * Coefficient par lequel est multiplié le rayon de référence pour déterminer le rayon du cercle des score
     * @type {Number}
     */
    coefRadiusScore: 1.024,
    /**
     * Détermine si la tooltip des scores est affiché
     * @type {Boolean}
     */
    isDisplayedScoreTooltip: false,
    /**
     * Il s'agit du résultat du filtrage des événements matchant avec le filtrage de score actuellement survolé par l'utilisateur
     * @type {EyeEventFiltering}
     */
    hoveredEvent: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    scoreCircle: state => state.scoreCircle,
    scoreChecker: state => state.scoreChecker,
    isDisplayedScoreTooltip: state => state.isDisplayedScoreTooltip,
    idScore: state => state.idScore,
    hoveredEvent: state => state.hoveredEvent
  },
  mutations: {
    /**
     * Definit le coefficient de rayon appliqué au cercle de score
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_COEF_RADIUS_SCORE](state, payload) {
      state.coefRadiusScore = payload
    },
    /**
     * Met à jour les données pour la génération du cercle des scores
     * @param {EyeCircle} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_SCORE_CIRCLE](state, payload) {
      state.scoreCircle = payload
    },
    /**
     * Met à jour l'objet définissant les tests du score et les événements validant ces tests
     * @param {EyeScoreChecker} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_SCORE_CHECKER](state, payload) {
      state.scoreChecker = payload;
    },
    /**
     * Met à jour l'identifiant du score affiché par l'utilisateur
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_ID_SCORE](state, payload) {
      if (state.idScore !== payload) {
        state.scoreCircle = null
      }
      state.idScore = payload
    },
    /**
     * Définit que la tooltip des scores doit être affichée
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.DISPLAY_SCORE_TOOLTIP](state) {
      state.isDisplayedScoreTooltip = true
    },
    /**
     * Définit que la tooltip des scores ne doit pas être affichée
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.HIDE_SCORE_TOOLTIP](state) {
      state.isDisplayedScoreTooltip = false
    },
    /**
     * Permet de modifier la prise en compte ou non d'un test dans le calcul du score
     * @param {Boolean} key
     * @method
     * @public
     */
    [mutationTypes.CHANGE_DISPLAY_VALUE](state, key) {
      if (key + "" in state.scoreChecker) {
        if (!(state.scoreChecker[key] === true))
          state.scoreChecker[key]["display"] = !state.scoreChecker[key]["display"];
      }
    },
    /**
     * Permet de mettre à jour le filtrage des événements matchant avec les événements ou tests survolés depuis la tooltip des scores par l'utilisateur
     * @param {EyeEventFiltering} payload Il s'agit des événements matchant avec le filtrage
     * @method
     * @public
     */
    [mutationTypes.UPDATE_EVENT_HOVERED](state, payload) {
      state.hoveredEvent = payload
    },
    [mutationTypes.DISABLE_SCORE]() {
      this.commit(`refCircle/score/${mutationTypes.SET_ID_SCORE}`, null)
      this.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`)
      this.commit(`refCircle/score/${mutationTypes.UPDATE_SCORE_CIRCLE}`, null)
    }
  },
  actions: {
    /**
     * Permet de remettre le state local du module à sa valeur par défaut
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    /**
     * Cette fonction est appelé lors du chargement du cercle des scores. Elle permet de faire un appel au back-end pour obtenir les données nécessaires à l'affichage du cercle
     * @method
     * @public
     */
    async getScoreCircle(context, params) {
      if (context.state.idScore === null) {
        context.commit(mutationTypes.HIDE_SCORE_TOOLTIP)
        context.commit(mutationTypes.UPDATE_SCORE_CIRCLE, null)
        return
      }

      try {
        const body = await context.dispatch('getRepresentationReqBody', {
          mode: {
            type: eyediagMode.mode.PATIENT,
            options: (context.rootState.mode.type === eyediagMode.mode.PATIENT)
              ? context.rootState.mode.options
              : {}
          }
        }, {root: true})

        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/score/`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(body),
        })

        if (!response.ok) {
          ErrorManager.requestError(response, { origin: 'refCircle/score/getScoreCircle', params: params })
          return
        }

        response = await response.json();
        const scoreChecker = response.scoreChecker;

        for (const property in scoreChecker) {
          scoreChecker[property].events.forEach((escore, index) => {
            let tmp = null
            tmp = EventManager.allEvents.find(e => e.id === escore.id)
            if (tmp)
              scoreChecker[property].events[index] = tmp
          })
        }

        if (context.state.scoreCircle === null) {
          context.commit(mutationTypes.DISPLAY_SCORE_TOOLTIP)
        }

        context.commit(mutationTypes.UPDATE_SCORE_CIRCLE, response.scoreCircle)
        context.commit(mutationTypes.UPDATE_SCORE_CHECKER, scoreChecker)
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    },
    /**
     * async
     * function
     * @param {*} idScore 
     */
    async baseEnableScore(context, idScore) {
      context.commit(`refCircle/associatedEvents/${mutationTypes.DISABLE_ASSOCIATED_EVENT_CIRCLE}`, null, {root: true})
      context.commit(`refCircle/score/${mutationTypes.SET_ID_SCORE}`, idScore, {root: true})
      await context.dispatch('refCircle/score/getScoreCircle', {
        sections: context.rootState.sections.map(section => section.id)
      }, {root: true})
      if (context.rootState.circle.filters.matchOnly) {
        await context.dispatch('circle/getDataRepresentation', {
          unitPerCircle: context.rootState.circle.unitPerCircle,
          periodUnit: context.rootState.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        }, {root: true})
      }
    },
    disableScore(context) {
      context.commit(`refCircle/score/${mutationTypes.DISABLE_SCORE}`, null, {root: true})
    }
  }
}