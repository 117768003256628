<template>
  <div>
    <label>
      Nom catégorie
      <input
        v-model="options.sections[0].name"
        @input="(e) => onInput(e, options.sections[0].name, false)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'ConfigSectionFiltering'
}
</script>

<script setup>
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import FilterCustom from '@/shared/libraries/filterCustom.js'

const store = useStore()

const options = defineModel({
  type: Object,
  required: true
})

options.value.sections = [{
  name: '',
  filtering: FilterCustom.getDefaultRootTemplate(),
  children: []
}]

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>

<style scoped>
label {
  @apply flex flex-col;
}

label > * {
  @apply mt-2;
}

</style>