<template>
  <div>
    <FilteredEventContent
      :filtering="filtering"
    />
  </div>
</template>

<script>
export default {
  name: 'LegendParcours'
}
</script>

<script setup>
import FilteredEventContent from '@/components/borderLists/ListLegend/content/filteredEventContent.vue'
import Filters from '@/shared/libraries/filters.js'
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'

const store = useStore()

onMounted(() => {
  emits('title', 'Parcours')
})

const filtering = computed(() => {
  let filteredEvents = []
  Filters.filterCustom(
    store.getters['event/common/allEvents'],
    store.getters['event/filtering/eventFilters'],
    filteredEvents
  )

  return _.groupBy(filteredEvents, e => e.code)
})

const emits = defineEmits(['title'])
</script>