<template>
  <div>
    <div class="flex flex-col border-0 border-dashed">
      <label
        class="mb-2"
      >
        <h3>
          Options
        </h3>
      </label>
    </div>
    <div class="mt-2">
      <div class="flex flex-row">
        <textarea
          v-model="title"
          class="grow"
          name="title"
          rows="1"
          placeholder="Titre"
          @input="(e) => onInput(e, title, false)"
        />
      </div>
      <div class="flex flex-row">
        <textarea
          v-model="xlabel"
          class="grow"
          name="xlabel"
          rows="1"
          placeholder="xlabel"
          @input="(e) => onInput(e, xlabel, false)"
        />
      </div>
      <div class="flex flex-row">
        <textarea
          v-model="ylabel"
          class="grow"
          name="ylabel"
          rows="1"
          placeholder="ylabel"
          @input="(e) => onInput(e, ylabel, false)"
        />
      </div>
    </div>
    <div 
      class="input-row"
    >
      <div
        v-if="nOptions > 0"
        class="radio-group"
      >
        type:
        <label 
          v-for="type in nTypes" 
          :key="type.value" 
          class="radio-label"
        >
          <input
            v-model="selectedFormat"
            type="radio"
            :value="type.value"
            name="radio-nTypes"
            @change="(e) => onInput(e, selectedFormat, true)"
          >
          {{ type.label }}
        </label>
      </div>
      <div
        v-if="typeEntityContext == userRole.CH"
        class="radio-group"
      >
        type:
        <label 
          class="radio-label"
        >
          <input
            v-model="drop_init"
            type="radio"
            :value="false"
            name="radio-drop"
            @change="(e) => onInput(e, drop_init, true)"
          >
          tout les patients
        </label>
        <label 
          class="radio-label"
        >
          <input
            v-model="drop_init"
            type="radio"
            :value="true"
            name="radio-drop"
            @change="(e) => onInput(e, drop_init, true)"
          >
          avec evenement seulement (si Ish(+)Revasc(+))
        </label>
      </div>
    </div>
    <label>p-value:</label>
    <input 
      v-model="pvalue" 
      type="checkbox"
      @click="onClickCheckbox"
    >
    <div 
      v-if="['HistoTrancheAge', 'BoxPlot'].includes(graphType)"
      class="input-row"
    >
      <label for="n">Nombre de tranches:</label>
      <input 
        v-model="n" 
        type="number" 
        min="1" 
        placeholder="Enter positive integer" 
        class="small-input"
        @input="(e) => onInput(e, n, false)"
      >
      
      <label for="tranche">Taille:</label>
      <input 
        v-model="tranche"
        type="number" 
        min="1" 
        placeholder="Enter tranche value" 
        class="small-input"
        @input="(e) => onInput(e, tranche, false)"
      >
    </div>

    <div 
      v-if="typeEntityContext == userRole.CH"
      class="input-row"
    >
      <label>Retirer les actes de revascularisation des n jours à partir du premier acte des Ish(+)Revasc(+)</label>
      <textarea
        v-model="t_filtre"
        class="grow"
        name="t_filtre"
        rows="1"
        placeholder="Retirer les actes de revascularisation des n jours à partir du premier acte"
        @input="(e) => onInput(e, t_filtre, false)"
      />
    </div>
  </div>
  <DesignButton 
    text="Rafraichir"
    @click="raffraichi"
  />
</template>

<script>
import * as d3 from 'd3'
import { mapActions, mapGetters } from 'vuex'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import DesignButton from '@/components/DesignButton.vue'
import userRole from '@/shared/enums/userRole';

import CollaborativeUtils from '@/libraries/collaborative/utils.js'

export default {
  name: 'AddEventOptions',
  components: {
    // ToggleSwitch
    DesignButton,
  },
  props:
  {
    graphType:{
      type: String,
      required: true, 
      default: "KM",
    },
    nOptions: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  emits:
    ["raffraichir"],
  data: () => ({
    note: "",
    errors: {
      period: [],
      interval: []
    },
    pvalue: true,
    title: "",
    selectedFormat: 0,
    t_filtre: '-1',
    xlabel: '',
    ylabel:'',
    userRole: userRole,
    n: 4,
    tranche: 10,
    drop_init: false,
  }),
  computed: {
    ...mapGetters({
      allEvents: 'event/common/allEvents',
      editEvent: 'event/edit/editingEventToAdd',
      typeEntityContext: 'user/typeEntityContext',

    }),
    nTypes() {
      return Array.from({ length: this.nOptions }, (_, index) => ({
        label: `${index + 1}`,
        value: index,
      }));
    },

  },
  watch: {
    // nOptions(oldVal, newVal) {
    //   console.log("this.nTypes", newVal);
      
    //   this.nTypes = Array.from({ length: newVal }, (_, index) => ({
    //     label: `${index + 1}`,
    //     value: index,
    //   }));
    // }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      sendEvent: 'ws/sendEvent',
    }),
    checkForm() {
      this.errors = {
        period: [],
        interval: []
      }
    },
    resetForm() {
      this.note = "",
      this.title = "",
      this.xlabel = "",
      this.ylabel = "",
      this.pvalue = true,
      this.t_filtre = -1
    },
    editThisEvent(event) {
      this.note = event.note
    },
    getOptions() {
      this.checkForm()
      for (const errors of Object.values(this.errors)) {
        if (errors.length > 0) {
          return null
        }
      }
      return {
        note: this.note,
      }
    },
    raffraichi(event) {
      this.sendEvent({ event: event })
      let obj_res = {
        drop_init: this.drop_init,
        Format: this.selectedFormat,
        pvalue: this.pvalue,
        Type: this.graphType,
        t_filtre: this.t_filtre,
        Title: this.title,
        xlabel: this.xlabel,
        ylabel:this.ylabel
      }
      if (['HistoTrancheAge', 'BoxPlot'].includes(this.graphType)) {
        obj_res['n'] = this.n
        obj_res['age_range'] = this.tranche
      }
      this.$emit(
        'raffraichir',
        obj_res
      )
      this.collaborativeEventTreated()
    },
    onInput(...params) {
      CollaborativeUtils.onInput(...[this.$store, ...params])
    },
    onClickCheckbox(event) {
      this.sendEvent({ event: event })
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
label {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
.input-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

</style>