<template>
  <div>
    <div class="tooltip-event-header">
      <p class="tooltip_title">
        {{ 'Classement' }}
      </p>
    </div>
    <div
      ref="tooltip-event-note-container"
      class="tooltip-event-note-container"
    >
      <a
        href="#"
        class="show-associated"
        @click="(e) => showAssociatedEventsCircle(e, tooltipData.ranks.length > 0 ? tooltipData.ranks[0] : {})"
      >
        Stats Associées
      </a>
      <div
        v-for="(ranking, index) in tooltipData.ranks"
        :key="index"
        class="tooltip-note"
      >
        <Collapse>
          <template #title>
            <p>
              {{ `${ranking.size} - ${ranking.code} - ${ranking.label}` }}
            </p>
          </template>
          <template #content>
            <Collapse
              v-for="(linkEvent, idx) in linkEvents"
              :key="idx"
            >
              <template #title>
                <p>{{ listLinks[ranking.code][linkEvent.linkId].length }} - {{ linkEvent.collapseName }}</p>
              </template>
              <template #content>
                <div
                  v-for="(linkData, i) in listLinks[ranking.code][linkEvent.linkId]"
                  :key="i"
                >
                  <a
                    href="#"
                    class="show-associated"
                    @click="(e) => focusLinkData(e, linkEvent, linkData)"
                    @mouseover="(e) => onOverLinkData(e, linkEvent, linkData)"
                    @mouseleave="(e) => onLeaveLinkData(e, linkEvent, linkData)"
                  >
                    {{ linkEvent.label(linkData.data) }}
                  </a>
                  <br>
                </div>
              </template>
            </Collapse>
          </template>
        </Collapse>
      </div>
      <Collapse class="tooltip-note">
        <template #title>
          <p>Tableau</p>
        </template>
        <template #content>
          <TableEvents :tooltip-data="tooltipData" />
        </template>
      </Collapse>
      <Collapse class="tooltip-note">
        <template #title>
          <p>Tableau Global</p>
        </template>
        <template #content>
          <TableEvents />
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script>
import utils from '@/libraries/utils.js'
import * as d3 from 'd3'
import Collapse from '../Collapse.vue'
import { mapGetters, mapActions } from 'vuex'
import * as mutationTypes from "@/store/mutations-types.js"
import _ from 'lodash'
import linkEventsConfig from '@/shared/config/linkEvents.js'
import TableEvents from "./Table.vue"

export default {
  name: 'TooltipContentNbEventPerSection',
  components: {
    Collapse,
    TableEvents
  },
  props: {
    /**
     * Contient les informations de l'événement dont le détail doit être affiché en tooltip
     * @type {EyeEvent}
     */
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    linkEvents: [],
    listLinks: {}
  }),
  computed: {
    ...mapGetters({
      events: "event/common/events",
      eyediagMode: "eyediagMode",
      typeEntityContext: "user/typeEntityContext"
    })
  },
  mounted() {
    this.getLinkEvents()
    this.getlistLinks()
    this.initScrollEmitter()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    getLinkEvents() {
      const configObj = Object.values(linkEventsConfig)

      this.linkEvents = configObj.filter(config => config.canBeDisplayed(this.eyediagMode, this.typeEntityContext) === true)
    },
    async showAssociatedEventsCircle(event, eyediagEvent) {
      this.sendEvent({ event: event })
      event.preventDefault()
      this.$store.commit(`refCircle/score/${mutationTypes.DISABLE_SCORE}`, null)
      const promise = this.$store.dispatch('refCircle/associatedEvents/getAssociatedEvents', eyediagEvent, {root: true})
      this.$store.commit(`refCircle/associatedEvents/${mutationTypes.UPDATE_SRC_EVENT_ASSOCIATED}`, eyediagEvent)

      await promise
      this.collaborativeEventTreated()
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur de la tooltip puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['tooltip-event-note-container'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    },
    getlistLinks() {
      this.tooltipData.ranks.forEach(rank => {
        this.listLinks[rank.code] = {}
        for (const linkEvent of this.linkEvents) {
          this.listLinks[rank.code][linkEvent.linkId] = linkEvent.getList(rank.events)
        }
      })
    },
    focusLinkData(event, linkEvent, linkData) {
      linkEvent.onFocus(event, this.$store, linkData.data)
    },
    onOverLinkData(event, linkEvent, linkData) {
      this.sendEvent({ event: event })
      const predicate = linkEvent.getSearchPredicate(linkData.data)
      const eventsFilteredByPredicate = this.events.filter(predicate)
      const src = linkEvent.getRootEvent(this.events, eventsFilteredByPredicate, this.tooltipData)

      if (src) {
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
          src: src,
          dest: eventsFilteredByPredicate.filter(e => utils.differentEventOrManyLocation(src, e))
        })
      }
      this.collaborativeEventTreated()
    },
    onLeaveLinkData(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>
.show-associated {
  color: var(--c-gray-1);
  font-size: 1.3rem;
}

</style>