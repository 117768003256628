<template>
  <div>
    <div
      v-for="(groupEvents, code) in filteredEvents"
      :key="code"
    >
      <ListItem
        :event="groupEvents[0]"
      >
        <template #description>
          <p class="legend-pie-content-date">
            {{ getDateLabel(groupEvents) }}
          </p>
          <p class="mt-1">
            {{ groupEvents[0].defaultLabel }}
          </p>
        </template>
      </ListItem>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import ListItem from '@/components/event/ListItem.vue'

const store = useStore()

const props = defineProps({
  filtering: {
    type: [Object, null],
    required: false,
    default: null
  }
})

const filteredEvents = computed(() => props.filtering !== null
  ? props.filtering
  : store.getters['event/filtering/filteredEvents']
)

function getDateLabel(srcEvents) {
  if (srcEvents.length === 0) {
    return ''
  }
  const firstEvent = srcEvents[0]
  const lastEvent = srcEvents[srcEvents.length - 1]
  let label = srcEvents[0].onsetDateTime.slice(0, 4)

  if (srcEvents.length > 1 && firstEvent.onsetDateTime.slice(0, 4) !== lastEvent.onsetDateTime.slice(0, 4)) {
    label += `-${lastEvent.onsetDateTime.slice(0, 4)}`
  }
  return label
}
</script>