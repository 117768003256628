import { getItemMenu } from "@/config/contextMenu/contextMenuItems.js"
import sharedMenu from "@/config/contextMenu/shared-menu.js"
import ContextMenuConfigHelpers from '@/config/contextMenu/helpers.js'

import AppContextChecker from "@/libraries/AppContextChecker.js"

import borderListTypes from '@/enums/borderList/borderListTypes.js'
import IdScore from '@/shared/enums/score.js'
import periodUnits from '@/shared/enums/period_units.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'
import Timefilter from "@/enums/time_filter.js"
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

import UserRole from '@/shared/enums/userRole.js'
/**
 * Tableau de configuration des fonctionnalités du menu contextuel principale de l'application
 * EyeContextMenuItem[]
 */

const menuStruct = [
  {
    labelId: 'Logo0',
    selectedChecker: () => false,
    children: [
      {
        labelId: 'Diags_memorises',
        selectedChecker: (store) => store.getters['event/common/isDisplayedMemorizedEvents'] === true
      },
      {
        labelId: 'DP',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].dp === true,
        conditions: [AppContextChecker.isInPopulational]
      },
      {
        labelId: 'Sev>=2',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].severity !== undefined
          && store.getters['event/filtering/eventFilters'].severity.min === 2
          && store.getters['event/filtering/eventFilters'].severity.max === 4
          && store.getters['event/filtering/eventFilters'].antecedent === undefined
      },
      {
        labelId: 'Sev>=3',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].severity !== undefined
          && store.getters['event/filtering/eventFilters'].severity.min === 3
          && store.getters['event/filtering/eventFilters'].severity.max === 4
          && store.getters['event/filtering/eventFilters'].antecedent === undefined
      },
      {
        labelId: '5+recents',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].last === 5
      },
      {
        labelId: 'ATCD>=3',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].antecedent !== undefined
          && store.getters['event/filtering/eventFilters'].antecedent.min === 3
          && store.getters['event/filtering/eventFilters'].severity === undefined
      },
      {
        labelId: 'Sev>=3+ATCD>=3',
        selectedChecker: (store) => store.getters['event/filtering/eventFilters'].severity !== undefined
          && store.getters['event/filtering/eventFilters'].antecedent !== undefined
          && store.getters['event/filtering/eventFilters'].severity.min === 3
          && store.getters['event/filtering/eventFilters'].severity.max === 4
          && store.getters['event/filtering/eventFilters'].antecedent.min === 3
      },
      {
        labelId: 'Temps',
        selectedChecker: () => false,
        children: [
          {
            labelId: 'Semaine',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_TIME_FILTER) && store.getters['event/filtering/eventFilters'].time.unit === Timefilter.WEEK
          },
          {
            labelId: 'Mois',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_TIME_FILTER) && store.getters['event/filtering/eventFilters'].time.unit === Timefilter.MONTH
          }
        ],
        conditions: [(s) => AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'Top10',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_TOP_10),
        conditions: [(s) => AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'CreateFiltering',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_CREATE_FILTERING),
        conditions: [(s) => AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'AffichageTOUS',
        selectedChecker: (store) => store.getters['event/filtering/displayAllActiveCircleEvent'] === true
      },
      {
        labelId: 'Types_event',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY),
        conditions: [(s) => AppContextChecker.otherThanPharmacienTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'Mode',
        selectedChecker: () => false,
        children: [
          {
            labelId: 'DossierPatient',
            selectedChecker: (store) => AppContextChecker.isInPopulational(store) === false
          },
          {
            labelId: 'Populationnel',
            selectedChecker: () => false,
            children: [
              { labelId: 'CustomMergeEvents', selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY) === true },
              { labelId: 'Echelle',
                selectedChecker: () => false,
                children: [
                  {labelId: 'Echelle/lineaire', selectedChecker: (store) => AppContextChecker.isInPopulational(store) === true && ContextMenuConfigHelpers.isThisTypeOfScalePopulational(store, eyediagMode.populationalOptions.typeScale.LINEAR) },
                  {labelId: 'Echelle/racine-carre', selectedChecker: (store) => AppContextChecker.isInPopulational(store) === true && ContextMenuConfigHelpers.isThisTypeOfScalePopulational(store, eyediagMode.populationalOptions.typeScale.RACINECARRE) },
                  {labelId: 'Echelle/algo-Jenks', selectedChecker: (store) => AppContextChecker.isInPopulational(store) === true && ContextMenuConfigHelpers.isThisTypeOfScalePopulational(store, eyediagMode.populationalOptions.typeScale.JENKS) }
                ]
              }
            ],
            conditions: [(s) => AppContextChecker.isInNurseEntry(s) === false]
          }
        ],
        conditions: [AppContextChecker.otherThanPharmacienTypePatient]
      },
      {
        labelId: 'LiensCR',
        selectedChecker: (store) => store.getters['event/common/isDisplayedLines'] === true,
        conditions: [(s) => AppContextChecker.otherThanPharmacienTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      // { labelId: 'carnetvaccin', selectedChecker: () => false },
      { labelId: 'SauvegarderFiltres', selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY)}
    ]
  },
  {
    labelId: 'HistoryIcon',
    selectedChecker: () => false,
    children: [
      {
        labelId: 'TempsOP',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.YEAR, store.getters['circle/optimalYearPerCircle'])
      },
      {
        labelId: 'MoisTemp',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.MONTH, 1)
      },
      {
        labelId: 'Trimestre',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.QUARTER, 1)
      },
      {
        labelId: 'Semestre',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.SEMESTER, 1)
      },
      {
        labelId: '1 an',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.YEAR, 1)
      },
      {
        labelId: '2 ans',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.YEAR, 2)
      },
      {
        labelId: '3 ans',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.YEAR, 3)
      },
      {
        labelId: '4 ans',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisTemporalityEnabled(store, periodUnits.YEAR, 4)
      }
    ]
  },
  {
    labelId: 'Logo2',
    selectedChecker: () => false,
    children: [
      // { labelId: 'CustomHierarchy', selectedChecker: () => false },
      {
        labelId: 'Diabetologie',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.SYSTEM, id: hierarchy.hierarchy.DIABETOLOGIE}),
        conditions: [AppContextChecker.isInGeneralisteTypePatient]
      },
      {
        labelId: 'DiabetologieNurseEntry',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.SYSTEM, id: hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY}),
        conditions: [AppContextChecker.isInGeneralisteTypePatient]
      },
      {
        labelId: 'DiabetologieNurseEntryTrt',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.SYSTEM, id: hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT}),
        conditions: [AppContextChecker.isInGeneralisteTypePatient]
      },
      {
        labelId: 'HierarchyGHM',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'GHM'}),
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'HierarchyUM',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'UM'}),
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      {
        labelId: 'TESTW',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'Welcoop'}),
        conditions: [AppContextChecker.isInPharmacienTypePatient]
      },
      // { labelId: 'TESTW2', selectedChecker: () => false, conditions: [AppContextChecker.isInPharmacienTypePatient] },
      {
        labelId: 'TESTW3',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'Welcoop3'}),
        conditions: [AppContextChecker.isInPharmacienTypePatient]
      },
      {
        labelId: 'QOR',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'QOR'}),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'ChatiTypeExam',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'Chati'}),
        conditions: [AppContextChecker.isInCh]
      },
      {
        labelId: 'ChatiStatut',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'ChatiStatut'}),
        conditions: [AppContextChecker.isInCh]
      },
      {
        labelId: 'ChatiStatutGrouped',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'ChatiStatutGrouped'}),
        conditions: [AppContextChecker.isInCh]
      },
      {
        labelId: 'ChatiStatutGroupedCoro',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'ChatiStatutGroupedCoro'}),
        conditions: [AppContextChecker.isInCh]
      },
      {
        labelId: 'ChatiSexe',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisHierarchyEnabled(store, {type: hierarchy.type.USER, id: 'ChatiSexe'}),
        conditions: [AppContextChecker.isInCh]
      },
      // {
      //   labelId: 'EchelleW',
      //   selectedChecker: () => false,
      //   children: [
      //     { labelId: 'EchelleValeurW', selectedChecker: () => false },
      //     { labelId: 'EchelleSeverityW', selectedChecker: () => false },
      //   ],
      //   conditions: [AppContextChecker.isInPharmacienTypePatient]
      // },
      {
        labelId: 'Tooltips',
        selectedChecker: () => false,
        children: [
          { labelId: 'TooltipsOnOff', selectedChecker: () => false },
          { labelId: 'TooltipsReorg', selectedChecker: () => false },
          { labelId: 'TooltipsSupp', selectedChecker: () => false }
        ]
      },
      ...sharedMenu,
      { labelId: 'Centrer_representation', selectedChecker: () => false }
    ]
  },
  {
    labelId: 'Logo3',
    selectedChecker: () => false,
    children: [
      {
        labelId: 'FiltrePatient',
        selectedChecker: () => false,
        children: [
          {
            labelId: 'AucunFiltrePatient',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisPatientFilters(store, {sectionsEventsOnly: false, matchOnly: false})
          },
          {
            labelId: 'CatFiltrePatient',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisPatientFilters(store, {sectionsEventsOnly: true, matchOnly: false})
          },
          {
            labelId: 'CorresFiltrePatient',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisPatientFilters(store, {sectionsEventsOnly: false, matchOnly: true})
          }
        ],
        conditions: [AppContextChecker.otherThanPharmacienTypePatient]
      },
      {
        labelId: 'FactAggrCOVID',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.COVID),
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      { 
        labelId: 'Euroscore2.2', 
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.EUROSCORE2), 
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      { 
        labelId: 'ATCDfam', 
        selectedChecker: (store) => store.getters['refCircle/familyHistory/isDisplayedFamilyHistoryCircle'] === true, 
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      { 
        labelId: 'CHA2', 
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.CHADSVASC), 
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      { 
        labelId: 'HasBled', 
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.HASBLED),
        conditions: [(s) => AppContextChecker.isInGeneralisteTypePatient(s) && AppContextChecker.isInNurseEntry(s) === false]
      },
      // {
      //   labelId: 'Desir',
      //   selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.DESIR),
      //   conditions: [AppContextChecker.isInGeneralisteTypePatient]
      // },
      // {
      //   labelId: 'Findrisc',
      //   selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.FINDRISC),
      //   conditions: [AppContextChecker.isInGeneralisteTypePatient]
      // },
      {
        labelId: 'ScoreWelcoop',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.WELCOOP_ALERTE),
        conditions: [AppContextChecker.isInPharmacienTypePatient]
      },
      {
        labelId: 'ScoreQor0',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.QOR0),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'ScoreQor1',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.QOR1),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'ScoreQor2',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.QOR2),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'ScoreQor3',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.QOR3),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'ScoreQor4',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreEnabled(store, IdScore.QOR4),
        conditions: [AppContextChecker.isInQOR]
      },
      {
        labelId: 'Emplacement',
        selectedChecker: () => false,
        children: [
          {
            labelId: 'Emplacement1.012',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.012)
          },
          {
            labelId: 'Emplacement1.03',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.03)
          },
          {
            labelId: 'Emplacement1.066',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.066)
          },
          {
            labelId: 'Emplacement1.1',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.1)
          },
          {
            labelId: 'Emplacement1.15',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.15)
          },
          {
            labelId: 'Emplacement1.2',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.2)
          },
          {
            labelId: 'Emplacement1.3',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.3)
          },
          {
            labelId: 'Emplacement1.4',
            selectedChecker: (store) => ContextMenuConfigHelpers.isThisScoreCircleCoefRadius(store, 1.4)
          }
        ],
        conditions: [AppContextChecker.otherThanPharmacienTypePatient]
      },
    ]
  },
  {
    labelId: 'Logo4',
    selectedChecker: () => false,
    children: [
      { labelId: 'RetourAccueil', selectedChecker: () => false },
      {
        labelId: 'AfficherNonCl',
        selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_UNCLASSIFIED),
        conditions: [AppContextChecker.otherThanPharmacienTypePatient]
      },
      // { labelId: 'ImporterFichier', selectedChecker: () => false },
      // { labelId: 'ModeEdition', selectedChecker: () => false, conditions: [AppContextChecker.otherThanPharmacienTypePatient] },
      {
        labelId: 'Collaboration',
        selectedChecker: () => false,
        children: [
          { labelId: 'CollabCreer', selectedChecker: () => false },
          { labelId: 'CollabJoindre', selectedChecker: () => false },
          { labelId: 'CollabQuit', selectedChecker: () => false },
          { labelId: 'CollabCouperVisio', selectedChecker: () => false }, //conditions: AppContextChecker.isAdmin },
          { labelId: 'CollabListForceJoin', selectedChecker: (store) => ContextMenuConfigHelpers.isThisBorderListEnabled(store, borderListTypes.LIST_FORCE_JOIN_COLLAB) } //conditions: AppContextChecker.isAdmin }
        ]
      },
      {
        labelId: 'Patient',
        selectedChecker: () => false,
        conditions: [AppContextChecker.canUsePatientOptions],
        children: [
          { labelId: 'PatientCopy', selectedChecker: () => false },
          // { labelId: 'PatientMove', selectedChecker: () => false },
          { labelId: 'PatientEdit', selectedChecker: () => false }
        ]
      },
      // { labelId: 'legendBeta', selectedChecker: () => false },
      { labelId: 'deconnecter', selectedChecker: () => false }
    ]
  }
]

//gestion de l'état des listes présente dans la borderlist du caroussel comprend la fonciton waitFunction et findObjectFound

let contextMenuConfig = []

function initContextMenu(store) {
  initContextMenuWatchers(store)
  fillMenu(menuStruct, contextMenuConfig, contextMenuConfig, store)
}

function applyConditionsSelectedItems(menu, store) {
  let atLeastOneSelected = false

  for (const item of menu) {
    item.selected = item.children 
      ? applyConditionsSelectedItems(item.children, store)
      : item.selectedChecker(store)

    if (item.selected) {
      atLeastOneSelected = true
    }
  }
  return atLeastOneSelected
}

/***
 * @function
 * @name initContextMenuWatchers
 * @description
 * Cette fonction permet d'utiliser les "watcher" qui gère la gestion de l'état du menu contextuel
 */
function initContextMenuWatchers(store) {
  store.watch(() => store.getters['user/typeEntityContext'], () => {
    resetContextMenu(store)
  })

  store.watch(() => store.getters.hierarchy, () => {
    resetContextMenu(store)
  })
  //Lorsqu'un nouveau participant rejoint en session collaborative, reset le menuContextuel pour ne pas avoir de différence entre les participants avec les on/off sur les icones du menu contextuel
  store.watch(() => store.getters['ws/roomParticipants'], () => {
    resetContextMenu(store)
  })
}

/**
 * @function
 * @name fillMenu
 * @param {*} menuItems
 * @param {*} dest
 * @param {*} entryConfig
 * @param {*} hierarchy
 */

function fillMenu(menuItems, dest, entryConfig, store, hierarchy = '') {
  let index = 0
  for (const item of menuItems) {
    if (item.conditions !== undefined) {
      const conditionValidity = item.conditions.reduce(
        (acc, condition) => acc && condition(store),
        true
      )
      if (conditionValidity === false) {
        continue
      }
    }

    const fillItem = getItemMenu(entryConfig, item.labelId)
    fillItem.id = `${hierarchy}${index}`
    fillItem.selected = item.selectedChecker(store)
    fillItem.selectedChecker = item.selectedChecker
    fillItem.labelId = item.labelId
    if (item.hasOwnProperty('children')) {
      fillItem.children = []
      fillMenu(item.children, fillItem.children, entryConfig, store, `${hierarchy}${index}-`)
    }

    dest.push(fillItem)
    index++
  }
}

/**
 * @function
 * @name resetContextMenu
 * @param {*} struct 
 */

function resetContextMenu(store, struct = menuStruct) {
  contextMenuConfig.length = 0
  fillMenu(struct, contextMenuConfig, contextMenuConfig, store)
}

export default contextMenuConfig
export {
  fillMenu,
  resetContextMenu,
  initContextMenu,
  applyConditionsSelectedItems
}