import * as mutationTypes from '../mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import EyeFetch from '@/libraries/EyeFetch.js'

const getDefaultState = () => {
  return {
    /**
     * Liste contenant les identifiants des différents assemblages de filtres créé par l'utilisateur
     * @type {Object[]}
     */
    userAssemblies: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    userAssemblies: state => state.userAssemblies
  },
  mutations: {
    /**
     * Met à jour la liste contenant les identifiants des différents assemblages de filtres créé par l'utilisateur
     * @param {State} state state du module
     * @param {Object[]} payload liste d'objets contenant les identifiants des assemblages créé par l'utilisateur
     */
    [mutationTypes.UPDATE_USER_ASSEMBLIES](state, payload) {
      state.userAssemblies = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state local du module à sa valeur par défaut
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    /**
     * Initie une requête http vers le back pour sauvegarder l'assemblage de filtres fournit en paramètre
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     * @param {EyeUserAssembly} assembly Assemblage créé par l'utilisateur
     */
    async addUserAssembly(context, assemblyData) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/assembly`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(assemblyData)
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'assembly/addUserAssembly', params: assemblyData})
          return 
        }

        context.dispatch('getListUserAssemblies')
      } catch (err) {
        ErrorManager.fetchError(err)
        console.error(err)
      }
    },
    /**
     * Initie une requête http vers le back pour supprimer l'assemblage de filtres dont l'id est fournit en paramètre
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     * @param {Int} bddId Identifiant de l'assemblage devant être supprimé
     */
    async delUserAssembly(context, bddId) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/assembly/${bddId}`, {
          method: 'DELETE',
          credentials: 'include'
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'assembly/delUserAssembly', params: bddId})
          return 
        }

        context.dispatch('getListUserAssemblies')
      } catch (err) {
        ErrorManager.fetchError(err)
        console.error(err)
      }
    },
    /**
     * Cette fonction initie une requête http auprès du back pour obtenir la liste des identifiants des assemblages de filtres créés par l'utilisateur. Si l'utilisateur est en mode collaboratif, les identifiants des assemblages créés par le présentateur de la session collaborative seront retournés
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    async getListUserAssemblies(context) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/assembly/all/`, {
          method: 'GET',
          credentials: 'include'
        })
  
        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'assembly/getListUserAssemblies'})
          return
        }
  
        response = await response.json()
        context.commit(mutationTypes.UPDATE_USER_ASSEMBLIES, response)
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    },
    /**
     * Cette fonction permet d'initier une requête http auprès du serveur pour obtenir le détail d'un assemblage en particulier créé par l'utilisateur. Les données sont ensuite chargé dans le store pour appliquer l'assemblage sur la représentation. Si l'utilisateur est en session collaborative la recherche par rapport à l'identifiant de l'assemblage se fera dans la liste des assemblages créés par le présentateur de la session collaborative
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     * @param {Object} params Object contenant les paramètres pour la requête http (Contient notamment l'identifiant de l'assemblage dont le detail doit être récupéré)
     */
    async getUserAssembly(context, params) {
      const mutators = {
        circleFilters: `circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`,
        displayedEventsTypes: `${mutationTypes.SET_TYPES_EVENTS_TO_DISPLAY}`,
        displayedFamilyHistoryCircle: `refCircle/familyHistory/${mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE}`,
        eventFilters: `event/filtering/${mutationTypes.SET_EVENT_FILTERS}`,
        idScore: `refCircle/score/${mutationTypes.SET_ID_SCORE}`,
        mode: `${mutationTypes.SET_EYEDIAG_MODE}`,
        periodUnit: `circle/${mutationTypes.SET_PERIOD_UNIT}`,
        unitPerCircle: `circle/${mutationTypes.SET_UNIT_PER_CIRCLE}`,
        //Attention à la refacto
        hierarchy: mutationTypes.SET_HIERARCHY
      }

      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/assembly/${params.idAssembly}/`, {
          method: 'GET',
          credentials: 'include'
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'assembly/getUserAssembly', params})
          return
        }
  
        response = await response.json()
  
        for (const [key, value] of Object.entries(response.assembly)) {
          if (key === 'sections') {
            const baseHistorySection = context.rootState.historySections.splice(
              0,
              context.rootState.indexHistorySection + 1
            )
            context.commit(mutationTypes.SET_HISTORY_SECTIONS, [
              ...baseHistorySection,
              value,
            ], {root: true})
            context.commit(
              mutationTypes.SET_INDEX_HISTORY_SECTIONS,
              context.rootState.indexHistorySection + 1,
              {root: true}
            )
          } else {
            context.commit(mutators[key], value, {root: true})
          }
        }

        await context.dispatch('circle/getDataRepresentation', {
          unitPerCircle: context.rootState.circle.unitPerCircle,
          periodUnit: context.rootState.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        }, {root: true})
        
        if (response.assembly.idScore) {
          context.dispatch('refCircle/score/getScoreCircle', null, {root: true})
        }
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    }
  }
}