<template>
  <div>
    <AddEventModal />
    <ModalCollaborative />
  </div>
</template>

<script>
export default {
  name: 'EyeModals'
}
</script>

<script setup>
import AddEventModal from "@/components/modal/addEvent/AddEventModal.vue"
import ModalCollaborative from '@/components/modal/collaborative/ModalCollaborative.vue'

</script>