<template>
  <div
    class="tooltip-settings"
    @click="onDeleteEvent"
  >
    <div class="cursor-pointer">
      <div class="icon">
        ⌫
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import utils from '@/libraries/utils.js'
import reloadDataTypes from '@/shared/enums/reload_data_types'

const store = useStore()

const props = defineProps({
  tooltipData: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['exitTooltip'])

async function onDeleteEvent(event) {
  const idsToDelete = props.tooltipData.content.map(c => c.id)

  if (event.isTrusted) {
    await store.dispatch('event/edit/deleteEvent', { idEvents: idsToDelete })
  }
  store.dispatch('ws/sendEvent', {event: event})
  await store.dispatch('deleteUserCache')
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.TEMPORALITY
  })
  emit('exitTooltip')
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}
</script>

<style scoped>
.icon {
  color: var(--color-text);
  font-size: 1.4rem;
}
</style>