import * as mutationTypes from '@/store/mutations-types.js'
import BorderListTypes from '@/enums/borderList/borderListTypes.js'

const getDefaultState = () => ({
  lockedBy: null
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    lockedBy: state => state.lockedBy,
    isLegendOpen: (state, getters, rootState) => rootState.borderList.isDisplayedList === true && rootState.borderList.currentDisplayedBorderList === BorderListTypes.LIST_LEGEND
  },
  mutations: {
    [mutationTypes.SET_LOCKED_LEGEND_BY] (state, payload) {
      state.lockedBy = payload
    }
  },
  actions: {
    /**
     * Permet de remettre à la valeur par défaut le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    }
  }
}