import * as d3 from 'd3'
import _ from 'lodash'
import EyeCurve from '@/libraries/curves/EyeCurve.js'
import FilteringPathManager from '@/libraries/filters/PathManager.js'

/**
 * class LinesGenerator
 */

class LinesGenerator {
  constructor() {
    /**
     * Il s'agit de la fonction de construction de ligne utilisé pour tracé les lignes des filtrages entre les événements
     * @type {d3.Line}
     */
    this.lineFunc = d3.line()
      .x((d) => d.x)
      .y((d) => d.y)
    /**
     * Il s'agit de la fonction de construction de ligne utilisé pour tracé les lignes entre les événements liés par un même compte rendu
     * @type {d3.Link}
     */
    this.linkFunc = d3.link(EyeCurve)
      .x(d => d.x)
      .y(d => d.y)
  }

  /**
   * Cette fonction prend 2 événements en paramètres, cette fonction permet de calculer les coordonnées des points devant être reliés pour tracer un trait entre ces 2 événements de manière à ce que ce trait ne travers pas les cercles de surbrillance des événements. Ci-desous le lien de la solution pour le calcul
   * https://math.stackexchange.com/questions/175896/finding-a-point-along-a-line-a-certain-distance-away-from-another-point
   * @param {EyeEvent} event1 Evénement 1
   * @param {EyeEvent} event2 Evénement 2
   * @returns { Object } point1: EyeCoordinates Coordonnées du premier point; point2: EyeCoordinates Coordonnées du deuxième point
   */
  betweenCircle(event1, event2) {
    const O = {x: event1.cx, y: event1.cy}
    const A = {x: event2.cx, y: event2.cy}
    const distance = Math.sqrt(Math.pow(A.x - O.x, 2) + Math.pow(A.y - O.y, 2))
    
    let ratio = event1.r / distance
    const point1 = {
      x: (1 - ratio) * O.x + ratio * A.x,
      y: (1 - ratio) * O.y + ratio * A.y
    }
    ratio = event2.r / distance
    const point2 = {
      x: (1 - ratio) * A.x + ratio * O.x,
      y: (1 - ratio) * A.y + ratio * O.y
    }

    return { point1, point2 }
  }

  /**
   * Cette fonction permet pour le résultat d'un filtrage d'événement de générer les paths des lignes et symbols devant être présent sur la représentation pour l'affichage du filtrage
   * @param {EyeEventFilteringSection} selection Description du filtrage (Evenement devant être relié / progression intérieur, extérieur etc)
   * @param {Number} referenceRadius Rayon de référence
   * @returns {Object} paths: Object[] Liste des paths des lignes reliant les événements; symbols: EyeSymbol[] Liste des symbols devant être affichés sur la représentation
   */
  betweenEvents(selection, referenceRadius) {
    const pathManager = new FilteringPathManager(selection, referenceRadius, this.lineFunc, this.betweenCircle)
    pathManager.generateFilteringPaths()

    return { 
      paths: pathManager.paths,
      symbols: pathManager.symbols
    }
  }

  /**
   * Cette fonction permet de générer les paths des lignes reliant les événements liés par un même compte rendu
   * @param {EyeEvent} d Il s'agit de l'événement actuellement survolé par l'utilisateur
   * @param {EyeEvent[]} events Il s'agit du tableau contenant l'ensemble des événements présent sur les cercles visibles sur la représentation
   * @returns {Object[]} Liste des paths des lignes devant être affichés sur la représentation pour l'affichage des liens entre les événements présent dans un même compte rendu
   */
  betweenReport(linkedEvents) {
    const paths = []

    for (let i = 0; i < linkedEvents.dest.length; i++) {
      linkedEvents.dest[i].selected = true
      const points = this.betweenCircle(linkedEvents.dest[i], linkedEvents.src)
      paths.push(this.linkFunc({source: points.point2, target: points.point1}))
    }

    return paths
  }
}

export default new LinesGenerator()