import * as eventsTypes from '@/shared/enums/eventsTypes.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import utils from '@/libraries/utils.js'
import UserRole from '@/shared/enums/userRole.js'
import CohortUtils from '@/libraries/cohort/Utils.js'

export default {
  'PMSI_RSS': {
    canBeDisplayed: (mode, entityContext) => {
      return entityContext === UserRole.GENERALISTE && mode.type === eyediagMode.mode.POPULATIONAL
    },
    linkId: 'PMSI_RSS',
    collapseName: 'RSS',
    label: (data) => data.RSS,
    getList: (rankEvents) => {
      let results = []
      for (const event of rankEvents) {
        if (event.extension.RSS) {
          results.push({
            data: {
              RSS: event.extension.RSS.value,
            }
          })
        }
      }
      //Supprime les doublons
      results = results.filter((a, index, self) =>
        index === self.findIndex(b => a.data.RSS === b.data.RSS)
      )
      return results
    },
    getSearchPredicate: (data) => {
      return (e) => e.extension.RSS.value === data.RSS
    },
    getRootEvent(events, filteredByPredicate) {
      return utils.findEventFromRanks(filteredByPredicate, (e) => e.extension.typeDiagnostique?.value === eventsTypes.pmsiTypeDiag.DP)
    },
    onFocus(event, store, data) {
      CohortUtils.focusOn(event, store, {
        focusContent: eyediagMode.populationalOptions.focusContent.RSS,
        focusParams: data
      })
    }
  },
  'PMSI_RUM': {
    canBeDisplayed: (mode, entityContext) => {
      return entityContext === UserRole.GENERALISTE && mode.type === eyediagMode.mode.POPULATIONAL
    },
    linkId: 'PMSI_RUM',
    collapseName: 'RUM',
    label: (data) => `${data.RUM} / ${data.RSS}`,
    getList: (rankEvents) => {
      let results = []
      for (const event of rankEvents) {
        if (event.extension.RUM) {
          for (const rum of event.extension.RUM.value) {
            results.push({
              data: {
                RSS: event.extension.RSS.value,
                RUM: rum
              }
            })
          }
        }
      }
      //Supprime les doublons
      results = results.filter((a, index, self) =>
        index === self.findIndex(b => a.data.RSS === b.data.RSS && a.data.RUM === b.data.RUM)
      )
      return results
    },
    getSearchPredicate: (data) => {
      return (e) => e.extension.RSS.value === data.RSS && e.extension.RUM.value.includes(data.RUM)
    },
    getRootEvent(events, filteredByPredicate, tooltipData) {
      return events.find(e => e.id === tooltipData.id)
    },
    onFocus(event, store, data) {
      CohortUtils.focusOn(event, store, {
        focusContent: eyediagMode.populationalOptions.focusContent.RUM,
        focusParams: data
      })
    }
  },
  'CH_PATIENT': {
    canBeDisplayed: (mode, entityContext) => {
      return entityContext === UserRole.CH && mode.options.merge !== undefined
    },
    linkId: 'CH_PATIENT',
    collapseName: 'Patient',
    label: (data) => data.IdPatient,
    getList: (rankEvents) => {
      const results = []
      for (const event of rankEvents) {
        results.push({
          data: {
            IdPatient: event.extension.IdPatient
          }
        })
      }
      return results
    },
    getSearchPredicate: (data) => {
      return (e) => e.extension.IdPatient === data.IdPatient
    },
    getRootEvent(events, filteredByPredicate, tooltipData) {
      return events.find(e => e.id === tooltipData.id)
    },
    onFocus() {
      return
      // store.dispatch('ws/sendEvent', {event: event})
      // store.dispatch('ws/collaborativeEventTreated')
    }
  }
}