<template>
  <div>
    <component
      :is="settingsToDisplay"
      v-if="settingsToDisplay !== null"
      :tooltip-data="tooltipData"
      @exit-tooltip="exitTooltip"
    />
  </div>
</template>

<script>
export default {
  name: 'TooltipSettings'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { markRaw, computed } from 'vue'

import Patient from '@/components/tooltip/settings/Patient.vue'
import DiabFollowUp from '@/components/tooltip/settings/DiabFollowUp.vue'
import AppContextChecker from '@/libraries/AppContextChecker.js'

const store = useStore()

const props = defineProps({
  tooltipData: {
    type: Object,
    required: true
  }
})

const settingsToDisplay = computed(() => {
  if (AppContextChecker.isInNurseEntry(store)) {
    return markRaw(DiabFollowUp)
  } else if (store.getters['isInPatient'] === true) {
    return markRaw(Patient)
  }
  return null
})

const emit = defineEmits(['exitTooltip'])

function exitTooltip() {
  emit('exitTooltip')
}
</script>

<style>
.tooltip-settings {
  display: flex;
  margin-right: 7px;
}
</style>