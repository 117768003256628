import utils from "@/libraries/utils.js"
import * as mutationTypes from "@/store/mutations-types"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

class CohortUtils {
  static async focusOn(event, store, {focusContent, focusParams}) {
    store.dispatch('ws/sendEvent', { event: event })
    event.preventDefault()
    store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
      src: {},
      dest: []
    })

    store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, {
      dp: true
    })
    store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, 'Diagnostic principal')

    store.commit(`${mutationTypes.ADD_PREVIOUS_MODE}`, store.getters['eyediagMode'])
    store.commit(mutationTypes.SET_EYEDIAG_MODE, {
      type: eyediagMode.mode.PATIENT,
      options: {
        cohortBase: true,
        cohortFocusContent: focusContent,
        cohortFocusParams: focusParams
      }
    })
    await store.dispatch('circle/getDataRepresentation', {
      unitPerCircle: store.state.circle.unitPerCircle,
      periodUnit: store.state.circle.periodUnit,
      reloadDataTypes: reloadDataTypes.CATEGORISATION
    })
    await store.dispatch('patient/getPatientData')
    utils.onGlobalAnimationEnd(store, () => {
      store.dispatch('ws/collaborativeEventTreated')
    })
  }
}

export default CohortUtils