<template>
  <div>
    <div
      v-for="(report, index) of reports"
      :key="report.id"
      @mouseover="(e) => onHoverReport(e, report)"
      @mouseleave="onLeaveReport"
      @click="(e) => onClickReport(e, report)"
    >
      <ChronologyItem
        :index="index"
        :selected="report.id === lockedParcours?.id"
        type-link="none"
      >
        <template #right-content>
          <p>{{ report.description }}</p>
        </template>  
      </ChronologyItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HudReport'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import * as mutationTypes from '@/store/mutations-types.js'
import ChronologyItem from '@/components/utils/ChronologyItem.vue'
import CustomFilteringFormContent from '@/shared/enums/customFilteringFormContent.js'
import FilterCustom from '@/shared/libraries/filterCustom.js'
import LegendContent from '@/enums/borderList/legendContent.js'

const store = useStore()

const reports = computed(() => store.getters['event/report/reports'])
const allEvents = computed(() => store.getters['event/common/allEvents'])
const lockedParcours = computed(() => store.getters['event/parcours/lockedParcours'])

const template = () => ({
  operator: 'OR',
  type: 'block', 
  conditions: []
})

async function onHoverReport(event, report) {
  if (lockedParcours.value !== null) {
    return
  }
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`event/parcours/${mutationTypes.SET_HOVERED_PARCOURS}`, report)
  const reportEvents = allEvents.value.filter(e => report.idEvents.includes(e.id))
  let codes = reportEvents.map(e => e.code)
  codes = codes.filter((code, index) => codes.indexOf(code) === index)

  const filtering = template()
  filtering.conditions = codes.map(code => ({
    operator: 'AND',
    type: 'item',
    typeCondition: CustomFilteringFormContent.STRICT_VALUE,
    item: {
      ...FilterCustom.getTemplateFor(CustomFilteringFormContent.STRICT_VALUE, {
        key: 'code',
        value: code
      }),
    }
  }))

  store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, {
    custom: filtering
  })
  await store.dispatch('event/common/baseEnableFiltering', {label: 'Custom', isEventsMemorized: false})
  store.dispatch('ws/collaborativeEventTreated')
}

async function onLeaveReport(event) {
  if (lockedParcours.value !== null) {
    return
  }
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`event/parcours/${mutationTypes.SET_HOVERED_PARCOURS}`, null)
  await store.dispatch('event/common/disableFiltering')
  store.dispatch('ws/collaborativeEventTreated')
}

function onClickReport(event, report) {
  store.dispatch('ws/sendEvent', { event: event})
  if (lockedParcours.value === null || lockedParcours.value.id !== report.id) {
    store.commit(`event/parcours/${mutationTypes.SET_LOCKED_PARCOURS}`, report)
    store.commit(`borderList/legend/${mutationTypes.SET_LOCKED_LEGEND_BY}`, LegendContent.HOVERED_PARCOURS.type)
  } else {
    store.commit(`event/parcours/${mutationTypes.SET_LOCKED_PARCOURS}`, null)
    store.commit(`borderList/legend/${mutationTypes.SET_LOCKED_LEGEND_BY}`, null)
  }
  store.dispatch('ws/collaborativeEventTreated')
}

</script>