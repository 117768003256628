// import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from "@/libraries/EyeFetch"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async searchEvents(context, params) {
      const requestBody = await context.dispatch('getRepresentationReqBody', {}, {root: true})
      delete requestBody.unitPerCircle
      delete requestBody.periodUnit
      requestBody.search = params.search
      requestBody.idPatient = context.rootState.patient.idPatient

      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/services/search`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(requestBody),
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'search/searchEvents', params: params})
          return
        }

        response = await response.json()
        return response
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
      }
    }
  }
}