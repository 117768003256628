import EyeApp from '../views/EyeApp.vue'
import Home from '../views/Home/Home.vue'
import Login from '../views/Login.vue'
import Bio from '../views/Bio.vue'
import Test from '../views/Test.vue'
import TutorialHome from '../views/TutorialHome.vue'
import TutorialPresentation from '../views/TutorialPresentation.vue'
import PatientCreation from '../views/PatientCreation.vue'
import AccountCreation from '@/views/AccountCreation/AccountCreation.vue' 
import * as VueRouter from 'vue-router'
import store from '@/store/index.js'
import ImportView from "@/views/ImportView"
import Disclaimer from "@/views/Disclaimer.vue"
import UserRole from "@/shared/enums/userRole.js"
import * as eyediagMode from "@/shared/enums/eyediagMode.js"
import * as mutationTypes from '@/store/mutations-types.js'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/eyeapp/', name: 'EyeApp', component: EyeApp },
  { path: '/login/', name: 'Login', component: Login },
  { path: '/bio/', name: 'Bio', component: Bio },
  { path: '/test/', name: 'Test', component: Test },
  { path: '/tutorial/home', name: 'TutorialHome', component: TutorialHome },
  { path: '/tutorial/presentation', name: 'TutorialPresentation', component: TutorialPresentation },
  { path: '/import/', name: 'ImportView', component: ImportView},
  { path: '/edit/patient', name: 'PatientCreation', component: PatientCreation},
  { path: '/disclaimer', name: 'Disclaimer', component: Disclaimer},
  { path: '/account/create', name: 'AccountCreation', component: AccountCreation}
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
})

router.beforeEach(to => {
  const routerNames = []

  routes.forEach( obj => routerNames.push(obj.name))
  if ((to.name !== 'Login' && store.getters['user/loggedIn'] === false) || !routerNames.includes(to.name)) {
    return { name: 'Login' }
  }
  if(to.name === 'ImportView' && store.getters.eyediagMode.type !== eyediagMode.mode.UNIVERSAL){
    return { name: 'Login' }
  }
  if (to.name !== 'Disclaimer' && store.getters['user/userData'].Role && store.getters['user/userData'].Role.includes(UserRole.DISCLAIMER)) {
    return { name: 'Disclaimer' }
  }
})

router.afterEach((to, from, failure) => {
  if (failure === undefined) {
    store.commit(`appNavigation/${mutationTypes.SET_APP_ROUTE_NAME}`, to.name)
  }
})

const test = {
  "userinfo":
    {
      "address": {"essential": true},
    }
}

export const loginUrl = `${process.env.VUE_APP_SERVER_BASE_URL}/oidc/auth?client_id=${process.env.VUE_APP_EYEDIAG_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_FRONT_BASE_URL}/login/&response_type=code&scope=openid+offline_access&state=321&claims=${JSON.stringify(test)}`
export default router