<template>
  <ListBase
    id="list-create-filtering"
    ref="listBaseRef"
    title="Filtrage personnalisé"
  >
    <template #content>
      <FiltersCustomCondtions
        v-model="data"
      />
      <div class="mt-10">
        <p
          class="cursor-pointer underline underline-offset-2"
          @click="resetFiltering"
        >
          Effacer le filtrage
        </p>
        <ToggleSwitch
          v-model:status="editStrictRepresentation"
          text="Filtrer la représentation"
          class="mt-4"
        />
        <ToggleSwitch
          v-model:status="editHighlight"
          text="Surbrillance"
          class="mt-4"
        />
        <DesignButton
          text="Valider"
          class="mt-6"
          @click="validateFiltering"
        />
      </div>
    </template>
  </ListBase>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import ListBase from '@/components/borderLists/ListBase.vue'
import FiltersCustomCondtions from '@/components/event/filters/custom/Conditions.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import DesignButton from '@/components/DesignButton.vue'
import { useStore } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from '@/libraries/utils.js'
import customFilteringFormContent from '@/shared/enums/customFilteringFormContent.js'
import FilterCustom from '@/shared/libraries/filterCustom.js'

const store = useStore()

const listBaseRef = ref(null)
const data = ref(getDefaultValueFiltering())

const editStrictRepresentation = ref(false)
const editHighlight = ref(false)

const strictRepresentation = computed(() => store.getters['event/filtering/strictRepresentation'])
const highlight = computed(() => store.getters['event/filtering/highlight'])

watch(strictRepresentation, () => {
  editStrictRepresentation.value = strictRepresentation.value
})

watch(highlight, () => {
  editHighlight.value = highlight.value
})

onMounted(() => {
  editHighlight.value = highlight.value
  editStrictRepresentation.value = strictRepresentation.value
})

async function validateFiltering(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, {
    custom: data.value
  })
  store.commit(`event/filtering/${mutationTypes.SET_FILTERING_HIGHLIGHT}`, editHighlight.value, { root: true })
  store.commit(`event/filtering/${mutationTypes.SET_FILTERING_STRICT_REPRESENTATION}`, editStrictRepresentation.value, { root: true })
  await store.dispatch('event/common/baseEnableFiltering', {label: 'Custom', isEventsMemorized: false})

  if (editStrictRepresentation.value === true) {
    store.dispatch('circle/getDataRepresentation', {
      unitPerCircle: store.state.circle.unitPerCircle,
      periodUnit: store.state.circle.periodUnit,
      reloadDataTypes: reloadDataTypes.CATEGORISATION
    })
  }

  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

async function resetFiltering(event) {
  store.dispatch('ws/sendEvent', { event: event })
  data.value = getDefaultValueFiltering()
  store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, {})
  store.commit(`event/filtering/${mutationTypes.SET_FILTERING_HIGHLIGHT}`, false)
  store.commit(`event/filtering/${mutationTypes.SET_FILTERING_STRICT_REPRESENTATION}`, false)
  await store.dispatch('event/common/baseEnableFiltering', {label: 'Custom', isEventsMemorized: false})
  store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.state.circle.unitPerCircle,
    periodUnit: store.state.circle.periodUnit,
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })

  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

function getDefaultValueFiltering() {
  return FilterCustom.getDefaultRootTemplate()
}

function movePanel(...params) {
  listBaseRef.value.movePanel(...params)
}

defineExpose({
  movePanel: movePanel
})
</script>

<style scoped>

</style>