<template>
  <MovingItem
    ref="movingContainer"
    container-class="add-event-modal-generic-container"
    :ref-slot="refDialog"
    :z-index="1"
  >
    <div
      ref="modal"
      class="modal_exportgraph"
      @mousemove="sendCheckScroll"
      @mousedown="onMouseDownModal"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-col grow min-h-0">
          <div
            class="flex flex-row justify-between"
          >
            <h3>Export Graph</h3>
            <!-- <CrossButton
              @close="exitModal"
            /> -->
          </div>
          <select
            v-model="idSelectedGraphType"
            @change="onChangeGraphType"
          >
            <option
              v-for="type in graphType"
              :key="type.id"
              :value="type.id"
            >
              {{ type.nom }}
            </option>
          </select>
      
          <svg  
            ref="svgContainerKM" 
            xmlns="http://www.w3.org/2000/svg" 
            class="svg-container"
          />
        </div>
      </div>
      <OptionsExport
        :n-options="selectedGraphType.nOptions"
        :graph-type="selectedGraphType.commande"
        @raffraichir="raffraichi"
      />
      <div class="grid grid-cols-2 mt-5">
        <DesignButton 
          text="Export SVG"
          @click="(e) => exportGraph(e, 'svg', svgContainerKM)"
        />
        <DesignButton 
          text="Export PNG"
          @click="(e) => exportGraph(e, 'png', svgContainerKM)"
        />
      </div>
    </div>
  </MovingItem>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../../../store/mutations-types.js";
import CrossButton from "@/components/Button.vue"
import utils from "@/libraries/utils.js"
import EyeFetch from "@/libraries/EyeFetch.js"
import DesignButton from '@/components/DesignButton.vue'
import MovingItem from "@/components/MovingItem.vue"
import OptionsExport from "./OptionsExportGraph.vue";
import AppContextChecker from "@/libraries/AppContextChecker.js";
import event from "@/store/modules/event/event.js";
import Sections from '@/components/Sections.vue';
import { formatGrouped } from '../../borderLists/ListGraphs/PlotUtils_List.js'
import CollaborativeUtils from "@/libraries/collaborative/utils.js"

export default {
  name: "ModalDialog",
  components: {
    // CrossButton,
    DesignButton,
    MovingItem,
    OptionsExport,
  },
  data: () => {
    const graphType = [
      { id: 0, nom: "Kaplan Meier", nOptions: 2, commande: 'KM' },
      { id: 1, nom: "Cumulatif",    nOptions: 0, commande: 'Cumul' },
      { id: 2, nom: "Histogramme", nOptions: 3, commande: 'HistoTrancheAge' }, 
      { id: 3, nom: "BoxPlot",  nOptions: 3, commande: 'BoxPlot'},
    ]
    return {
      sectionName: "",
      idCounter: 0,
      isEditing: false,
      refDialog: null,
      graph_setting: {
        drop_init: false,
        Format: 0,
        pvalue: true,
        t_filtre: -1,
        Title: '',
        Type: "KM",
        xlabel: '',
        ylabel: '',
      },
      graphType: graphType,
      selectedGraphType: graphType[0],
      idSelectedGraphType: graphType[0].id
    };
  },
  computed: {
    ...mapGetters({
      Sections: "sections",
      allEvents: "event/common/allEvents",
      pieSelectedSections: "pie/pieSelectedSections",
      editingType: 'event/edit/editingType',
      unitPerCircle: 'circle/unitPerCircle',
      periodUnit: 'circle/periodUnit',
      isInPatient: 'isInPatient',
      isInNurseEntry: 'isInNurseEntry',
      folderId: 'patient/folderId',
    }),
  },
  watch: {
  },
  mounted() {
    this.initMovingObject()
    this.svgContainerKM = this.$refs.svgContainerKM
    this.getGraphTool()
    this.selectedGraphType = this.graphType[0]
    this.idSelectedGraphType = this.graphType[0].id
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteEvent: 'event/edit/deleteEvent',
      deleteUserCache: 'deleteUserCache',
      getDataRepresentation: 'circle/getDataRepresentation',

    }),
    onMouseDownModal(event) {
      this.sendEvent({ event: event })
      if (['div', 'form', 'label'].includes(event.target.localName) === false) {
        event.stopPropagation()
      }
      this.collaborativeEventTreated()
    },
    initMovingObject() {
      this.refDialog = this.$refs.modal      
      this.$refs.movingContainer.initialCoordinate(50,50)
      this.$refs.movingContainer.moveItem()
      this.$refs.movingContainer.setOptions({
        share: true,
        resize: true,
      })
    },
    exitModal(event) {
      if (event) {
        this.sendEvent({ event })
      }
      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      this.$store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, null)
      if (event) {
        this.collaborativeEventTreated()
      }
    },
    async getGraphTool() {
      let mesEvents =[]
      if (AppContextChecker.isMergedEvents(this.$store)) {
        this.allEvents.forEach(event => {
          event.ranks.forEach(rank => {
            rank.events.forEach(rankEvent => 
              mesEvents.push(rankEvent)
            )            
          })
        })
        mesEvents = mesEvents.map(item => {
          const replacement = this.Sections.find(repl => repl.id === item.idSection);
          return {
            ...item,
            idSection: replacement ? replacement.name : item.idSection
          };
        });
      }
      else {
        mesEvents = this.allEvents
      }
      try {
        let dataGraph = mesEvents.map(({ Name, code, onsetDateTime, extension, idSection }) =>({
          id: extension.IdPatient,
          Date_Events: onsetDateTime,
          idSection: /*Name*/ idSection,
          code: code,
          Age: extension.AgeJourExam,
          Sexe: extension.Sexe,
        }))
            
        const response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/graphtool_data`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            dataGraph: dataGraph,
            setting: this.graph_setting
          })
        })
        const res = await response.json()
        if (res) {
          const parser = new DOMParser()
          const svgDocument = parser.parseFromString(res.svg, 'image/svg+xml') // { svg: '<svg>' }
          const svgElement = svgDocument.documentElement
          this.svgContainerKM.innerHTML = '' 
          Array.from(svgElement.childNodes).forEach((node) =>
            this.svgContainerKM.appendChild(node)
          )
        }
      } catch (error) {
        console.error('Error making the POST request:', error)
      }
    },
    exportGraph(event, format, svgElement) {
      if (!svgElement) {
        console.error('SVG element not found')
        return
      }

      const serializer = new XMLSerializer()
      const svgString = serializer.serializeToString(svgElement)
      let graphName = "graph"
      if (this.graph_setting.Title.length > 0)
        graphName = this.graph_setting.Title
      // Export as SVG
      if (format === 'svg') {
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)
        const link = document.createElement('a')
        link.href = url
        link.download = `${graphName}.svg`
        link.click()
        URL.revokeObjectURL(url) 
      }
      // Export as PNG
      else if (format === 'png') {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const img = new Image()
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)
        const scaleFactor = window.devicePixelRatio || 1; // Use device pixel ratio for better resolution
        console.log(`scale factor ${scaleFactor}`)
        img.onload = () => {
          const viewBox = svgElement.getAttribute('viewBox')
          if (viewBox) {
            const [x, y, width, height] = viewBox.split(' ').map(Number)
            canvas.width = width
            canvas.height = height
          } else {
            canvas.width = 1440 || (svgElement.clientWidth || 800) * scaleFactor;
            canvas.height = 860 || (svgElement.clientHeight || 600) * scaleFactor;
          }
          ctx.scale(scaleFactor, scaleFactor);
          ctx.drawImage(img, 0, 0, canvas.width , canvas.height );
          URL.revokeObjectURL(url) 

          canvas.toBlob((blob) => {
            const pngUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = pngUrl
            link.download = `${graphName}.png`
            link.click()
            URL.revokeObjectURL(pngUrl) // Cleanup
          }, 'image/png')
        }

        img.onerror = () => {
          console.error('Error loading SVG as image')
          URL.revokeObjectURL(url) // Cleanup
        }

        img.src = url
      } else {
        console.error('Unsupported format:', format)
      }
    },
    raffraichi(data) {
      // console.log(data);
      this.graph_setting = data
      this.getGraphTool()
    },
    onChangeGraphType(event) {
      CollaborativeUtils.onInput(this.$store, event, this.idSelectedGraphType, true, () => {
        this.selectedGraphType = this.graphType.find(type => type.id === this.idSelectedGraphType)
        this.raffraichi({
          Title: '',
          xlabel: '',
          ylabel:'',
          Format: 0,
          Type: this.selectedGraphType.commande,
          pvalue: true,
          n: 4,
          age_range:10
        })
      })
    },
    onInput(...params) {
      CollaborativeUtils.onInput(...[this.$store, ...params])
    },
    sendCheckScroll(e) {
      utils.sendCheckScroll(e, this.$store)
    }
  },
}
</script>

<style>
.add-event-modal-generic-container{
  position: fixed;
}
.modal_exportgraph {
  flex-direction: column;
  background-color: var(--color-bg-1);
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 50vw;
  height: 50vh;
  min-width: 30vw;
  min-height: 50vh;
  resize: both;
  overflow: auto;
  color: var(--color-text);
  padding: 12px;
}

.input_wrapper {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.modal_form {
  display: flex;
  flex-direction: column;
}

/* .modal_custom button:hover {
background: rgb(86, 97, 109);
transition-duration: 500ms;
} */
.modal_custom button:nth-child(1) {
  margin-right: 2px;
}
.modal_custom button:nth-child(2) {
  margin-left: 2px;
}
.button_wrapper {
  display: flex;
  margin-top: 20px;
}
textarea {
  margin-bottom: 15px;
  resize: vertical;
}

dialog::backdrop {
  background: linear-gradient(90deg, navy,rgb(1, 1, 47) );
  opacity: 0.1;
}

#suggestions-modal {
  margin-top: 20px;
  margin-inline: 5px;
}

.suggestion {
  cursor: pointer;
}

.error-modal {
  color: red;
}

/* .insert-event-recurrence-choose-dates { */
input:invalid {
  border: red solid 1px;
}
.svg-container {
  width: 100%;
  height: 100%;
}
</style>