import * as mutationTypes from '../mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import UserRole from '@/shared/enums/userRole.js'
import cookies from 'vue-cookies'
import { loginUrl } from '@/router/router.js'
import { appAvailableEntityContext } from '@/shared/config/entityContext.js'

export default {
  namespaced: true,
  state: {
    /**
     * Information de l'utilisateur utilisant l'application
     * @type {Object}
     */
    userData: {},
    loggedIn: false,
    tokenTimeout: null,
    typeEntityContext: UserRole.GENERALISTE,
    availableTypeEntityContext: []
  },
  getters: {
    userData: state => state.userData,
    loggedIn: state => state.loggedIn,
    typeEntityContext: state => state.typeEntityContext,
    availableTypeEntityContext: state => state.availableTypeEntityContext
  },
  mutations: {
    /**
     * Permet de définir les données de l'utilisateur actuellement connecté à l'application
     * @method
     * @public
     */
    [mutationTypes.UPDATE_USER_DATA] (state, payload) {
      state.userData = payload
    },
    [mutationTypes.SET_LOGGED_IN] (state, payload) {
      state.loggedIn = payload
    },
    [mutationTypes.SET_TYPE_ENTITY_CONTEXT] (state, payload) {
      state.typeEntityContext = payload
      cookies.set('typeEntityContext', payload, "30d")
    },
    [mutationTypes.SET_AVAILABLE_TYPE_ENTITY_CONTEXT] (state, payload) {
      state.availableTypeEntityContext = payload
    }
  },
  actions: {
    /**
     * Permet d'obtenir les informations de l'utilisateur connecté à l'application
     * @method
     * @public
     */
    async getUserData(context) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/me`, {
          method: 'GET',
          credentials: 'include'
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'user/getUserData', params: null})
          return null
        }

        response = await response.json()

        context.commit(mutationTypes.UPDATE_USER_DATA, response)
        context.dispatch('calcAvailableTypeEntityContext')
        return response
      } catch (err) {
        ErrorManager.fetchError(err)
        return null
      }
    },
    calcAvailableTypeEntityContext(context) {
      const findEntitiesContext = []

      for (const role of context.state.userData.Role) {
        if (appAvailableEntityContext.includes(role)) {
          findEntitiesContext.push(role)
        }
      }

      if (findEntitiesContext.length > 0) {
        context.commit(mutationTypes.SET_TYPE_ENTITY_CONTEXT, findEntitiesContext[0])
      }
      context.commit(mutationTypes.SET_AVAILABLE_TYPE_ENTITY_CONTEXT, findEntitiesContext)
    },
    async logout() {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/user/logout`, {
          method: 'GET',
          credentials: 'include'
        })
        response = await response.json()
  
        const params = new URLSearchParams()
        params.append("xsrf", response.xsrf)
        params.append("logout", "yes")
        response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/oidc/session/end/confirm`, {
          method: "POST",
          credentials: 'include',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          },
          body: params
        })
      } catch {}
      window.location.href = loginUrl
    },
    async createAccount(context, accountData) {
      try {
        await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/account/create`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(accountData)
        })
      } catch (err) {
        ErrorManager.fetchError(err)
        return null
      }
    }
  }
}