<template>
  <div>
    <DesignButton
      :text="text"
      class="hidden"
      @click="onClickImport"
    />
    <input
      ref="inputConfig"
      type="file"
      name="file"
      :accept="accept"
      class="hidden"
      @change="readFile"
    >
  </div>
</template>

<script setup>
import DesignButton from '@/components/DesignButton.vue'
import { ref } from 'vue'

defineProps({
  text: {
    type: String,
    required: true
  },
  accept: {
    type: String,
    required: false,
    default: '*'
  }
})

const inputConfig = ref(null)

const emit = defineEmits(['content'])

function onClickImport() {
  inputConfig.value.click()
}

async function readFile() {
  if (inputConfig.value !== null) {
    const file = inputConfig.value.files[0]
    const fileReader = new FileReader()
    fileReader.readAsText(file)

    let result = await new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.onerror = (error) => reject(error)
    })
    emit('content', result)
  }
}

</script>