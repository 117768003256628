<template>
  <baseTutoTile
    :id-patient="5"
    :id-tuto="6"
    :number-steps="numberSteps"
  >
    <template #title>
      <h1>Concept </h1>
    </template>
    <template #description>
      <p>- Cercles</p>
      <p>- Evénements</p>
      <p>- Catégorisation</p>
    </template>
    <template #backgroundImage>
      <img
        src="./priseEnMain.png"
        style="height: 35vh;"
      >
    </template>
  </baseTutoTile>
</template>

<script>
import baseTutoTile from '@/components/tutorial/homeItems/baseTutoTile.vue'

export default {
  name: "Component1ViewTuto",
  components: {
    baseTutoTile
  },
  data: () => ({
    numberSteps: require.context(`@/components/tutorial/concepts`, false, /\.(vue)$/).keys().length
  })
}
</script>