export default {
  'HOVERED_EVENT': {
    id: 'HOVERED_EVENT',
    autoOpenBorderList: false
  },
  'HOVERED_CIRCLE': {
    id: 'HOVERED_CIRCLE',
    autoOpenBorderList: false
  },
  'HOVERED_PARCOURS': {
    id: 'HOVERED_PARCOURS',
    autoOpenBorderList: true
  },
  'PIE_HOVERED_SECTIONS': {
    id: 'PIE_HOVERED_SECTIONS',
    autoOpenBorderList: false
  },
  'HOVERED_REP_CIRCLE': {
    id: 'HOVERED_REP_CIRCLE',
    autoOpenBorderList: false
  }
}