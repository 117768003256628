import borderListTypes from '@/enums/borderList/borderListTypes.js'
import * as mutationTypes from '@/store/mutations-types.js'

import topElem from '@/store/modules/borderList/topElem.js'
import legend from '@/store/modules/borderList/legend.js'

const getDefaultState = () => ({
  /**
   * Définit quel panel de droite est affiché. Si la valeur est à null, aucun panel n'est affiché
   * @type {null|EyeEnumBorderListType}
   */
  currentDisplayedBorderList: borderListTypes.LIST_LEGEND,
  /**
    * Contient l'ancien panel de droite qui était affiché. Si la valeur est à null, aucun panel n'était affiché
    * @type {null|EyeEnumBorderListType}
    */
  oldDisplayedBorderList: null,
  isDisplayedList: true,
})

export default {
  namespaced: true,
  modules: {
    topElem: topElem,
    legend: legend
  },
  state: getDefaultState(),
  getters: {
    currentDisplayedBorderList: state => state.currentDisplayedBorderList,
    isDisplayedList: state => state.isDisplayedList,
    oldDisplayedBorderList: state => state.oldDisplayedBorderList
  },
  mutations: {
    /**
    * Permet de définir le panel actuellement affiché sur la droit de l'application
    * @method
    * @public
    */
    [mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST](state, payload) {
      state.currentDisplayedBorderList = payload
    },
    [mutationTypes.SET_OLD_DISPLAYED_BORDER_LIST](state, payload) {
      state.oldDisplayedBorderList = payload
    },
    [mutationTypes.UPDATE_DISPLAYED_LIST](state, payload) {
      state.isDisplayedList = payload
    }
  },
  actions: {
    /**
     * Permet de reset par défaut le state de l'application. Cette fonction est utilise avant chaque accès à un nouveau patient pour remttre à 0 tout ce qui a pu être effectué sur l'application
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    async resetState(context) {
      const promises = []
      const modulesToReset = ['topElem', 'legend']

      //Si on regarde un tuto, on ne reset pas le state pour éviter des incohérences avec eyeFlow
      if (context.rootState.tuto.idDisplayedTuto === null) {
        Object.assign(context.state, getDefaultState())
      }

      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`borderList/${module}/resetState`, null, {root: true}))
      })

      return Promise.all(promises)
    }
  }
}