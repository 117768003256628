<template>
  <div>
    <ol>
      <li
        v-for="(hierarchy, index) in options.hierarchy"
        :key="index"
        class="mt-2"
      >
        <select
          v-model="options.hierarchy[index]"
          @change="(e) => onChangeHierarchyItem(e, index)"
        >
          <option
            v-for="(property, idx) in userEventProperties"
            :key="idx"
            :value="property.name"
          >
            {{ property.name }}
          </option>
        </select>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'ConfigSectionHierarchy'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const userEventProperties = computed(() => store.getters['event/common/userEventProperties'])

const options = defineModel({
  type: Object,
  required: true
})

options.value.hierarchy = [null]

function onChangeHierarchyItem(event, index) {
  CollaborativeUtils.onInput(store, event, options.value.hierarchy[index], true, () => {
    if (index === options.value.hierarchy.length - 1) {
      options.value.hierarchy.push(null)
    }
  })
}

</script>

<style>
ol {
  padding-left: 15px;
}
</style>