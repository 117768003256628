<template>
  <ListBase
    id="list-config-sections"
    ref="listBaseRef"
    title="Sections"
  >
    <template #content>
      <ListSections
        v-model="base"
      />
      <p
        class="mt-4 cursor-pointer underline underline-offset-2"
        @click="exportSections"
      >
        Exporter
      </p>
      <p
        class="mt-4 cursor-pointer underline underline-offset-2"
        @click="removeCustomSections"
      >
        Effacer
      </p>
      <DesignButton
        text="Valider Catégories"
        class="mt-4"
        @click="onValidateSections"
      />
    </template>
  </ListBase>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import ListSections from '@/components/borderLists/ListConfigSections/ListSections.vue'
import DesignButton from '@/components/DesignButton.vue'
import utils from '@/libraries/utils.js'
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const store = useStore()

const listBaseRef = ref(null)
const base = ref([])

function movePanel(...params) {
  listBaseRef.value.movePanel(...params)
}

async function onValidateSections(event) {
  store.dispatch('ws/sendEvent', { event: event })
  setIdToSection(base.value)
  store.commit(`pie/${mutationTypes.SET_CUSTOM_SECTIONS_CONFIG}`, base.value)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

async function removeCustomSections(event) {
  store.dispatch('ws/sendEvent', { event: event })
  base.value = []
  store.commit(`pie/${mutationTypes.SET_CUSTOM_SECTIONS_CONFIG}`, null)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

function exportSections() {
  utils.downloadObjectAsJson(base.value, 'eyediagSections')
}

function setIdToSection(sections) {
  const loopSections = (srcSections, id) => {
    for (const [index, section] of srcSections.entries()) {
      section.id = id === '' ? `${index + 1}` : `${id}-${index + 1}`
      loopSections(section.children, section.id)
    }
  }

  loopSections(sections, '')
}

defineExpose({
  movePanel: movePanel
})
</script>