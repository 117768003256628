<template>
  <div>
    <GenericModal
      ref="modal"
      :title="title"
      :description="description"
      :buttons="buttons"
      theme="success"
      @close="validate"
    >
      <EyeFlowTips />
    </GenericModal>
  </div>
</template>

<script>
export default {
  name: 'TipsContainer'
}
</script>

<script setup>
import GenericModal from '@/components/modal/GenericModal.vue'
import EyeFlowTips from '@/components/hud/tips/EyeFlow.vue'
import { useStore } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import { ref, onMounted, computed } from 'vue'
// import utils from '@/libraries/utils.js'

const store = useStore()

const title = 'EyeFlow'
const description = ''
const buttons = [
  {
    label: 'Ne plus afficher',
    backgroundColorTheme: 'warning',
    color: 'white',
    onClick: noLongerDisplay
  },
  {
    label: 'Valider',
    backgroundColorTheme: 'success',
    color: 'white',
    onClick: validate
  }
]

const modal = ref(null)

const noLongerDisplayTips = computed(() => store.getters['tips/noLongerDisplay'])
const idDisplayedTuto = computed(() => store.getters['tuto/idDisplayedTuto'])

onMounted(() => {
  if (idDisplayedTuto.value === null && noLongerDisplayTips.value === false && modal.value.$el.open === false) {
    modal.value.$el.showModal()
  }
})

function validate() {
  modal.value.$el.close()
}

function noLongerDisplay() {
  store.commit(`tips/${mutationTypes.SET_TIPS_NO_LONGER_DISPLAY}`, true)
  validate()
}
</script>