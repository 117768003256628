/**
 * Cet objet de configuration contient les temps que mettent les différentes transitions de l'application
 * @type {Object<EyeEnumD3Animation>}
 */
export default {
  'CIRCLES_CHANGE_COLOR_THEME': 2000,
  'CIRCLES_MOVE_CIRCLES': 2000,
  'CONTEXT_MENU_LEAVE_PREVIOUS_HOVERED_ITEM': 200,
  'CONTEXT_MENU_LEAVE_PREVIOUS_HOVERED_ICON': 200,
  'CONTEXT_MENU_HOVERED_ITEM': 200,
  'CONTEXT_MENU_HOVERED_ICON': 200,
  'CONTEXT_MENU_DRAW_MENU': 400,
  'LABEL_APPEAR': 1000,
  'LABEL_DISAPPEAR': 1000,
  'BUTTON_LIST_SELECTED_EVENT_MOVE': 500,
  'LIST_CHOOSE_TYPE_EVENTS_DISPLAY': 500,
  'LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY': 500,
  'BORDER_LIST_DISPLAY': 500,
  'LIST_ADD_EVENTS': 500,
  'BORDER_LIST_ARROW': 1000,
  'PIE_CIRCLE_ORIGIN_APPEAR': 200,
  'PIE_CIRCLE_ORIGIN_DISAPPEAR': 200,
  'PIE_MOUSE_OVER': 600,
  'PIE_MOUSE_LEAVE': 300,
  'PIE_MOUSE_CLICK': 200,
  'PIE_CIRCLE_ORIGIN_GROW': 490,
  'PIE_CIRCLE_ORIGIN_SHRINK': 300,
  'CIRCLE_NAVIGATION_RESET_POSITION': 200,
  'CIRCLE_NAVIGATION_HOVER': 400,
  'CIRCLE_NAVIGATION_LEAVE': 400,
  'SCORE_TOOLTIP_HEADER_APPEAR': 2000,
  'SCORE_TOOLTIP_BODY_APPEAR': 1000,
  'SECTION_LABEL_APPEAR': 500,
  'SECTION_LABEL_DISAPPEAR': 500,
  'TEMPORALITY_MENU_MOVE_SELECTION': 50,
  'EVENT_HIDE_CIRCLE': 200,
  'EVENT_APPEAR_CIRCLE': 200,
  'EVENT_APPEAR_CIRCLE_SIMILAR': 300,
  'EVENT_GROW_CIRCLE': 200,
  'EVENT_SHRINK_CIRCLE': 200,
  'EVENT_APPEAR_TRANSPLANT_PARENTHESIS': 500,
  'LINES_REPORT_APPEAR': 500,
  'LINES_REPORT_DISAPPEAR': 200,
  'REFERENCE_CIRCLE_HOVER': 400,
  'REFERENCE_CIRCLE_LEAVE': 400,
  'REFERENCE_CIRCLE_EVENTS_DISAPPEAR': 300,
  'SCORE_CIRCLE_APPEAR': 300,
  'SCORE_CIRCLE_DISAPPEAR': 300,
  'FAMILY_HISTORY_CIRCLE_APPEAR': 300,
  'FAMILY_HISTORY_CIRCLE_DISAPPEAR': 300,
  'ASSOCIATED_EVENTS_CIRCLE_APPEAR': 300,
  'ASSOCIATED_EVENTS_CIRCLE_DISAPPEAR': 300,
  'TOOLTIP_EXIT_BUTTON_BORDER_COLOR_APPEAR': 200,
  'TOOLTIP_EXIT_BUTTON_BORDER_COLOR_DISAPPEAR': 500,
  'TOOLTIP_EXIT_BUTTON_CROSS_APPEAR': 200,
  'TOOLTIP_EXIT_BUTTON_CROSS_DISAPPEAR': 500,
  'ANIMATION_CATEGORISATION_UNDISTORDED_CIRCLES': 1000,
  'ANIMATION_CATEGORISATION_DISTORDED_CIRCLES': 1000,
  'ANIMATION_INTRO_NAVIGATION_CIRCLE_SHRINK': 500,
  'ANIMATION_INTRO_NAVIGATION_CIRCLE_GROW': 500,
  'ANIMATION_INTRO_SMALLEST_CIRCLE_SHRINK': 500,
  'ANIMATION_INTRO_SMALLEST_CIRCLE_GROW': 500,
  'ANIMATION_INTRO_OTHERS_CIRCLES_GROW': 1500,
  'ANIMATION_LOADING_BLINK': 600,
  'ANIMATION_TEMPORALITY_CIRCLES_DISAPPEAR': 500,
  'ANIMATION_TEMPORALITY_CIRCLES_APPEAR': 500,
  'ANIMATION_TEMPORALITY_FROM_CIRCLE_CIRCLES_DISAPPEAR': 500,
  'ANIMATION_TEMPORALITY_FROM_CIRCLE_CIRCLES_APPEAR': 500,
  'ANIMATION_PADLOCK_ICON': 200,
  'EYEAPP_MOVE_REPRESENTATION': 500
}