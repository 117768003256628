<template>
  <div
    ref="roomManagerWrapper"
  >
    <div
      v-if="isInCollaboration && roomId"
      class="room-manager-content"
    >
      <div>
        <p>
          Session: <strong @click="copyId">{{ toPrint }}</strong>
        </p>
      </div>
      <div>
        <ul class="grid grid-cols-2">
          <li
            v-for="(participant, index) in roomParticipants"
            :key="index"
            class="flex items-center"
            @click="() => forceToBePresenter(participant)"
          >
            <div
              class="dot w-3 h-3 rounded-full m-2"
              :class="participant.isPresenter
                ? 'dot-color pulse'
                : inactiveUser.includes(participant.idSocket)
                  ? 'bg-current'
                  : 'bg-orange-500'
              "
            >
              &nbsp;
            </div>
            {{ `${participant.name} ${participant.familyName}` }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as d3 from "d3";

export default {
  name: "RomeManager",
  data: () => ({
    /**
     * Il s'agit du nom de la session collaborative
     * @type {String}
     */
    toPrint: "",
    inactiveUser: []
  }),
  computed: {
    ...mapGetters({
      isInCollaboration: "ws/isInCollaboration",
      roomId: "ws/roomId",
      wsSocket: "ws/wsSocket",
      roomParticipants: "ws/roomParticipants",
      userData: "user/userData"
    })
  },
  watch: {
    roomId() {
      this.toPrint = this.roomId
    },
    isInCollaboration() {
      if (this.isInCollaboration) {
        this.listenChangeParticipantVisibility()
      }
    }
  },
  mounted() {
    d3.select(this.$refs.roomManagerWrapper).on("contextmenu", (e) => {
      e.stopPropagation();
    });

    this.toPrint = this.roomId;

    if (this.isInCollaboration) {
      this.listenChangeParticipantVisibility()
    }
  },
  unmounted() {
    if (this.wsSocket !== null) {
      this.wsSocket.off('collaborative:changeTabVisibility', this.onChangeParticipantVisibility)
    }
  },
  methods: {
    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur l'id de la session collaborative, l'id est automatiquement copié dans son presse-papier
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit au listener
     */
    async copyId(event) {
      try {
        await navigator.clipboard.writeText(this.roomId);
        this.toPrint = "Copié !";
        setTimeout(() => {
          this.toPrint = this.roomId;
        }, 1000);
      } catch (e) {
        console.log(e);
      }
    },
    listenChangeParticipantVisibility() {
      if (this.wsSocket !== null) {
        this.wsSocket.on('collaborative:changeTabVisibility', this.onChangeParticipantVisibility)
      }
    },
    onChangeParticipantVisibility(data) {
      if (data.visible) {
        this.inactiveUser = this.inactiveUser.filter(id => id !== data.idSocket)
      } else {
        this.inactiveUser.push(data.idSocket)
      }
    },
    //@debug
    forceToBePresenter(participant) {
      const roomCreatorData = this.roomParticipants.find(participant => participant.isCreator)

      if (roomCreatorData.id === this.userData.sub) {
        this.wsSocket.emit('collaborative:changePresenter', {
          roomId: this.roomId,
          idSocket: participant.idSocket
        })
      }
    }
  },
}
</script>

<style scoped>
.room-manager-content {
  color: var(--color-text);
  font-size: 1.3rem;
  background: var(--color-bg-2);
  border: solid 1px var(--color-border);
  position: absolute;
  top: 3%;
  right: 5%;
  padding: 10px 15px;
  border-radius: 5px;
}
p > strong {
  color: var(--color-bold-1);
  border-color: var(--color-bold-2);
  border: 2px solid;
  padding: 5px;
  border-radius: 5px;
  min-width: 248.7px;
  display: inline-block;
  text-align: center;
}

.roomManager_joinRoom {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 15px 10px;
  background: var(--color-bold-2);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.roomManager_joinRoom > * {
  margin: 3%;
}
.dot {
  position: relative;
}
.dot-color {
  background: green;
}
.pulse.dot::after {
  content: "";
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background: green;
  border-radius: inherit;
  animation: pulseAnimation 3s ease-out infinite;
}

@keyframes pulseAnimation {
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
</style>