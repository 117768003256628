import * as mutationTypes from '@/store/mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import TypeRecording from '@/shared/enums/typeRecording.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const getDefaultState = () => {
  return {
    selectedTuto: null,
    selectedTutoPart: null,
    idDisplayedTuto: null,
    tutoParts: [],
    spaceToRecord: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    selectedTuto: state => state.selectedTuto,
    selectedTutoPart: state => state.selectedTutoPart,
    idDisplayedTuto: state => state.idDisplayedTuto,
    tutoParts: state => state.tutoParts,
    spaceToRecord: state => state.spaceToRecord
  },
  mutations: {
    [mutationTypes.SET_SELECTED_TUTO] (state, payload) {
      state.selectedTuto = payload
    },
    [mutationTypes.SET_SELECTED_TUTO_PART] (state, payload) {
      state.selectedTutoPart = payload
    },
    [mutationTypes.SET_ID_DISPLAYED_TUTO] (state, payload) {
      state.idDisplayedTuto = payload
    },
    [mutationTypes.SET_TUTO_PARTS] (state, payload) {
      state.tutoParts = payload
    },
    [mutationTypes.SET_SPACE_TO_RECORD] (state, payload) {
      state.spaceToRecord = payload
    }
  },
  actions: {
    async getTutoParts(context, params) {
      try {
        const urlParams = new URLSearchParams()
        urlParams.append('idTuto', context.state.idDisplayedTuto)
        let response = await EyeFetch(this, 
          `${process.env.VUE_APP_SERVER_BASE_URL}/tuto/parts/?${urlParams}`,
          {
            method: "GET",
            credentials: 'include'
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'tuto/getTutoParts', params: params})
          return 
        }
        response = await response.json()
        context.commit(mutationTypes.SET_TUTO_PARTS, response.tutoParts)
      } catch (err) {
        ErrorManager.fetchError(err)
      }
    },

    async updateTextTutoPart(context, params) {
      try {
        let response = await EyeFetch(this, 
          `${process.env.VUE_APP_SERVER_BASE_URL}/tuto/part/text/`,
          {
            method: "PUT",
            credentials: 'include',
            body: JSON.stringify({
              tutoPart: params.tutoPart,
              text: params.text
            })
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'tuto/updateTextTutoPart', params: params})
          return 
        }
      } catch (err) {
        ErrorManager.fetchError(err)
      }
    },

    launchTutoRecord(context, { idRecord }) {
      context.dispatch(`record/replay/setRecordingType`, { typeRecording: TypeRecording.TUTO }, { root: true })
      const recordPart = context.state.tutoParts.find(part => part.IdTutoPart === idRecord && part.IdRecord !== null)
      if (recordPart !== undefined) {
        context.dispatch('record/replay/getRecord', { IdRoom: recordPart.IdRecord }, { root: true })
      }
    },

    startRecordTutoRecord(context) {
      context.dispatch('record/record/startRecording', {
        typeRecording: TypeRecording.TUTO
      }, { root: true })
    },

    async startEditTutoPartRecord(context) {
      context.dispatch('record/replay/stopReplay', null, { root: true })
      context.commit(mutationTypes.SET_ID_DISPLAYED_TUTO, null)
      context.dispatch('startTutoPatient')
      await context.dispatch('circle/getDataRepresentation', {
        unitPerCircle: context.rootState.circle.unitPerCircle,
        periodUnit: context.rootState.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.TEMPORALITY,
      }, { root: true })

      const notification = {
        id: Date.now(),
        type: 'success',
        message: 'Appuyez sur espace pour démarrer l\'enregistrement du tuto. La touche echap permettra de stopper l\'enregistrement.',
        timeout: 10 * 1000 // 10 secs
      }
      context.commit(`notification/${mutationTypes.ADD_NOTIFICATION}`, notification, { root: true })
      context.commit(mutationTypes.SET_SPACE_TO_RECORD, true)
    },

    startTutoPatient(context) {
      //TMP inutile permet juste de bypass express validator, les variables ne sont pas utilisé par le back pour les tutos. Il faudrait faire un truc plus prore quand on aura du temps
      context.commit(`patient/${mutationTypes.SET_FOLDER_ID}`, 1, { root: true })
      context.commit(`patient/${mutationTypes.SET_ID_PATIENT}`, 5, { root: true })
      //END TMP

      context.dispatch('enableTuto')
    },

    enableTuto(context) {
      const mode = context.rootState.mode
      mode.options.tuto = true
      context.commit(mutationTypes.SET_EYEDIAG_MODE, mode, { root: true })
    },
    
    disableTuto(context) {
      const mode = context.rootState.mode
      delete mode.options.tuto
      context.commit(mutationTypes.SET_EYEDIAG_MODE, mode, { root: true })
    }
  }
}