<template>
  <div>
    <img
      src="../assets/tronc_1.png"
    >
  </div>
</template>

<script>
export default {
  name: 'DecouverteTutoContent2'
}
</script>