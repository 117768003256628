import * as d3 from 'd3'
import periodUnits from '@/shared/enums/period_units'
import userRole from '@/shared/enums/userRole';
import store from '@/store/index.js'

export function fillYearWithZero(data) {
  const minYear = d3.min(data, d => d.year);
  const maxYear = d3.max(data, d => d.year);
  
  const completeYears = d3.range(minYear, maxYear + 1);
  return completeYears.map(year => {
    const existingYear = data.find(d => d.year === year);
    return existingYear ? existingYear : { year: year, count: 0 };
  });
}

function fillSemesterWithZero(data) {
  const minYear = d3.min(data, d => new Date(d.date).getFullYear());
  const maxYear = d3.max(data, d => new Date(d.date).getFullYear());
  const completeSemesters = [];

  for (let year = minYear; year <= maxYear; year++) {
    completeSemesters.push({ date: `${year}-01-01` });  
    completeSemesters.push({ date: `${year}-07-01` });
  }

  return completeSemesters.map(({ date }) => {
    const existingSemester = data.find(d => d.date === date);
    return existingSemester ? existingSemester : { date: date, count: 0 };
  });
}

function fillQuarterWithZero(data) {
  const quarters = [
    "Q1", 
    "Q2",
    "Q3", 
    "Q4",
  ];
  
  const years = d3.extent(data, d => new Date(d.date).getFullYear());
  const completeData = [];

  for (let year = years[0]; year <= years[1]; year++) {
    quarters.forEach(q => {
      const existingQuarter = data.find(d => d.date === `${year}-${q.charAt(1)}-01`);
      completeData.push(existingQuarter ? existingQuarter : { date: `${year}-${q.charAt(1)}-01`, count: 0 });
    });
  }
  return completeData;
}

export function draw_svg_CountDate(svg, width, height, coord, periodUnit = periodUnits.YEAR) {
  coord.forEach(d => {
    d.xVal = new Date(d.xVal);
  });
  
  let x = d3.scaleTime()
    .domain([d3.min(coord, d => d.xVal), d3.max(coord, d => d.xVal)])
    .range([0, width]);
    
  const y = d3.scaleLinear()
    .domain([0, d3.max(coord, d => d.yVal)])
    .nice()
    .range([height, 0]);
  
  let xAxis = d3.axisBottom(x)
    .tickFormat(d3.format("d"))

  if (periodUnit == periodUnits.SEMESTER) {
    xAxis = d3.axisBottom(x)
      .tickFormat(d => {
        const year = d.getFullYear();
        const month = d.getMonth();
        const semester = (month < 6) ? 'S1' : 'S2';
        return `${year}-${semester}`;
      });
  }
  if (periodUnit == periodUnits.QUARTER) {
    xAxis = d3.axisBottom(x)
      .tickFormat(d => {
        const date = new Date(d); // Convert to Date object
        const year = date.getFullYear();
        const month = date.getMonth();
        
        let quarter;
        if (month < 3) {
          quarter = 'Q1';
        } else if (month < 6) {
          quarter = 'Q2';
        } else if (month < 9) {
          quarter = 'Q3';
        } else {
          quarter = 'Q4';
        }
        
        return `${year}-${quarter}`;
      });
  }

  const yAxis = d3.axisLeft(y)
    .ticks(y.domain()[1]) 
    .tickFormat(d3.format("d")); 

  svg.append("g")
    .attr("transform", `translate(0,${height})`)
    .call(xAxis)
    .style(
      "color","var(--color-text)",
    )
    .selectAll("text")  // Select all text labels on the x-axis
    .style("text-anchor", "end")  // Align text at the end for better readability
    .attr("dx", "-0.8em")  // Horizontal displacement
    .attr("dy", "0.15em")  // Vertical displacement
    .attr("transform", "rotate(-30)")  // Rotate the text by -n degrees
    .style(
      "color","var(--color-text)",
    )
  svg.append("g")
    .call(yAxis)
    .style(
      "color","var(--color-text)",
    )

  const line = d3.line()
    .x(d => x(d.xVal))
    .y(d => y(d.yVal))
    
  svg.append("path")
    .datum(coord) 
    .attr("class", "line")
    .attr("d", line)
    .attr("fill", "none")
    .attr("stroke", "steelblue")
    .attr("stroke-width", 2);
  
  svg.selectAll("circle")
    .data(coord)  
    .enter()
    .append("circle")
    .attr("cx", d => x(d.xVal))
    .attr("cy", d => y(d.yVal))
    .attr("r", 4)
    .attr("fill", "steelblue");
  
  try {
    if (periodUnit == periodUnits.YEAR) // car mouseover fait seulement sur les années.
    {
      svg = mouseover_y(svg, "xVal", "yVal", coord, height, width, x, y)
    }
  }
  catch (err) {
    console.error(err)
  }
  
  return svg
}

export function set_shape(containerHeight, thisChart){
  const margin = { top: 20, right: 30, bottom: 50, left: 30 };
  const width = thisChart.clientWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;
  let svg = d3.select(thisChart)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  return { width, height, svg };
}

export function mouseover_y(svg, nom_x, nom_y, data, height, width, x, y) {
  const mouse_g = svg.append('g').classed('mouse', true).style('display', 'none');
  mouse_g.append('rect')
    .attr('width', 2)
    .attr('x', -1)
    .attr('height', height)
    .attr('fill', 'lightgray');
  mouse_g.append('circle')
    .attr('r', 3)
    .attr("stroke", "steelblue");
  mouse_g.append('text')
    .style(
      "fill","var(--color-text)",
    )

  svg.on("mouseover", function (event) {
    store.dispatch('ws/sendEvent', { event: event })
    mouse_g.style('display', 'block');
    store.dispatch('ws/collaborativeEventTreated')
  });

  data.forEach(d => {
    if (d[nom_x] instanceof Date) {
      d[nom_x] = d[nom_x].getTime()
    }
  });
  
  let [min_year, max_year] = d3.extent(data, d => d[nom_x]);
  svg.on("mousemove", function (mouse) {
    const [x_cord, y_cord] = d3.pointer(mouse);
    const ratio = x_cord / width;
    // const padding = 5;
    const current_year = min_year + Math.round(ratio * (max_year - min_year));      
    const datafound = data.find(d => d[nom_x] == current_year)
    
    const y_val_displayed = datafound && Object.hasOwn(datafound, nom_y) ? datafound[nom_y] : 0 
    mouse_g.attr('transform', `translate(${x(current_year)},${0})`);
    mouse_g.select('text')
      .text(nom_y == "yVal"
        ? y_val_displayed
        : `${(y_val_displayed * 100).toFixed(2)}% `
      )
      .attr('text-anchor', current_year < (min_year + max_year) / 2 ? "start" : "end")
      // .attr('x', current_year < (min_year + max_year) / 2 ? padding : -padding)  // Add padding
    mouse_g.select('circle').attr('cy', y(y_val_displayed));
    
    // svg.on("mouseout", function(mouse) {
    //   mouse_g.style('display', 'none');
    // });
  })
  return svg
}

function calculateKaplanMeier(data, seuil, selectedCondition) {
  function conditionRisk(selectedCondition, value, seuil) {
    if (selectedCondition == 1) {
      return value >= seuil
    }
    return value <= seuil
  }

  function processData(data) {
    const processedData = [];
    const groupedById = d3.group(data, d => d.id);
    groupedById.forEach((records, id) => {
      let noLongerAtRisk = false;
      records.sort((a, b) => a.year - b.year);
      records.forEach(record => {
        if (noLongerAtRisk) {
          return;
        }
  
        if (conditionRisk(selectedCondition, record.value, seuil) ) {
          noLongerAtRisk = true;
          processedData.push({ id: record.id, year: record.year, event: 1 }); 
        } else {
          processedData.push({ id: record.id, year: record.year, event: 0 });
        }
      });
    });
  
    return processedData;
  }
  
  data = processData(data);

  let kaplanMeierData = [];
  let survival = 1;
  let atRiskCount = data.length;
  data.sort((a, b) => a.year - b.year);

  data.forEach((d, i) => {
    const eventsOccurred = d.event;  
    if (eventsOccurred === 1) {
      survival *= (1 - (1 / atRiskCount));
      atRiskCount--;  
    } else {
      atRiskCount--; 
    }

    kaplanMeierData.push({ year: d.year, survival });
  });
  return kaplanMeierData;
}

function labelQOR(d, maxYear, patientType) {
  if (patientType == userRole.QOR) {    
    return {
      [maxYear]: "M1",
      [maxYear-1]: "S2",
      [maxYear-2]: "J3",
      [maxYear-3]: "J1",
      [maxYear-4]: "0"
    }[d]
  }
  return d
}

function drawKaplanMeierChart(svg, width, height, data, patientType) {
  const maxYear = d3.max(data, d => d.year);

  const x = d3.scaleLinear()
    .domain(d3.extent(data, d => d.year))
    .range([0, width])

  const y = d3.scaleLinear()
    .domain([0, 1]) 
    .range([height, 0]);
    
  const xAxis = d3.axisBottom(x).tickFormat(function(d) {
    return labelQOR(d, maxYear, patientType);
  })

  const yAxis = d3.axisLeft(y)
    .ticks(5) 
    .tickFormat(d3.format(".0%")); 
  
  svg.append("g")
    .attr("transform", `translate(0,${height})`)
    .call(xAxis)
  
  svg.append("g")
    .call(yAxis);

  const line = d3.line()
    .x(d => x(d.year))
    .y(d => y(d.survival));

  svg.append("path")
    .datum(data)
    .attr("class", "line")
    .attr("d", line)
    .attr("fill", "none")
    .attr("stroke", "steelblue")
    .attr("stroke-width", 2);
  
  try {
    svg = mouseover_y(svg, "year", "survival", data, height, width, x, y)
  }
  catch (err) {
    console.error(err)
  }
  
  return svg
}

export function countParTemps(data, thisChart, periodUnit) {
  const { width, height, svg } = set_shape(200, thisChart);

  if (periodUnit != periodUnits.SEMESTER) {
    data.forEach(d => {
      d.year = new Date(d.onsetDateTime).getFullYear();
    });
    const yearCounts = fillYearWithZero(d3.rollups(
      data,
      v => v.length,
      d => d.year
    ).map(d => ({ year: d[0], count: d[1] })));
    yearCounts.sort((a, b) => a.year - b.year);
    return draw_svg_CountDate(svg, width, height, yearCounts.map(({year, count}) => ({xVal: year, yVal:count})))
  }
  if (periodUnit == periodUnits.SEMESTER) {
    data.forEach(d => {
      const date = new Date(d.onsetDateTime);
      const year = date.getFullYear();
      d.date = date.getMonth() < 6 ? `${year}-01-01` : `${year}-07-01`;
    });
    
    const semesterCounts = fillSemesterWithZero(d3.rollups(
      data,
      v => v.length,
      d => d.date
    ).map(d => ({ date: d[0], count: d[1] })));
    
    return draw_svg_CountDate(svg, width, height, semesterCounts.map(({date, count}) => ({xVal: date, yVal:count})), periodUnit)
  }

  // if (periodUnit == periodUnits.QUARTER) {
  //   data.forEach(d => {
  //     const date = new Date(d.onsetDateTime);
  //     const year = date.getFullYear();
  //     const month = date.getMonth();
  //     if (month < 3) {
  //       d.date = `${year}-01-01`;
  //     } else if (month < 6) {
  //       d.date = `${year}-04-01`;
  //     } else if (month < 9) {
  //       d.date = `${year}-07-01`;
  //     } else {
  //       d.date = `${year}-10-01`; 
  //     }
  //   });https://scontent.fhio2-2.fna.fbcdn.net/v/t1.6435-9/131377310_3658986510829994_6207959347712120610_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=689c2a&_nc_ohc=7wlXkCnUqsoQ7kNvgFhyjl-&_nc_ht=scontent.fhio2-2.fna&_nc_gid=AqS84MXdpHaUdrzozAgjE92&oh=00_AYC5HekRWllp-zRUOZdooNm_zKInLYzf8y01jcjGrjq5wQ&oe=6732A388
    
  //   const quarterCounts = fillQuarterWithZero(
  //     d3.rollups(
  //       data,
  //       v => v.length,
  //       d => d.date
  //     ).map(d => ({ date: d[0], count: d[1] }))
  //   );
  //   return draw_svg_CountDate(svg, width, height, quarterCounts.map(({date, count}) => ({xVal: date, yVal:count})), periodUnit)

  // }
}

export function survivabilite(data, thisChart, seuil, selectedCondition, patientType) {  
  const { width, height, svg } = set_shape(200, thisChart);
  data.forEach(d => {
    d.year = new Date(d.onsetDateTime).getFullYear();
  });

  const kaplanMeierData = calculateKaplanMeier(data, seuil, selectedCondition);
  return drawKaplanMeierChart(svg, width, height, kaplanMeierData, patientType)
}

function draw_moustache_sev(svg, width, height, data, patientType, dimension) {
  // Compute summary statistics (min, max, median, Q1, Q3)
  function computeSummaryStatistics(values) {    
    values.sort(d3.ascending);

    var q1 = d3.quantile(values, 0.25);
    var median = d3.quantile(values, 0.5);
    var q3 = d3.quantile(values, 0.75);
    var min = d3.min(values);
    var max = d3.max(values);
    
    return {q1, median, q3, min, max};
  }

  // Draw semi-transparent lines from the y-axis to the boxplot
  function showExtendedLines(stats, center) {
    svg.selectAll(".extendedLine")
      .data(stats)
      .enter()
      .append("line")
      .attr("class", "extendedLine")  // Give a class for easier selection and removal
      .attr("x1", 0)  // Start at the y-axis
      .attr("x2", center - 30 / 2)  // Up to the left edge of the boxplot
      .attr("y1", d => y(d))  // Same y position as the original lines
      .attr("y2", d => y(d))
      .attr("stroke", "var(--color-text)")
      .style("stroke-dasharray", ("3, 3"))  // Dashed line
      .style("opacity", 0.5);  // Semi-transparent
  }
  
  function isWithinMargin(value1, value2, margin = 0.05) {
    const diff = Math.abs(value1 - value2);
    const avg = (value1 + value2) / 2;
    return (diff / avg) <= margin;
  }
  
  var dataGroupedByDim = d3.group(data, d => {    
    return d[dimension]
  });
  const maxX = d3.max(data, d => d[dimension]);
  
  // X scale: years
  var x = d3.scaleBand()
    .domain(Array.from(dataGroupedByDim.keys()))
    .range([0, width])
    .paddingInner(1)
    .paddingOuter(.5);

  const xAxis = d3.axisBottom(x)
    .tickFormat(function (d) {
      return labelQOR(d, maxX, patientType);
    })
  
  // Y scale: val values
  var y = d3.scaleLinear()
    .domain([0, d3.max(data, d => d.val)]) // Adjust the domain based on your data
    .range([height, 0]);
  
  // Add X axis
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)
    .style(
      "color","var(--color-text)",
    )

  // Add Y axis
  svg.append("g")
    .call(d3.axisLeft(y))
    .style("color","var(--color-text)")  

  // Draw the boxplot for each year
  dataGroupedByDim.forEach((values, dim) => {
    var center = x(dim); // X position for this dim's boxplot
    var width = 30; // Width of the box

    // Extract val values and compute statistics
    var valValues = values.map(d => d.val);
    
    var { q1, median, q3, min, max } = computeSummaryStatistics(valValues);
    
    // Show the main vertical line (min to max)
    svg.append("line")
      .attr("x1", center)
      .attr("x2", center)
      .attr("y1", y(min))
      .attr("y2", y(max))
      .attr("stroke", "var(--color-text)");
    
    // Show the box (from Q1 to Q3)
    svg.append("rect")
      .attr("x", center - width / 2)
      .attr("y", y(q3))
      .attr("height", y(q1) - y(q3)) 
      .attr("width", width)
      .attr("stroke", "var(--color-text)")
      .style("fill", "#69b3a2")
      .on("mouseover", function(event) {
        store.dispatch('ws/sendEvent', { event: event })
        showExtendedLines([min, median, max, q1, q3], center);
        store.dispatch('ws/collaborativeEventTreated')
      })
      .on("mouseout", function(event) {
        store.dispatch('ws/sendEvent', { event: event })
        svg.selectAll(".extendedLine").remove();
        store.dispatch('ws/collaborativeEventTreated')
      });
  

    // Show median, min, and max horizontal lines
    svg.selectAll("toto")
      .data([min, median, max])
      .enter()
      .append("line")
      .attr("x1", center - width / 2)
      .attr("x2", center + width / 2)
      .attr("y1", d => y(d))
      .attr("y2", d => y(d))
      .attr("stroke", "var(--color-text)")
      .style("stroke-width", d => {
        if (d === median && (isWithinMargin(median, min) || isWithinMargin(median, max))) {
          return 3;
        } else {
          return 1;
        }
      })

  });
  return svg

}

export function moustaches_sev(data, thisChart, patientType = userRole.STANDARD, dimension= "year") {
  const { width, height, svg } = set_shape(200, thisChart);  
  if (dimension == "year") {
    data.forEach(d => {
      d.year = new Date(d.onsetDateTime).getFullYear();
    })
  }
  // else if (dimension == "Section") {
  // }

  return draw_moustache_sev(svg, width, height, data, patientType, dimension)

}