<template>
  <ListBase
    id="legend-list"
    ref="listBaseRef"
    :title="title"
  >
    <template #content>
      <component
        :is="currentContent"
        v-if="currentContent !== null"
        @title="onChangeTitle"
      />
    </template>
  </ListBase>
</template>

<script setup>
import { ref, computed, watch, markRaw } from 'vue'
import { useStore } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'

import ContentCircle from '@/components/borderLists/ListLegend/content/Circle.vue'
import ContentEvent from '@/components/borderLists/ListLegend/content/Event.vue'
import ContentPie from '@/components/borderLists/ListLegend/content/Pie.vue'
import ContentRepCircle from '@/components/borderLists/ListLegend/content/RepCircle.vue'
import ContentParcours from '@/components/borderLists/ListLegend/content/Parcours.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'

import LegendContent from '@/enums/borderList/legendContent.js'

const store = useStore()
const currentContent = ref(null)
const listBaseRef = ref(null)
const title = ref('Légende')

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])
const hoveredCircle = computed(() => store.getters['circle/hoveredCircle'])
const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])
const isHoveredRepCircle = computed(() => store.getters['circle/isHoveredRepCircle'])
const isLegendShow = computed(() => store.getters['borderList/currentDisplayedBorderList'] === borderListTypes.LIST_LEGEND)
const hoveredParcours = computed(() => store.getters['event/parcours/hoveredParcours']) 

const lockedBy = computed(() => store.getters['borderList/legend/lockedBy'])

watch(isLegendShow, () => {
  if (isLegendShow.value === false) {
    currentContent.value = null
  }
})

watch(hoveredEvent, () => {
  if (canChangeLegendContent(LegendContent.HOVERED_EVENT) === true && hoveredEvent.value !== null) {
    currentContent.value = markRaw(ContentEvent)
  }
})

watch(hoveredCircle, () => {
  if (canChangeLegendContent(LegendContent.HOVERED_CIRCLE) === true && hoveredCircle.value !== null && hoveredEvent.value === null) {
    currentContent.value = markRaw(ContentCircle)
  }
})

watch(hoveredParcours, () => {
  if (canChangeLegendContent(LegendContent.HOVERED_PARCOURS) === true && hoveredParcours.value !== null) {
    currentContent.value = markRaw(ContentParcours)
  }
})

watch(pieHoveredSections, () => {
  if (canChangeLegendContent(LegendContent.PIE_HOVERED_SECTIONS) === true && pieHoveredSections.value.length > 0) {
    currentContent.value = markRaw(ContentPie)
  }
})

watch(isHoveredRepCircle, () => {
  if (canChangeLegendContent(LegendContent.HOVERED_REP_CIRCLE) === true && isHoveredRepCircle.value === true) {
    currentContent.value = markRaw(ContentRepCircle)
  }
})

function canChangeLegendContent(contentConfig) {
  if (lockedBy.value !== null) {
    return false
  }
  if (isLegendShow.value === true) {
    return true
  } else if (contentConfig.autoOpenBorderList === true) {
    store.dispatch('event/common/controlEvent',{isEnabled: true, typeEventslist: borderListTypes.LIST_LEGEND})
    return true
  }
  return false
}

function movePanel(...params) {
  listBaseRef.value.movePanel(...params)
}

function onChangeTitle(newTitle) {
  title.value = newTitle
}

defineExpose({
  movePanel: movePanel
})

</script>