<template>
  <dialog
    ref="genericModal"
    class="generic-modal"
  >
    <div
      class="generic-modal-header"
      :style="`background-color: ${themeColor(theme)}`"
    >
      <div class="generic-modal-title-wrapper">
        <p class="generic-modal-title">
          {{ title }}
        </p>
      </div>
      <div>
        <button
          class="generic-modal-close"
          @click="(e) => $emit('close', e)"
        >
          &#9747;
        </button>
      </div>
    </div>
    <div
      class="generic-modal-body"
    >
      <p
        v-if="description.length > 0"
        class="generic-modal-body-content"
      >
        {{ description }}
      </p>
      <slot />
      <div
        v-if="buttons.length > 0"
        class="generic-modal-body-button-wrapper"
      >
        <button
          v-for="(button, index) in buttons"
          :key="index"
          :style="`color: ${button.color}; background-color: ${themeColor(button.backgroundColorTheme)}`"
          @click="button.onClick"
        >
          {{ button.label }}
        </button>
      </div>
    </div>
  </dialog>
</template>

<script>
export default {
  props: {
    /**
     * Titre de la modal
     * @type {String}
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Decription de la modal
     * @type {String}
     */
    description: {
      type: String,
      required: true
    },
    /**
     * Tableau décrivant les boutons présents sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: {
      type: Array,
      required: true
    },
    /**
     * Thème appliqué à la modal
     * @type {String}
     */
    theme: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  methods: {
    /**
     * Cette fonction permet l'affichage de la modal
     * @method
     * @public
     */
    showModal() {
      this.$refs["genericModal"].show()
    },
    /**
     * Cette fonction permet la fermeture de la modal
     * @method
     * @public
     */
    close() {
      this.$refs["genericModal"].close()
    },
    /**
     * Cette fonction permet de retourner la couleur associée à un theme
     * @method
     * @public
     */
    themeColor(theme) {
      const colorTheme = {
        success: 'var(--c-green-1)',
        warning: 'var(--c-orange)',
        error: 'var(--c-pure-red)'
      }

      if (colorTheme[theme]) {
        return colorTheme[theme]
      } else {
        return colorTheme.success
      }
    }
  }
};
</script>

<style scoped>
.generic-modal-header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.generic-modal-title {
  color: white;
  font-size: 1.6rem;
}

.generic-modal-title-wrapper {
  display: flex;
  align-items: center;
}

.generic-modal {
  color: var(--color-text);
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-1);
  border-width: 0px;
  border-radius: 5px;
  max-width: 700px;
  width: 50vw;
  resize: both;
  overflow: auto;
  padding: 0px;
}

.generic-modal-close {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
}

.generic-modal-body-button-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5px;
}

.generic-modal-body-button-wrapper button {
  border-radius: 5px;
  padding: 5px;
  flex: 1;
  border: none;
}

.generic-modal-body {
  margin-top: 10px;
  margin-inline: 10px;
  font-size: 11px;
}

.generic-modal button:hover {
  background: rgb(86, 97, 109);
  transition-duration: 500ms;
}
.generic-modal button:nth-child(1) {
  margin-right: 2px;
}
.generic-modal button:nth-child(2) {
  margin-left: 2px;
}
.generic-modal-body-content {
  padding-bottom: 20px;
  font-size: 1.4rem;
}
.button_wrapper {
  display: flex;
}
</style>