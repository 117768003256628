<template>
  <div>
    <div v-if="options.combinations.length > 0">
      <p
        class="mb-2"
      >
        Entrées:
      </p>
      <ul>
        <li
          v-for="(combination, index) in options.combinations"
          :key="index"
          @click="(e) => onDeleteCombination(e, combination)"
        >
          {{ combination }}
        </li>
      </ul>
    </div>
    <div class="mt-4">
      <label>
        Combiner par
        <select
          v-model="tmp"
          @change="addCombinationItem"
        >
          <option
            v-for="(property, idx) in userEventProperties"
            :key="idx"
            :value="property.name"
          >
            {{ property.name }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigSectionCombination'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const tmp = ref(null)

const userEventProperties = computed(() => store.getters['event/common/userEventProperties'])

const options = defineModel({
  type: Object,
  required: true
})

options.value.combinations = []

function addCombinationItem(event) {
  CollaborativeUtils.onInput(store, event, tmp.value, true, () => {
    if (options.value.combinations.includes(tmp.value)) {
      options.value.combinations = options.value.combinations.filter(c => c !== tmp.value)
    } else {
      options.value.combinations.push(tmp.value)
    }
  })
}

function onDeleteCombination(event, combination) {
  store.dispatch('ws/sendEvent', { event: event })
  options.value.combinations = options.value.combinations.filter(c => c !== combination)
  store.dispatch('ws/collaborativeEventTreated')
}

</script>

<style scoped>
label {
  @apply flex flex-col;
}

label > * {
  @apply mt-2;
}
</style>