import * as d3 from 'd3'
import CircleUtility from '@/libraries/CircleUtility.js'
import _ from 'lodash'
import linesTypes from '@/enums/lines_types.js'
import circlesConfig from '@/config/circle.js'

class PathManager {
  constructor(selection, referenceRadius, lineFunc, betweenCircle) {
    this.betweenCircle = betweenCircle
    this.lineFunc = lineFunc
    this.selection = selection
    this.referenceRadius = referenceRadius
    this.paths = []
    this.symbols = []
    this.resourceType = selection.resourceType
    this.resourceTypeClass = selection.resourceTypeClass
  }

  generateInternalProgression() {
    if (this.selection.internalProgression) {
      let radius = CircleUtility.getRadiusCircle(this.referenceRadius, 1)
      let point = d3.pointRadial(this.selection.angle, radius)
      
      if (this.selection.events.length > 0) {
        const points = {
          point1: {x: point[0], y: point[1]},
          point2: {x: this.selection.events[0].cx, y: this.selection.events[0].cy}
        }

        this.paths.push({
          path: this.lineFunc([points.point1, points.point2]),
          type: linesTypes.DASH,
          resourceType: this.resourceType,
          resourceTypeClass: this.resourceTypeClass
        })
      }

      this.symbols.push({
        path: d3.symbol().type(d3.symbolPlus).size(50)(),
        x: point[0],
        y: point[1],
        resourceType: this.resourceType,
        resourceTypeClass: this.resourceTypeClass
      })
    }
  }

  generateExternalProgression() {
    if (this.selection.externalProgression) {
      const radius = CircleUtility.getRadiusCircle(this.referenceRadius, circlesConfig.length + 2)
      const point = d3.pointRadial(this.selection.angle, radius)

      if (this.selection.events.length > 0) {
        const points = {
          point1: {x: _.last(this.selection.events).cx, y: _.last(this.selection.events).cy},
          point2: {x: point[0], y: point[1]},
        }
        this.paths.push({
          path: this.lineFunc([points.point1, points.point2]),
          type: linesTypes.DASH,
          resourceType: this.resourceType,
          resourceTypeClass: this.resourceTypeClass
        })
      } else {
        this.symbols.push({
          path: d3.symbol().type(d3.symbolPlus).size(50)(),
          x: point[0],
          y: point[1],
          resourceType: this.resourceType,
          resourceTypeClass: this.resourceTypeClass
        })
      }
    }
  }

  betweenEvents() {
    for (let i = 0; i < this.selection.events.length - 1; i++) {
      const points = {
        point1: {x: this.selection.events[i].cx, y: this.selection.events[i].cy},
        point2: {x: this.selection.events[i + 1].cx, y: this.selection.events[i + 1].cy},
      }

      if (i === this.selection.events.length - 2 && this.selection.externalProgression === false) {
        const pointsOnCircle = this.betweenCircle(this.selection.events[i], this.selection.events[i + 1])
        points.point2 = pointsOnCircle.point2
      }

      const diffId = Math.abs(this.selection.events[i].circleParent.id - this.selection.events[i + 1].circleParent.id)
      this.paths.push({
        path: this.lineFunc([points.point1, points.point2]),
        type: (diffId > 1) ? linesTypes.DASH : linesTypes.SOLID,
        resourceType: this.resourceType,
        resourceTypeClass: this.resourceTypeClass
      })
    }
  }

  generateFilteringPaths() {
    this.generateInternalProgression()
    this.betweenEvents()
    this.generateExternalProgression()
  }
}

export default PathManager