<template>
  <div>
    <Transition name="overlay">
      <div
        v-if="display"
        class="box"
      >
        <div
          :class="`utililtyButtons ${isDisplayedNavButton ? 'justify-between' : 'justify-end'}`"
        >
          <div>
            <span
              v-if="isDisplayedNavButton"
              class="back-button"
              @click="changeComponent(-1)"
            >
              ←
            </span>
            <span
              v-if="canEditTuto && withRecord"
              @click="onEditRecord"
            >
              Editer l'enregistrement
            </span>
            <span
              v-if="canEditTuto && editableText"
              @click="onEditText"
            >
              Editer Texte
            </span>
          </div>
          <span 
            style="cursor: pointer; color: grey"
            @click="closeTutorial" 
          >
            &#x2715;
          </span>
        </div>
        <div class="overlay-content">
          <slot />
        </div>
        <div class="footer">
          <span style="color: grey">{{ currentIndex + 1 }} / {{ nbComponents }}</span>
          <div class="w-32">
            <DesignButton
              v-if="isDisplayedNavButton"
              class="p-1"
              text="Suivant"
              @click="changeComponent(1)"
            />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'TutorialOverlay'
}
</script>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import DesignButton from '@/components/DesignButton.vue'
import AppContextChecker from '@/libraries/AppContextChecker.js'

const store = useStore()

const props = defineProps({
  nbComponents: {
    type: Number,
    required: true
  },
  timeout: {
    type: Number,
    required: false,
    default: 0
  },
  display: {
    type: Boolean,
    required: false,
    default: true
  },
  startIndex: {
    type: Number,
    required: false,
    default: 0
  },
  withRecord: {
    type: Boolean,
    required: false,
    default: false
  },
  editableText: {
    type: Boolean,
    required: false,
    default: false
  }
})

const currentIndex = ref(props.startIndex)

const isDisplayedNavButton = computed(() => props.timeout === 0)
const canEditTuto = computed(() => AppContextChecker.canEditTuto(store))

watch(() => props.startIndex, () => {
  currentIndex.value = props.startIndex
})

watch(() => props.timeout, () => {
  if (props.timeout > 0) {
    setTimeout(() => {
      changeComponent(1)
    }, props.timeout)
  }
})

const emit = defineEmits(['changeComponent', 'exit', 'editText'])

function changeComponent(itt) {
  currentIndex.value += itt

  if (currentIndex.value < 0) {
    currentIndex.value = 0
  } else if (currentIndex.value >= props.nbComponents) {
    exitTutorial()
    return
  }

  emit('changeComponent', currentIndex.value)
}

function closeTutorial() {
  exitTutorial()
}

function exitTutorial() {
  emit('exit')
}

function onEditRecord() {
  store.dispatch('tuto/startEditTutoPartRecord')
}

function onEditText() {
  emit('editText')
}

</script>

<style scoped>
.box {
  position: absolute;
  width: 20vw;
  top: 5vh;
  right: 3vw;
  border: 1px solid #c0c0c0;
  background-color: var(--c-gray-soft-1);
  border-radius: 5px;
  padding: 20px;
  backdrop-filter: blur(5px);
  font-family: var(--font-secondary-1);
  z-index: 600;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.utililtyButtons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.back-button {
  cursor: pointer;
  color: grey;
  font-size: 1.5rem;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.5s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.overlay-enter-to,
.overlay-leave-from {
  opacity: 1;
}

</style>

<style>
.overlay-content p {
  @apply mt-4
}
</style>