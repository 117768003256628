<template>
  <div
    ref="listContainer"
    class="drag-drop-list"
    @dragstart="handleDragStart"
    @dragover="handleDragOver"
    @drop="handleDrop"
    @dragend="handleDragEnd"
  >
    <slot :dragging-item="draggingItem" />
  </div>
</template>

<script setup>
import { ref } from "vue"

const draggingItem = ref(null) // L'élément actuellement déplacé
const listContainer = ref(null)

const emit = defineEmits(['dragresult'])

const handleDragStart = (event) => {
  draggingItem.value = event.target
  event.target.classList.add("dragging")
}

const handleDragOver = (event) => {
  event.preventDefault() // Nécessaire pour autoriser le drop
}

const handleDrop = (event) => {
  event.preventDefault()

  const draggedOverElement = event.target.closest(".draggable-item")
  if (draggingItem.value && draggedOverElement && draggingItem.value !== draggedOverElement) {
    emit('dragresult', +draggingItem.value.getAttribute('idx'), +draggedOverElement.getAttribute('idx'))
  }

  draggingItem.value?.classList.remove("dragging")
  draggingItem.value = null
};

const handleDragEnd = () => {
  if (draggingItem.value) {
    draggingItem.value.classList.remove("dragging")
    draggingItem.value = null
  }
}
</script>

<style>
.drag-drop-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.draggable-item {
  cursor: grab;
  padding: 7px;
  border: 1px solid var(--c-gray-2);
  border-radius: 5px;
}

.dragging {
  opacity: 0.5;
}
</style>
