<template>
  <div>
    <FilteredEventContent />
  </div>
</template>

<script>
export default {
  name: 'LegendPie'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted, onBeforeUnmount, defineEmits } from 'vue'
import * as mutationTypes from '@/store/mutations-types.js'
import _ from 'lodash'

import FilteredEventContent from '@/components/borderLists/ListLegend/content/filteredEventContent.vue'

const store = useStore()

let events = []
const groupedEvents = ref({})
const displayedEvents = computed(() => store.getters['event/common/events'])
const allEvents = computed(() => store.getters['event/common/allEvents'])
const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])
const eventFilters = computed(() => store.getters['event/filtering/eventFilters'])

watch(pieHoveredSections, () => {
  setLegendEvents()
})

onMounted(() => {
  setLegendEvents()
})

onBeforeUnmount(() => {
  deleteOldHighlight()
  events = []
  groupedEvents.value = {}
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
})

const emits = defineEmits(['title'])

function setLegendEvents() {
  deleteOldHighlight()
  let idPie = null
  if (pieHoveredSections.value.length > 0) {
    const hoveredPie = pieHoveredSections.value[pieHoveredSections.value.length - 1]
    idPie = hoveredPie.id
    emits('title', hoveredPie.name)
    events = allEvents.value.filter(event => event.parentSection === idPie)
    groupedEvents.value = _.groupBy(events, (d) => d.code)
  }
  highlight(idPie)
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
}

function deleteOldHighlight() {
  delete eventFilters.value.sections
  store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, eventFilters.value)
  store.dispatch(`event/common/generateEvents`)
}

function highlight(idPie) {
  if (idPie === null) {
    return
  }

  eventFilters.value.sections = [idPie]
  store.commit(`event/filtering/${mutationTypes.SET_EVENT_FILTERS}`, eventFilters.value)
  store.dispatch(`event/common/generateEvents`)
}

</script>

<style>
.legend-pie-content-date {
  font-family: var(--font-secondary-2);
  font-weight: 500;
  letter-spacing: 0.03em;
}
</style>