<template>
  <div>
    <FamilyHistory />
    <DiabFollowUp />
    <Parcours />
  </div>
</template>

<script>
export default {
  name: 'ParcoursContainer'
}
</script>

<script setup>
import Parcours from '@/components/hud/parcours/Parcours.vue'
import DiabFollowUp from '@/components/hud/parcours/medical/DiabFollowUp.vue'
import FamilyHistory from '@/components/hud/parcours/medical/FamilyHistory.vue'

</script>