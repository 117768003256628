<template>
  <Tutorial
    :all-components="allComponents"
  />
</template>

<script>
export default {
  name: 'TutorialLauncherPriseEnMain'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import Tutorial from '@/components/tutorial/Tutorial.vue'

const store = useStore()

const allComponents = computed(() => store.getters['tuto/tutoParts'].map(part => ({
  ...part,
  idTutoPartRecord: part.IdTutoPart
})))

</script>