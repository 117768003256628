import common from "@/store/modules/event/common.js"
import edit from "@/store/modules/event/edit.js"
import report from '@/store/modules/event/report.js'
import filtering from "@/store/modules/event/filtering.js"
import parcours from "@/store/modules/event/parcours.js"

export default {
  namespaced: true,
  modules: {
    common: common,
    edit: edit,
    report: report,
    filtering: filtering,
    parcours: parcours
  },
  getters: {},
  mutations: {},
  actions: {
    /**
     * Permet de reset par défaut le state de l'application. Cette fonction est utilise avant chaque accès à un nouveau patient pour remttre à 0 tout ce qui a pu être effectué sur l'application
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    async resetState(context) {
      const promises = []
      const modulesToReset = ['common', 'edit', 'report', 'filtering', 'parcours']

      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`event/${module}/resetState`, null, {root: true}))
      })

      return Promise.all(promises) 
    }
  }
}