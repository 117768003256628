<template>
  <svg
    id="ac4ad517-34cf-4018-9c81-e6ea556a39a4"
    viewBox="0 0 40 32.5"
  >
    <path
      class="letter"
      d="M31.49,18.78A2.37,2.37,0,0,1,30,18.21a2.09,2.09,0,0,1-.72-1.29c-1.18.75-2.89,1.86-3.64,1.86A3.42,3.42,0,0,1,22,15.64c0-1.28.79-2.05,2.33-2.51a33.59,33.59,0,0,0,4.95-1.68V10.6c0-2.06-1.14-3.4-2.92-3.4A2,2,0,0,0,25,7.72a5,5,0,0,0-.89,1.79.65.65,0,0,1-.62.46C23,10,22.3,9.54,22.3,9c0-.23.23-.45.79-.83a16.71,16.71,0,0,1,4.49-2,4.41,4.41,0,0,1,2.4.68,3.82,3.82,0,0,1,1.41,3.48v5.1c0,1.37.53,1.83,1.15,1.83a1.7,1.7,0,0,0,1-.29l.19.37ZM29.32,12c-.56.32-2.26.89-3.15,1.26-1.08.43-1.87,1-1.87,2a2.24,2.24,0,0,0,2.4,2.14,3.79,3.79,0,0,0,2.56-1C29.26,15,29.32,13.45,29.32,12Z"
      :style="{
        opacity: aOpacity
      }"
    />
    <g>
      <path
        class="ea37fe46-8a94-402a-b547-875d42cf2aca"
        d="M20.22,28.06A12.21,12.21,0,1,1,21.67,5.4"
      />
      <path
        class="ea37fe46-8a94-402a-b547-875d42cf2aca"
        d="M23.25,26.58l-.58.37"
      />
    </g>
    <line
      class="b2ccf2e4-8198-4ab4-996b-c64ec0376b3b"
      x1="22.82"
      y1="20.93"
      x2="29.78"
      y2="20.93"
    />
    <line
      class="b2ccf2e4-8198-4ab4-996b-c64ec0376b3b"
      x1="22.82"
      y1="23.25"
      x2="33.09"
      y2="23.25"
    />
    <line
      class="b2ccf2e4-8198-4ab4-996b-c64ec0376b3b"
      x1="31.88"
      y1="20.93"
      x2="33.21"
      y2="20.93"
    />
  </svg>
</template>

<script>
export default {
  name: 'LegendIcon'
}
</script>

<script setup>
defineProps({
  aOpacity: {
    type: Number,
    required: false,
    default: 1
  }
})
</script>

<style scoped>
.b2ccf2e4-8198-4ab4-996b-c64ec0376b3b, .ea37fe46-8a94-402a-b547-875d42cf2aca {
  fill: none;
  stroke: red;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ea37fe46-8a94-402a-b547-875d42cf2aca {
  stroke-width: 1.7px;
}

.b2ccf2e4-8198-4ab4-996b-c64ec0376b3b {
  stroke-width: 1.35px;
}

.letter {
  fill: var(--color-text);
  stroke: var(--color-text);
}
</style>