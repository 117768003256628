<template>
  <div>
    <img
      src="../assets/tronc_2.png"
    >
  </div>
</template>
  
<script>
export default {
  name: 'DecouverteTutoContent3'
}
</script>