import * as mutationTypes from '../../mutations-types.js'
import eventManager from '@/libraries/EventManager.js'

const getDefaultState = () => {
  return {
    /**
     * Il s'agit des informations du cercle des antécédents familiaux
     * @type {null|Objet}
     */
    familyHistoryCircle: null,
    /**
     * Définit si le cercle des antécédents familiaux doit être affiché ou non
     * @type {Boolean}
     */
    displayFamilyHistoryCircle: false,
    familyHistoryEvents: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: { 
    familyHistoryCircle: state => state.familyHistoryCircle,
    isDisplayedFamilyHistoryCircle: state => state.displayFamilyHistoryCircle,
    familyHistoryEvents: state => state.familyHistoryEvents
  },
  mutations: {
    /**
     * Permet de mettre à jour les données du cercle des antécédents familiaux
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_FAMILY_HISTORY_CIRCLE](state, payload) {
      state.familyHistoryCircle = payload
    },
    /**
     * Permet de mettre à jour si le cercle des antécédents familiaux doit être affiché ou non
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE](state, payload) {
      state.displayFamilyHistoryCircle = payload
    },
    /**
     * Permet de mettre 
     * @param {EyeEvent[]} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_FAMILY_HISTORY_EVENTS] (state, payload) {
      state.familyHistoryEvents = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    updateCircle(context, { circle }) {
      if (circle !== undefined) {
        let events = eventManager.extractEventsFromCircle([circle])
        context.commit(mutationTypes.UPDATE_FAMILY_HISTORY_CIRCLE, circle)
        context.commit(mutationTypes.UPDATE_FAMILY_HISTORY_EVENTS, events)
      }
    }
  }
}