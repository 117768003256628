<template>
  <div>
    <label>
      Valeur
      <input
        v-model="data.value"
        type="text"
        class="mr-2"
        @input="(e) => onInput(e, data.value, false)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomFilteringExceptValue'
}
</script>

<script setup>
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const data = defineModel({
  type: Object,
  required: true
})

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>