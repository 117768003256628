<template>
  <Tutorial
    :all-components="allComponents"
  />
</template>

<script>
export default {
  name: 'TutorialLauncherDecouverte'
}
</script>

<script setup>
import { computed, markRaw } from 'vue'
import Tutorial from '@/components/tutorial/Tutorial.vue'
import { useStore } from 'vuex'

import Content1 from '@/components/tutorial/decouverte/contentComponents/Component1.vue'
import Content2 from '@/components/tutorial/decouverte/contentComponents/Component2.vue'
import Content3 from '@/components/tutorial/decouverte/contentComponents/Component3.vue'
import Content4 from '@/components/tutorial/decouverte/contentComponents/Component4.vue'
import Content5 from '@/components/tutorial/decouverte/contentComponents/Component5.vue'
import Content6 from '@/components/tutorial/decouverte/contentComponents/Component6.vue'
import Content7 from '@/components/tutorial/decouverte/contentComponents/Component7.vue'

const store = useStore()

const ovverideComponents = [
  {
    component: null,
    contentComponent: markRaw(Content1),
    timeout: 10 * 1000
  },
  {
    contentComponent: markRaw(Content1),
    timeout: 5 * 1000
  },
  {
    contentComponent: markRaw(Content1)
  },
  {
    contentComponent: markRaw(Content2)
  },
  {
    contentComponent: markRaw(Content3)
  },
  {
    contentComponent: markRaw(Content4)
  },
  {
    contentComponent: markRaw(Content5)
  },
  {
    contentComponent: markRaw(Content6)
  },
  {
    contentComponent: markRaw(Content7)
  }
]

const allComponents = computed(() => store.getters['tuto/tutoParts'].map((part, i) => ({
  ...part,
  ...ovverideComponents[i],
  idTutoPartRecord: part.IdTutoPart
})))

</script>