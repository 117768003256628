import * as mutationTypes from '@/store/mutations-types.js'
import EventSelectionTypes from '@/enums/event_selection_types.js'

const getDefaultState = () => ({
  /**
   * Définit si les événements appartenant au filtrage doivent être mis en surbrillance sur la représentation
   * @type {Boolean}
   */
  highlight: true,
  /**
   * Définit si la représentation ne doit comporter que les événements respectant le filtrage. 
   * @type {Boolean}
   */
  strictRepresentation: false,
  /**
   * Liste des symboles (+) devant s'afficher sur la représentation
   * @type {EyeSymbol[]}
   */
  symbols: [],
  /**
   * Filtres appliquées sur les événements visualisés à l'aide d'un filtrage
   * @type {EyeEventFilterOptions}
   */
  eventFilters: {},
  /**
   * Il s'agit des événements repondant aux filtrages sur les événements
   * @type {EyeEventFiltering}
   */
  filteredEvents: {},
  /**
   * Permet de déterminer si tous les événements du cercle actif doivent être affichés
   * @type {Boolean}
   */
  displayAllActiveCircleEvent: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    highlight: state => state.highlight,
    strictRepresentation: state => state.strictRepresentation,
    symbols: state => state.symbols,
    eventFilters: state => state.eventFilters,
    filteredEvents: state => state.filteredEvents,
    displayAllActiveCircleEvent: state => state.displayAllActiveCircleEvent
  },
  mutations: {
    [mutationTypes.SET_FILTERING_HIGHLIGHT] (state, payload) {
      state.highlight = payload
    },
    [mutationTypes.SET_FILTERING_STRICT_REPRESENTATION] (state, payload) {
      state.strictRepresentation = payload
    },
    /**
     * Permet de définir la liste des symbols devant être affichée sur la représentation
     * @param {EyeSymbol[]} payload
     * @method
     * @public
     */
    [mutationTypes.SET_SYMBOLS] (state, payload) {
      state.symbols = payload
    },
    /**
     * Permet de définir un filtrage qui sera appliqué sur les événements devant être affiché à l'utilisateur
     * @param {EyeEventFilterOptions} payload
     * @method
     * @public
     */
    [mutationTypes.SET_EVENT_FILTERS](state, payload) {
      state.eventFilters = payload

      //suppression selection ancien element filtrés
      const eventsToUnselect = this.state.event.common.events.filter(event => event.selectionTypes.includes(EventSelectionTypes.FILTERING))
      eventsToUnselect.forEach(event => {
        this.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
          event: event,
          selectionType: EventSelectionTypes.FILTERING
        })
      })
    },
    /**
     * Permet de définir la liste des événements répondant aux filtrages
     * @param {EyeEventFiltering} payload
     * @method
     * @public
     */
    [mutationTypes.SET_FILTERED_EVENTS](state, payload) {
      state.filteredEvents = payload
    },
    /**
     * Permet de mettre à jour la variable indiquant si tous les événements du cercle actif doivent être affiché ou non
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT](state, payload) {
      state.displayAllActiveCircleEvent = payload

      if (payload === false) {
        const eventsActiveCircle = this.state.event.common.events.filter(event => event.selectionTypes.includes(EventSelectionTypes.MAIN_CIRCLE))
        eventsActiveCircle.forEach(event => {
          this.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
            event,
            selectionType: EventSelectionTypes.MAIN_CIRCLE
          })
        })
      }
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
  }
}