<template>
  <div>
    <ContextMenu
      v-if="menuConfig.show"
      :x="menuConfig.x"
      :y="menuConfig.y"
      :items="menuConfig.items"
      :z-index="5"
      @close="onCloseContextMenu"
    />

    <div
      id="clear-selected-events-action-container"
      ref="clear-selected-events-action-container"
      height="15"
    >
      <IconCross
        id="clear-selected-events-action"
        ref="clear-selected-events-action"
        class="clear-selected-events-action"
        @click="clearSelect"
      />
    </div>

    <div 
      id="selected-menu-list-container"
      class="selected-menu-list-container"
    >
      <IconMenuList
        id="selected-menu-list"
        ref="selected-menu-list"
        class="selected-menu-list"
      />
    </div>

    <div>
      <div
        v-if="displayReportForm"
        class="container-report-form"
      >
        <ReportForm
          @validate="onValidateReportForm"
        />
      </div>
      <p @click="onClickReportForm">
        R
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import menuTypes from '@/enums/menu_types.js'
import ContextMenu from "@/components/ContextMenu.vue"
import MemorizedEventsSortTypes from '@/enums/memorized_events_sort_types.js'
import IconMenuList from '@/assets/icons/menuList.vue'
import { modifySelection } from "@/config/contextMenu/fonction-menu.js"
import * as mutationTypes from '@/store/mutations-types.js'
import * as d3 from 'd3'
import IconCross from '@/assets/icons/cross.vue'
import ReportForm from '@/components/borderLists/ListSelectedEvent/ReportForm.vue'

export default {
  name: 'ListSelectedEventOptions',
  components: {
    ContextMenu,
    IconMenuList,
    IconCross,
    ReportForm
  },
  data: () => ({
    /**
     * Configuration du menu contextuel s'affichant lors d'un clique droit sur le bouton
     * @type {EyeContextMenu}
     */
    menuConfig: {
      show: false,
      x: 0,
      y: 0,
      items: []
    },
    displayReportForm: false
  }),
  computed: {
    ...mapGetters({
      memorizedEvents: "event/common/memorizedEvents",
      eventsStore: 'event/common/events',
      listMemorizedEventsSort: 'event/common/listMemorizedEventsSort'
    })
  },
  watch: {
    listMemorizedEventsSort(_, oldValue) {
      modifySelection(this.menuConfig, this.listMemorizedEventsSort.type, oldValue.type)
    }
  },
  mounted() {
    this.initEvent()
    this.menuConfig.items = [
      {
        id: 0,
        label: "Date",
        labelId: "DATE",
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          const isSortAsc = this.listMemorizedEventsSort.type === MemorizedEventsSortTypes.DATE
            ? !this.listMemorizedEventsSort.asc
            : false
          this.$store.commit(`event/common/${mutationTypes.SET_LIST_MEMORIZED_EVENTS_SORT}`, {
            type: MemorizedEventsSortTypes.DATE,
            asc: isSortAsc,
          })
        }
      },
      {
        id: 1,
        label: "Sévérité",
        labelId: "SEVERITY",
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          const isSortAsc = this.listMemorizedEventsSort.type === MemorizedEventsSortTypes.SEVERITY
            ? !this.listMemorizedEventsSort.asc
            : false
          this.$store.commit(`event/common/${mutationTypes.SET_LIST_MEMORIZED_EVENTS_SORT}`, {
            type: MemorizedEventsSortTypes.SEVERITY,
            asc: isSortAsc,
          })
        }
      },
      {
        id: 2,
        label: "Code",
        labelId: "CODE",
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          const isSortAsc = this.listMemorizedEventsSort.type === MemorizedEventsSortTypes.CODE
            ? !this.listMemorizedEventsSort.asc
            : false
          this.$store.commit(`event/common/${mutationTypes.SET_LIST_MEMORIZED_EVENTS_SORT}`, {
            type: MemorizedEventsSortTypes.CODE,
            asc: isSortAsc,
          })
        }
      },
      {
        id: 3,
        label: "Séléction",
        labelId: "SELECTION",
        type: menuTypes.TEXT,
        selected: true,
        click: () => {
          const isSortAsc = this.listMemorizedEventsSort.type === MemorizedEventsSortTypes.SELECTION
            ? !this.listMemorizedEventsSort.asc
            : false
          this.$store.commit(`event/common/${mutationTypes.SET_LIST_MEMORIZED_EVENTS_SORT}`, {
            type: MemorizedEventsSortTypes.SELECTION,
            asc: isSortAsc,
          })
        }
      },
    ]
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    onCloseContextMenu() {
      this.menuConfig.show = false
    },
    /**
     * Cette fonction permet l'initialisation des actions sur le bouton de la liste des elements mémorisés
     * @method
     * @public
     */
    initEvent() {
      d3.select(this.$refs['selected-menu-list'].$el)
        .on('click', (event) => {
          this.sendEvent({ event: event })
          event.stopPropagation()
          event.preventDefault()
          this.menuConfig.show = !this.menuConfig.show

          const boundingRect = this.$refs['selected-menu-list'].$el.getBoundingClientRect()
          this.menuConfig.x = boundingRect.x - 100
          this.menuConfig.y = boundingRect.y - 92

          //Faire un nextTick et getBoundingRect sur le composant du menuContextuel ou voir avec une ref si moyen de choper la taille
          this.collaborativeEventTreated()
        })
    },
    /**
     * Permet d'éffacer l'ensemble des évenemetn séléctionné dans le store, la liste active et sur le cercle.
     * @method
     * @public
     * @param {*} event 
     */
    clearSelect(event){
      this.sendEvent({ event: event })
      for (const selected of this.memorizedEvents) {
        this.$store.commit(`event/common/${mutationTypes.CHANGE_STATE_MEMORIZED_EVENT}`, selected)
      }
      this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`,this.eventsStore)
      this.collaborativeEventTreated()
    },
    onClickReportForm() {
      this.displayReportForm = !this.displayReportForm
    },
    onValidateReportForm() {
      this.displayReportForm = false
    }
  }
}
</script>

<style scoped>
.container-report-form {
  position: absolute;
  right: 35px;
  bottom: 65px;
  background-color: var(--color-bg-1);
  border: solid;
  border-color: var(--color-border);
  border-width: 1px;
  border-radius: 10px;
  padding: 5px;
}
</style>