/**
 * Définit les types de hiérarchies possible et les hierarchies disponibles
 * pour le type system
 */

import UserRole from './userRole.js'

export const type = {
  'SYSTEM': 'SYSTEM',
  'USER': 'USER'
}

export const hierarchy = {
  'DEFAULT': 'DEFAULT',
  'DIABETOLOGIE': 'DIABETOLOGIE',
  'DIABETOLOGIE_NURSE_ENTRY': 'DIABETOLOGIE_NURSE_ENTRY',
  'DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT': 'DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT'
}

export const hierarchyLabel = {
  'DEFAULT': 'Clinique',
  'DIABETOLOGIE': 'Diabétologie',
  'DIABETOLOGIE_NURSE_ENTRY': 'Diabétologie Entrée',
  'DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT': 'Diabétologie Entrée Trt',
  'Welcoop3': 'Etendues',
  'Welcoop': 'Standards'
}

export const rootHierarchyFromEntityContext = {
  [UserRole.GENERALISTE]: {
    type: type.SYSTEM,
    id: hierarchy.DEFAULT
  },
  [UserRole.PHARMACIEN]: {
    type: type.USER,
    id: 'Welcoop'
  },
  [UserRole.QOR]: {
    type: type.USER,
    id: 'QOR'
  },
  [UserRole.CH]: {
    type: type.USER,
    id: 'ChatiStatut'
  }
}