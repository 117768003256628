<template>
  <ListBase
    id="list-merge-events"
    ref="listBaseRef"
    title="Fusionner les Evénements"
  >
    <template #content>
      <form @submit.prevent="handleSubmit">
        <!-- Boutons radio type de regroupement -->
        <div class="field-container">
          <label>
            Regroupement par
            <div>
              <label
                v-for="(option, index) in groupTypes"
                :key="index"
              >
                <input
                  v-model="groupType"
                  type="radio"
                  :value="option.value"
                  @change="(e) => onInput(e, groupType, true)"
                >
                {{ option.label }}
              </label>
            </div>
          </label>
        </div>
  
        <!-- Select valeur de regroupement -->
        <div class="field-container">
          <label class="input-fill">
            Valeur de regroupement:
            <select
              v-model="groupValue"
              @change="(e) => onInput(e, groupValue, true)"
            >
              <option
                v-for="(property, index) in userEventProperties"
                :key="index"
                :value="property.name"
              >
                {{ property.name }}
              </option>
            </select>
          </label>
        </div>
  
        <!-- Checkbox Activer un podium -->
        <div
          class="field-container mt-8"
        >
          <ToggleSwitch
            v-model:status="enablePodium"
            text="Activer un podium"
          />
        </div>
  
        <!-- Inputs supplémentaires si le podium est activé -->
        <div
          v-if="enablePodium"
        >
          <!-- Nombre d'éléments du podium -->
          <div class="field-container">
            <label class="input-fill">
              Nombre d'éléments du podium:
              <input
                v-model.number="podiumNbGroup"
                type="number"
                @input="(e) => onInput(e, podiumNbGroup, false)"
              >
            </label>
          </div>
  
          <!-- Boutons radio Type de podium -->
          <div class="field-container">
            <label>
              Portée du podium
              <div>
                <label
                  v-for="(option, index) in podiumTypes"
                  :key="index"
                >
                  <input
                    v-model="podiumType" 
                    type="radio" 
                    :value="option.value"
                    @input="(e) => onInput(e, podiumType, true)"
                  >
                  {{ option.label }}
                </label>
              </div>
            </label>
          </div>
        </div>
  
        <!-- Bouton de soumission -->
        <DesignButton
          text="Valider"
        />
      </form>
    </template>
  </ListBase>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import DesignButton from '@/components/DesignButton.vue'
import * as eyediagModeEnum from '@/shared/enums/eyediagMode.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as mutationTypes from '@/store/mutations-types.js'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import utils from '@/libraries/utils.js'

const store = useStore()

// Données pour les boutons radio "type de regroupement"
const groupTypes = [
  { label: 'Section', value: eyediagModeEnum.populationalOptions.mergedBy.SECTION },
  { label: 'Evénement', value: eyediagModeEnum.populationalOptions.mergedBy.EVENT }
]

// Données pour le select "valeur de regroupement" (exemples de données)

// Données pour les boutons radio "Type de podium"
const podiumTypes = [
  { label: 'Global', value: 'global' },
  { label: 'Par cercle', value: 'perCircle' }
]

const listBaseRef = ref(null)
// V-models
const groupType = ref(groupTypes[0].value)
const groupValue = ref('')
const enablePodium = ref(false)
const podiumNbGroup = ref(1) // Valeur par défaut pour le nombre d'éléments du podium
const podiumType = ref(podiumTypes[0].value)

const eyediagMode = computed(() => store.getters['eyediagMode'])
const userEventProperties = computed(() => store.getters['event/common/userEventProperties'])

// Fonction de soumission
async function handleSubmit(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`)
  const mergeOptions = {
    mergedKey: groupValue.value,
    type: groupType.value,
    enablePodium: enablePodium.value
  }

  if (enablePodium.value === true) {
    mergeOptions.podiumType = podiumType.value
    mergeOptions.podiumNbGroup = podiumNbGroup.value
  }

  Object.assign(eyediagMode.value.options, {
    merge: mergeOptions
  })
  store.commit(mutationTypes.SET_EYEDIAG_MODE, eyediagMode.value)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.state.circle.unitPerCircle,
    periodUnit: store.state.circle.periodUnit,
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

function movePanel(...params) {
  listBaseRef.value.movePanel(...params)
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

defineExpose({
  movePanel: movePanel
})
</script>

<style scoped>

.field-container {
  @apply mb-8
}

.field-container > label {
  font-size: 1.3rem;
}

.field-container > label > * {
  @apply mt-4
}

.input-fill {
  display: flex;
  flex-direction: column;
}

.input-fill > input, input-fill > select {
  flex-grow: 1;
}

</style>