<template>
  <div
    class="tooltip-settings"
    @click="onEditEvent"
  >
    <div class="cursor-pointer">
      <EditIcon
        width="20px"
        height="20px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipSettingsPatient'
}
</script>

<script setup>
import { useStore } from 'vuex'
import EditIcon from '@/assets/icons/edit.vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const props = defineProps({
  tooltipData: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['exitTooltip'])

function onEditEvent(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(mutationTypes.IS_EDIT_MODE, true)
  store.commit(`event/edit/${mutationTypes.SET_EDITING_EVENT}`, props.tooltipData)
  emit('exitTooltip')
  store.dispatch('ws/collaborativeEventTreated')
}
</script>