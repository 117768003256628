<template>
  <!-- <ContextMenu
    v-if="suggestions.show && isInNurseEntry === false"
    :x="suggestions.pos.x"
    :y="suggestions.pos.y"
    :items="suggestions.items"
    :z-index="5"
    @close="onCloseContextMenu"
  >
    <template #croix="item">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div>{{ item.item.label }}</div>
        <div
          v-if="item.item.hasOwnProperty('bddId')"
          class="delFilter"
          @click="(e) => onDeleteAssembly(e, item.item.bddId)"
        >
          &#10005;
        </div>
      </div>
    </template>
  </ContextMenu> -->
  <div class="patient-info_wrapper">
    <Populational v-if="isInPopulational || isAssociatedPatient" />
    <Universal v-else-if="isInUniversal" />
    <Patient v-else />
    <div class="patient-info_date_range_wrapper">
      <p
        id="patient-info-current-temporality"
        ref="patient-info-current-temporality"
      >
        {{ `${periodLabel.replace("-", ".")}` }}
      </p>
      <div class="patient-info_temporality_mode">
        <p>{{ year }}</p>
        <p>{{ labelFilters }}</p>
      </div>
    </div>
    <Parcours
      v-if="isInNurseEntry === false && isInPopulational === false && isInUniversal === false"
      class="mt-4"
    />
    <!-- <p
      v-if="isInNurseEntry === false"
      id="patient-info_suggestions"
      ref="patient-info_suggestions"
      @click="onClickSuggestion"
      @contextmenu="onContextMenuSuggestion"
    >
      <span>Mes filtres: </span>{{ suggestion }}
    </p> -->
    <SelectDiab v-if="isInNurseEntry" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import utils from "@/libraries/utils";
import periodUnits from '@/shared/enums/period_units.js'
import * as d3 from 'd3'

// import ContextMenu from '@/components/ContextMenu.vue'
import { fillMenuSuggestions } from '@/config/suggestions.js'
import CircleUtility from '@/libraries/CircleUtility.js'

import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import menuTypes from '@/enums/menu_types.js'
import SelectDiab from '@/components/hud/main/SelectDiab.vue'
import Parcours from '@/components/hud/parcours/Container.vue'

import Populational from '@/components/hud/main/Populational.vue'
import Patient from '@/components/hud/main/Patient.vue'
import Universal from '@/components/hud/main/Universal.vue'

export default {
  name: "PatientInfo",
  components: {
    // ContextMenu,
    Populational,
    Patient,
    Universal,
    SelectDiab,
    Parcours
  },
  data: () => ({
    /**
     * Configuration du menu contextuel des suggestions de filtrages
     * @type {EyeContextMenu}
     */
    suggestionItems: [],
    suggestions: {
      items: [],
      show: false,
      pos: {x: 0, y: 0}
    },
    /**
     * Libellé de la période de temps sur laquelle se trouve l'utilisateur
     * @type {String}
     */
    periodLabel: '',
    /**
     * Index de la suggestion précédemment séléctionné par l'utilisateur
     * @type {Number}
     */
    indexSuggestion: 0
  }),
  computed: {
    ...mapGetters({
      patientData: "patient/patientData",
      selectedPeriodLabel: "selectedPeriodLabel",
      labelFilters: "labelFilters",
      hoveredCircle: "circle/hoveredCircle",
      theme: "circle/theme",
      eyediagMode: "eyediagMode",
      userAssemblies: "assembly/userAssemblies",
      hierarchy: 'hierarchy',
      isInNurseEntry: 'isInNurseEntry'
    }),
    /**
     * Il s'agit de la période sur laquelle se trouve le cercle principal
     * @type {EyePeriod}
     */
    selectedPeriod() {
      return this.$store.state.selectedPeriod
    },
    /**
     * Temporalité sur laquelle l'utilisateur est positionné
     * @type {String}
     */
    year() {
      if (!this.selectedPeriod) return '';
      if (this.selectedPeriod.unit === periodUnits.YEAR) {
        const nbYear = this.selectedPeriod.unitPerCircle
        return `${nbYear} an` + ((nbYear > 1) ? 's' : '')
      } else if (this.selectedPeriod.unit === periodUnits.SEMESTER) {
        return 'Semestre'
      } else {
        return 'Trimestre'
      }
    },
    /**
     * Suggestion de filtrage / temporalité proposé par l'application
     * @type {String}
     */
    suggestion() {
      if (this.suggestionItems.length === 0) return '';
      return `${this.suggestionItems[this.indexSuggestion].label}`;
    },
    /**
     * Permet de déterminer si l'utilisateur se trouve en mode populationnel
     * @type {Boolean}
     */
    isInPopulational() {
      return this.eyediagMode.type === eyediagMode.mode.POPULATIONAL
    },
    /**
     * 
     */
    isAssociatedPatient() {
      return this.eyediagMode.type === eyediagMode.mode.PATIENT
        && this.eyediagMode.options.cohortBase
    },
    isInUniversal() {
      return this.eyediagMode.type === eyediagMode.mode.UNIVERSAL
    }
  },
  watch: {
    selectedPeriodLabel() {
      this.periodLabel = this.selectedPeriodLabel
    },
    hoveredCircle() {
      let color = 'black'
      let opacity = 1
      let config = {color, opacity}

      if (this.hoveredCircle === null) {
        this.periodLabel = this.selectedPeriodLabel
        config = this.theme.find(theme => theme.active)
      } else {
        this.periodLabel = this.hoveredCircle.period.label
        config = this.theme[CircleUtility.getIndexConfig(this.hoveredCircle.configIndex)]
      }
      color = config.color
      opacity = config.opacity

      d3.select(this.$refs['patient-info-current-temporality'])
        .style("color", color)
        .style("opacity", opacity)
    },
    //Fusion des suggestions "en dur" d'eyediag avec les assemblages de filtres fait par l'utilisateur lui même
    userAssemblies() {
      let lastId = this.suggestionItems[this.suggestionItems.length - 1].id
      this.suggestions.items = [
        ...this.suggestionItems,
        ...this.userAssemblies.map(assembly => ({
          id: ++lastId,
          label: assembly.Title,
          type: menuTypes.TEXT,
          selected: false,
          bddId: assembly.id,
          click: () => this.$store.dispatch('assembly/getUserAssembly', {idAssembly: assembly.id})
        }))
      ]
    }
  },
  mounted() {
    this.suggestionItems = fillMenuSuggestions(this.$store)
    this.suggestions.items = this.suggestionItems
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction est appelée lorsque l'utilisateur effectue un clique droit sur la suggestion proposée par l'application 
     * @method
     * @public
     */
    onContextMenuSuggestion(event) {
      this.sendEvent({ event: event })
      event.preventDefault()
      event.stopPropagation()

      if (event.isTrusted) {
        this.suggestions.pos.x = event.clientX
        this.suggestions.pos.y = event.clientY
      } else {
        const boundingRect = this.$refs['patient-info_suggestions'].getBoundingClientRect()
        this.suggestions.pos.x = boundingRect.x
        this.suggestions.pos.y = boundingRect.y
      }
      this.suggestions.show = !this.suggestions.show

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur clique sur la suggestion proposé par l'application
     * @param {Event} event Evenement fournit par le listener
     * @method
     * @public
     */
    async onClickSuggestion(event) {
      this.sendEvent({ event: event})

      this.suggestionItems[this.indexSuggestion].click()
      if (this.indexSuggestion < this.suggestionItems.length - 1) {
        this.indexSuggestion += 1
      }

      this.collaborativeEventTreated()
    },

    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur la croix d'un item pour le supprimer depuis le menu contextuel des suggestions
     * @param {Event} event Evenement fournit par le listener
     * @param {Int} id Identifiant de l'assemblage devant être supprimé
     */
    async onDeleteAssembly(event, id) {
      event.stopPropagation()
      if (event.isTrusted) {
        await this.$store.dispatch('assembly/delUserAssembly', id)
        this.sendEvent({ event: event })
      } else {
        this.sendEvent({ event: event })
        await this.$store.dispatch('assembly/getListUserAssemblies')
      }
      this.collaborativeEventTreated()
    },
    onCloseContextMenu() {
      this.suggestions.show = false
    }
  }
};
</script>

<style>
h1 {
  margin: 0px;
  padding: 0px;
}
.patient-info_wrapper{
  color: var(--color-text);
}
.patient-info_wrapper {
  margin: 1.5rem 0;
  background: var(--color-bg);
  z-index: 3;
  font-weight: 100;
  font-family: var(--font-primary);
}
.patient-info_name {
  color: var(--color-text);
  font-family: 'Gotham Pro';
  font-size: 1.6rem;
  font-weight: 300;
}
.patient-info_date_range_wrapper {
  display: flex;
  font-weight: 100;
  font-size: 5.3rem;
}
.patient-info_temporality_mode {
  display: flex;
  margin-left: 1.5rem;
  align-self: center;
  flex-direction: column;
  font-weight: 100;
  font-size: 1.2rem;
  font-family: var(--font-secondary-1);
}

#patient-info-current-temporality {
  font-size: 3.5rem;
  font-weight: 100;
  font-family: var(--font-secondary-2);
  letter-spacing: 0.03em;
}

#patient-info_suggestions{
  font-size: 1.15rem;
  cursor: pointer;
  font-family: var(--font-secondary-1);
  font-weight: 300;
  font-style: 'normal';
  user-select: none;
}

.patient-birthdate {
  font-size: 1.27rem;
  font-family: var(--font-secondary-1);
  font-weight: 200;
}

#patient-info_suggestions > span {
  font-weight: 200;
  font-family: var(--font-secondary-1);
}
</style>