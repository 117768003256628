import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => ({
  /**
   * Définit le parcours survolé par l'utilisateur
   * @type {Boolean}
   */
  hoveredParcours: null,
  lockedParcours: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    hoveredParcours: state => state.hoveredParcours,
    lockedParcours: state => state.lockedParcours
  },
  mutations: {
    [mutationTypes.SET_HOVERED_PARCOURS] (state, payload) {
      state.hoveredParcours = payload
    },
    [mutationTypes.SET_LOCKED_PARCOURS] (state, payload) {
      state.lockedParcours = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
  }
}