import EventsUtils from './eventsUtils.js'
import CustomFilteringFormContent from '../enums/customFilteringFormContent.js'

const templateFormContent = {
  [CustomFilteringFormContent.STRICT_VALUE]: (config) => ({
    key: config.key || '',
    value: config.value || ''
  }),
  [CustomFilteringFormContent.EXCEPT_VALUE]: (config) => ({
    key: config.key || '',
    value: config.value || ''
  }),
  [CustomFilteringFormContent.GREATER_THAN]: (config) => ({
    key: config.key || '',
    lowerBound: config.lowerBound || ''
  }),
  [CustomFilteringFormContent.SMALLER_THAN]: (config) => ({
    key: config.key || '',
    upperBound: config.upperBound || ''
  }),
  [CustomFilteringFormContent.BETWEEN]: (config) => ({
    key: config.key || '',
    lowerBound: config.lowerBound || '',
    upperBound: config.upperBound || ''
  }),
  [CustomFilteringFormContent.LIKE]: (config) => ({
    key: config.key || '',
    value: config.value || ''
  })
}

class FilterCustom {
  constructor(events, filters, filteredEvents) {
    this.events = events
    this.filters = filters,
    this.filteredEvents = filteredEvents
  }

  static getDefaultRootTemplate() {
    return {
      operator: 'AND',
      type: 'block', 
      conditions: [{
        operator: 'AND',
        type: 'item',
        typeCondition: CustomFilteringFormContent.STRICT_VALUE,
        item: FilterCustom.getTemplateFor(CustomFilteringFormContent.STRICT_VALUE)
      }]
    }
  }

  static getTemplateFor(formContent, config = {}) {
    return templateFormContent[formContent](config)
  }

  filterCustom() {
    if (this.filters.custom) {
      for (const event of this.events) {
        if (this.filterCustomCheckEvent(event, this.filters.custom) === true) {
          this.filteredEvents.push(event)
        }
      }
    }
  }

  filterCustomCheckEvent(event, filters) {
    if (filters.type === 'block') {
      const results = []
      const funcLogicalOperator = (filters.operator === "OR") ? 'some' : 'every' 
      for (const condition of filters.conditions) {
        results.push(this.filterCustomCheckEvent(event, condition))
      }
      return results[funcLogicalOperator](elem => elem === true)
    } else {
      return this.itemConditionChecker(event, filters)
    }
  }

  itemConditionChecker(event, filters) {
    switch(filters.typeCondition) {
    case CustomFilteringFormContent.STRICT_VALUE:
      return this.itemCheckStrictValue(event, filters)
    case CustomFilteringFormContent.GREATER_THAN:
      return this.itemCheckGreaterThan(event, filters)
    case CustomFilteringFormContent.SMALLER_THAN:
      return this.itemCheckSmallerThan(event, filters)
    case CustomFilteringFormContent.BETWEEN:
      return this.itemCheckBetween(event, filters)
    case CustomFilteringFormContent.EXCEPT_VALUE:
      return this.itemCheckExcept(event, filters)
    case CustomFilteringFormContent.LIKE:
      return this.itemCheckLike(event, filters)
    default:
      return false
    }
  }

  itemCheckIsInArray(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value.includes(filters.item.value) 
  }

  itemCheckStrictValue(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value == filters.item.value 
  }

  itemCheckExcept(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value != filters.item.value 
  }

  itemCheckLike(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    if (typeof value === 'string') {
      return value.toLowerCase().includes(filters.item.value.toLowerCase())
    }
    return value?.includes(filters.item.value)
  }

  itemCheckGreaterThan(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value > filters.item.lowerBound
  }

  itemCheckSmallerThan(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value < filters.item.upperBound
  }

  itemCheckBetween(event, filters) {
    const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
    if (value === undefined) {
      return false
    }
    return value > filters.item.lowerBound && value < filters.item.upperBound
  }
}

export default FilterCustom