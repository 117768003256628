import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => ({
  idSelectedReport: null,
  reports: []
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    idSelectedReport: state => state.idSelectedReport,
    reports: state => state.reports
  },
  mutations: {
    [mutationTypes.SET_ID_SELECTED_REPORT] (state, payload) {
      state.idSelectedReport = payload
    },
    [mutationTypes.SET_REPORTS] (state, payload) {
      state.reports = payload
    },
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
  }
}