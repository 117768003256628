<template>
  <div>
    <video
      src="../assets/introDataFace.mp4"
      autoplay
    />
  </div>
</template>

<script>
export default {
  name: 'DecouverteTutoContent1'
}
</script>