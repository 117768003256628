<template>
  <g>
    <TransitionGroup name="list">
      <path
        v-for="(symbol, index) in symbols"
        :key="index"
        :d="symbol.path"
        :class="`${symbol.resourceTypeClass} symbol`"
        :style="{
          transform: `translate(${symbol.x}px,${symbol.y}px)`
        }"
      />
    </TransitionGroup>
  </g>
</template>

<script>
export default {
  name: 'EyeSymbols'
}
</script>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const symbols = computed(() => store.getters['event/filtering/symbols'])

</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 200ms ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-enter-to,
.list-leave-from {
  opacity: 1;
}
</style>